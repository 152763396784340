import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mainContainer: {
    color: "red",
  },
}));

function NoAccess() {
  const classes = useStyles();

  return (
    <main className={classes.mainContainer}>
      <h1>You don&apos;t have access to this Page</h1>
    </main>
  );
}

export default NoAccess;
