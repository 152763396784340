import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import { ThreeDots } from "react-loader-spinner";
import { SnackBarContext } from "../../../context/SnackBarContext";
import AppDialog from "../../common/AppDialog/AppDialog";
import { CreateQuotationApi } from "../../../Apis/Estimations/RequestForQuotation/RequestForQuotation";
import { fetchRequestQuoteData } from "../../../redux/slice/EstimationSlice/EstimationSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { fetchCompanyData } from "../../../redux/slice/CompanySlice/CompanySlice";

const validationSchema = yup.object({
  companyId: yup.string().required("Field Required!"),
  products: yup.array().of(
    yup.object().shape({
      productName: yup.string().required("Field Required!"),
      quantity: yup
        .number()
        .typeError("Quantity must be a number")
        .positive("Quantity must be a positive number")
        .required("Field Required!"),
    })
  ),
});

function RequestQuotationDialog({ open, onClose, selectedRow, opportunityId }) {
  const { setsnackBarData } = useContext(SnackBarContext);
  const { CompanyData } = useSelector((state) => state?.Companies);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanyData());
  }, dispatch);
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={selectedRow ? "Update A Request" : "Add A Request"}
      minHeight="auto"
    >
      <Box my={2}>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{
            companyId: selectedRow ? selectedRow?.companyId : "",
            products: [{ productName: "", quantity: "" }],
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            const formData = {
              companyId: data?.companyId,
              lineItems: data?.products,
              considered: false,
              opportunityId: opportunityId,
            };

            let response;
            if (!selectedRow) {
              response = await CreateQuotationApi(formData);
            } else {
              null;
            }
            response?.snackBarData?.message &&
              setsnackBarData(response?.snackBarData);

            if (response?.success) {
              resetForm();
              onClose();
              dispatch(fetchRequestQuoteData(opportunityId));
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form noValidate autoComplete="off">
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label={"Company"}
                  name="companyId"
                  value={values.companyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.companyId && touched?.companyId}
                  helperText={
                    errors?.companyId && touched?.companyId
                      ? errors?.companyId
                      : ""
                  }
                >
                  {CompanyData?.map(
                    (company, idx) =>
                      company.type === "Vendor" && (
                        <MenuItem key={idx} value={company?.companyId}>
                          {company?.name}
                        </MenuItem>
                      )
                  )}
                </TextField>

                <FieldArray name="products">
                  {({ push, remove }) => (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          mt: 3,
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Add Products
                        </Typography>
                        <Tooltip title="Add Product">
                          <IconButton
                            color="success"
                            size="small"
                            sx={{ padding: "6px" }}
                            onClick={() =>
                              push({
                                productName: "",
                                quantity: "",
                              })
                            }
                          >
                            <AddIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <TableContainer
                        component={Box}
                        sx={{ marginTop: "10px", maxHeight: "300px" }}
                      >
                        <Table
                          sx={{ minWidth: "100%" }}
                          aria-label="simple table"
                        >
                          <TableHead className="table-head">
                            <TableRow>
                              <TableCell>Product Name</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.products?.map((document, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      sx={{ minWidth: "200px" }}
                                      type="text"
                                      name={`products[${index}].productName`}
                                      value={
                                        values.products[index]?.productName
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `products[${index}].productName`,
                                          e.target.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        errors?.products && touched?.products
                                          ? errors?.products[index]
                                              ?.productName &&
                                            touched?.products[index]
                                              ?.productName
                                          : false
                                      }
                                      helperText={
                                        errors?.products && touched?.products
                                          ? errors?.products[index]
                                              ?.productName &&
                                            touched?.products[index]
                                              ?.productName
                                            ? errors?.products[index]
                                                ?.productName
                                            : ""
                                          : ""
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      sx={{ width: "115px" }}
                                      type="number"
                                      name={`products[${index}].quantity`}
                                      value={values.products[index]?.quantity}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `products[${index}].quantity`,
                                          Number(e.target.value)
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        errors?.products && touched?.products
                                          ? errors?.products[index]?.quantity &&
                                            touched?.products[index]?.quantity
                                          : false
                                      }
                                      helperText={
                                        errors?.products && touched?.products
                                          ? errors?.products[index]?.quantity &&
                                            touched?.products[index]?.quantity
                                            ? errors?.products[index]?.quantity
                                            : ""
                                          : ""
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {values?.products?.length > 1 && (
                                      <Tooltip title="Remove Document">
                                        <IconButton
                                          color="error"
                                          size="small"
                                          sx={{ padding: "6px" }}
                                          disabled={values?.types?.length == 1}
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        >
                                          <DeleteOutlineIcon fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </FieldArray>

                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#f79c35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isSubmitting}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {selectedRow ? "Update" : "Add"}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppDialog>
  );
}

export default RequestQuotationDialog;
RequestQuotationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
  opportunityId: PropTypes.any,
};
