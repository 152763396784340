import React from "react";
import Layout from "../Layouts/Layout";
import RequireAuth from "../components/RequireAuth/RequireAuth";
import NotFound from "../pages/NotFound/NotFound";
import SignInForm from "../pages/Authentication/SignInForm";
import Opportunity from "../pages/Opportunity/Opportunity";
import Qualifications from "../pages/Qualifications/Qualifications";
import Create_Job from "../pages/Create_Job/Create_Job";
import Estimations from "../pages/Estimations/Estimations";
import Proposal_Review from "../pages/Proposal_Review/Proposal_Review";
import Follow_Ups from "../pages/Follow_Ups/Follow_Ups";
import Documents from "../pages/Documents/Documents";
import Settings from "../pages/Settings/Settings";
import RolesPage from "../pages/RolesPage/RolesPage";
import UsersPage from "../pages/UsersPage/UsersPage";
import UserForm from "../components/Settings/Roles/ScreenComponents/AddUpdateUserScreen/UserForm";
import Sources from "../pages/Settings/Opportunity/Sources";
import ProjectTypes from "../pages/Settings/Opportunity/ProjectTypes";
import Tags from "../pages/Settings/Opportunity/Tags";
import OpportunityForm from "../pages/Opportunity/OpportunityForm/OpportunityForm";
import Contacts from "../pages/Contacts/Contacts";
import ContactsForm from "../components/Contacts/ContactsForm";
import Companies from "../pages/Companies/Companies";
import CompaniesForm from "../components/Companies/CompaniesForm";
import EmailTemplatesTable from "../components/Settings/EmailTemplates/EmailTemplatesTable";
import EstimationsForm from "../components/Estimations/EstimationsForm";
import ProposalReviewDetail from "../pages/Proposal_Review/ProposalReviewDetail";
import OpportunityDetails from "../pages/Opportunity/OpportunityDetails/OpportunityDetails";
import ForgotPassword from "../pages/Authentication/ForgotPassword/ForgotPassword";
import OpportunityByStatus from "../pages/Opportunity/OpportunityByStatus/OpportunityByStatus";
import EstimationsByStatus from "../pages/Estimations/EstimationsByStatus/EstimationsByStatus";
import Dashboard from "../pages/Dashboard/Dashboard";
import SalesSummary from "../pages/Reports/SalesSummary/SalesSummary";
import EmailTemplatesForm2 from "../components/Settings/EmailTemplates/EmailTemplateForm2";

const AdminRoutes = () => {
  const AdminRoutes = [
    {
      path: "/",
      component: (
        <RequireAuth>
          <Layout activePage={<Dashboard />} />
        </RequireAuth>
      ),
      roleName: "Dashboard",
    },
    {
      path: "/bidding",
      component: (
        <RequireAuth>
          <Layout activePage={<Opportunity />} />
        </RequireAuth>
      ),
      roleName: "Opportunity",
    },
    {
      path: "/bidding/:screen",
      component: (
        <RequireAuth>
          <Layout activePage={<OpportunityByStatus />} />
        </RequireAuth>
      ),
      roleName: "Opportunity",
    },
    {
      path: "/bidding/form",
      component: (
        <RequireAuth>
          <Layout activePage={<OpportunityForm />} />
        </RequireAuth>
      ),
      roleName: "Opportunity",
    },
    {
      path: "/bidding/:screen/form",
      component: (
        <RequireAuth>
          <Layout activePage={<OpportunityForm />} />
        </RequireAuth>
      ),
      roleName: "Opportunity",
    },
    {
      path: "/bidding/details",
      component: (
        <RequireAuth>
          <Layout activePage={<OpportunityDetails />} />
        </RequireAuth>
      ),
      roleName: "Opportunity",
    },
    {
      path: "/bidding/:screen/details",
      component: (
        <RequireAuth>
          <Layout activePage={<OpportunityDetails />} />
        </RequireAuth>
      ),
      roleName: "Opportunity",
    },
    {
      path: "/qualifications",
      component: (
        <RequireAuth>
          <Layout activePage={<Qualifications />} />
        </RequireAuth>
      ),
      roleName: "Qualifications",
    },
    {
      path: "/qualifications/details",
      component: (
        <RequireAuth>
          <Layout activePage={<OpportunityDetails />} />
        </RequireAuth>
      ),
      roleName: "Qualifications",
    },
    {
      path: "/create_Job",
      component: (
        <RequireAuth>
          <Layout activePage={<Create_Job />} />
        </RequireAuth>
      ),
      roleName: "Create_Job",
    },
    {
      path: "/estimations",
      component: (
        <RequireAuth>
          <Layout activePage={<Estimations />} />
        </RequireAuth>
      ),
      roleName: "Estimations",
    },
    {
      path: "/estimations/form",
      component: (
        <RequireAuth>
          <Layout activePage={<EstimationsForm />} />
        </RequireAuth>
      ),
      roleName: "Estimations",
    },
    {
      path: "/estimations/:screen",
      component: (
        <RequireAuth>
          <Layout activePage={<EstimationsByStatus />} />
        </RequireAuth>
      ),
      roleName: "Estimations",
    },
    {
      path: "/proposal_review",
      component: (
        <RequireAuth>
          <Layout activePage={<Proposal_Review />} />
        </RequireAuth>
      ),
      roleName: "Proposal_Review",
    },
    {
      path: "/proposal_review/details",
      component: (
        <RequireAuth>
          <Layout activePage={<OpportunityDetails />} />
        </RequireAuth>
      ),
      roleName: "Proposal_Review",
    },
    {
      path: "/proposal_review/view",
      component: (
        <RequireAuth>
          <Layout activePage={<ProposalReviewDetail />} />
        </RequireAuth>
      ),
      roleName: "Proposal_Review",
    },
    {
      path: "/follow_Ups",
      component: (
        <RequireAuth>
          <Layout activePage={<Follow_Ups />} />
        </RequireAuth>
      ),
      roleName: "Follow_Ups",
    },
    {
      path: "/contacts",
      component: (
        <RequireAuth>
          <Layout activePage={<Contacts />} />
        </RequireAuth>
      ),
      roleName: "Contacts",
    },
    {
      path: "/contacts/form",
      component: (
        <RequireAuth>
          <Layout activePage={<ContactsForm parent="screen" />} />
        </RequireAuth>
      ),
      roleName: "Contacts",
    },
    {
      path: "/companies",
      component: (
        <RequireAuth>
          <Layout activePage={<Companies />} />
        </RequireAuth>
      ),
      roleName: "Companies",
    },
    {
      path: "/companies/form",
      component: (
        <RequireAuth>
          <Layout activePage={<CompaniesForm parent="screen" />} />
        </RequireAuth>
      ),
      roleName: "Companies",
    },
    {
      path: "/documents",
      component: (
        <RequireAuth>
          <Layout activePage={<Documents />} />
        </RequireAuth>
      ),
      roleName: "Documents",
    },
    {
      path: "/repots/sales-summary",
      component: (
        <RequireAuth>
          <Layout activePage={<SalesSummary />} />
        </RequireAuth>
      ),
      roleName: "Reports",
    },
    {
      path: "/settings",
      component: (
        <RequireAuth>
          <Layout activePage={<Settings />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/roles",
      component: (
        <RequireAuth>
          <Layout activePage={<RolesPage />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/users",
      component: (
        <RequireAuth>
          <Layout activePage={<UsersPage />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/users/form",
      component: (
        <RequireAuth>
          <Layout activePage={<UserForm />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/sources",
      component: (
        <RequireAuth>
          <Layout activePage={<Sources />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/tags",
      component: (
        <RequireAuth>
          <Layout activePage={<Tags />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/project-types",
      component: (
        <RequireAuth>
          <Layout activePage={<ProjectTypes />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/email-template",
      component: (
        <RequireAuth>
          <Layout activePage={<EmailTemplatesTable />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "/settings/email-template/form",
      component: (
        <RequireAuth>
          <Layout activePage={<EmailTemplatesForm2 />} />
        </RequireAuth>
      ),
      roleName: "Settings",
    },
    {
      path: "signin",
      component: <SignInForm />,
    },
    {
      path: "forgot-password",
      component: <ForgotPassword />,
    },
    {
      path: "*",
      component: (
        <RequireAuth>
          <Layout activePage={<NotFound />} />
        </RequireAuth>
      ),
    },
  ];

  return AdminRoutes;
};

export default AdminRoutes;
