import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const SnackBarContext = createContext();

export const SnackBarContextProvider = ({ children }) => {
  const [snackBarData, setsnackBarData] = useState({
    openToast: false,
    type: "",
    message: "",
  });

  return (
    <SnackBarContext.Provider value={{ snackBarData, setsnackBarData }}>
      {children}
    </SnackBarContext.Provider>
  );
};
SnackBarContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
