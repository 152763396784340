import React, { useContext, useEffect } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import { ThreeDots } from "react-loader-spinner";
import { SnackBarContext } from "../../../context/SnackBarContext";
import AppDialog from "../../common/AppDialog/AppDialog";
import { fetchThirdPartyData } from "../../../redux/slice/EstimationSlice/EstimationSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyData } from "../../../redux/slice/CompanySlice/CompanySlice";
import { CreateThirdPartyApi } from "../../../Apis/Estimations/ThirdParty/ThirdPartyApis";

const validationSchema = yup.object({
  companyId: yup.string().required("Field Required!"),
  description: yup.string().required("Field Required!"),
});

function ThirdPartyDialog({ open, onClose, selectedRow, opportunityId }) {
  const { setsnackBarData } = useContext(SnackBarContext);
  const { CompanyData } = useSelector((state) => state?.Companies);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanyData());
  }, dispatch);
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={selectedRow ? "Update Third Party" : "Add Third Party"}
      minHeight="auto"
    >
      <Box my={2}>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{
            companyId: selectedRow ? selectedRow?.companyId : "",
            description: selectedRow ? selectedRow?.description : "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            const formData = {
              companyId: data?.companyId,
              description: data?.description,
              considered: false,
              opportunityId: opportunityId,
            };

            let response;
            if (!selectedRow) {
              response = await CreateThirdPartyApi(formData);
            } else {
              null;
            }
            response?.snackBarData?.message &&
              setsnackBarData(response?.snackBarData);

            if (response?.success) {
              resetForm();
              onClose();
              dispatch(fetchThirdPartyData(opportunityId));
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form noValidate autoComplete="off">
                <TextField
                  select
                  name="companyId"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={values.companyId}
                  onChange={handleChange}
                  label="Company"
                  onBlur={handleBlur}
                  error={touched.companyId && Boolean(errors.companyId)}
                  helperText={touched.companyId && errors.companyId}
                >
                  {CompanyData?.map(
                    (company, idx) =>
                      company.type === "Vendor" && (
                        <MenuItem key={idx} value={company?.companyId}>
                          {company?.name}
                        </MenuItem>
                      )
                  )}
                </TextField>

                <TextField
                  sx={{
                    mt: 2,
                  }}
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={"Description"}
                  multiline
                  rows={5}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.description && touched?.description}
                  helperText={
                    errors?.description && touched?.description
                      ? errors?.description
                      : ""
                  }
                />

                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#f79c35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isSubmitting}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {selectedRow ? "Update" : "Add"}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppDialog>
  );
}

export default ThirdPartyDialog;
ThirdPartyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
  opportunityId: PropTypes.any,
};
