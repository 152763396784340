import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, FormControl, FormHelperText } from "@mui/material";
import AppDialog from "../common/AppDialog/AppDialog";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
} from "draft-js";
import { convertToHTML } from "draft-convert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { SnackBarContext } from "../../context/SnackBarContext";
import { AddNotesApi, UpdateNotesApi } from "../../Apis/Notes/NotesApi";
import htmlToDraft from "html-to-draftjs";

function AddNoteDialog({
  open,
  onClose,
  referenceId,
  referenceType,
  opportunityId,
  updateData,
}) {
  const { setsnackBarData } = useContext(SnackBarContext);

  const validationSchema = Yup.object({
    body: Yup.string().required("Field required!"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      body: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm, setSubmitting }) => {
      let data = {
        note: values.body,
        ...(referenceId && { referenceId: referenceId }),
        ...(referenceType && { referenceType: referenceType }),
        ...(opportunityId && { opportunityId: opportunityId }),
      };
      setSubmitting(true);
      let response;
      if (updateData) {
        response = await UpdateNotesApi(updateData?.notesId, data);
      } else {
        response = await AddNotesApi(data);
      }

      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);
      if (response?.success) {
        // dispatch(fetchAllReceivings());
        resetForm();
        setSubmitting(false);
        onClose(true);
      } else {
        setSubmitting(false);
      }
    },
  });
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const styleToHTML = (style) => {
    if (style.startsWith("color-")) {
      const color = style.replace("color-", "");
      return <span style={{ color: color }} />;
    }
  };
  useEffect(() => {
    if (updateData) {
      let body = htmlToDraft(updateData?.note ? updateData.note : "");
      if (body?.contentBlocks) {
        const contentState = ContentState.createFromBlockArray(
          body?.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [updateData]);
  useEffect(() => {
    const html = convertToHTML({
      styleToHTML,
    })(editorState.getCurrentContent());

    if (html !== "<p></p>") {
      formik.setFieldValue("body", html);
    }
  }, [editorState]);
  const onChange = async (value) => {
    setEditorState(value);
  };

  const handleKeyCommand = (command, editorState) => {
    // Intercept Enter key press
    if (command === "split-block") {
      // Create a new EditorState with a soft line break instead of a new paragraph
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    // Handle Enter key press with shift
    if (e.keyCode === 13 /* 'Enter' key */ && e.shiftKey) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };

  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title={updateData ? "Update Note" : "Add Note"}
      minHeight="auto"
      maxWidth="md"
    >
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Box sx={{ marginTop: "20px" }}>
          <FormControl fullWidth>
            <Box
              sx={{
                maxHeight: "500px",
                overflow: "auto",
                border:
                  Boolean(formik.errors.body) && Boolean(formik.touched.body)
                    ? "1px solid #d32f2f"
                    : "1px solid #bdbdbd",
                padding: "8px",
              }}
            >
              <Editor
                placeholder="Start Typing your note here"
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                keyBindingFn={mapKeyToEditorCommand}
                onEditorStateChange={(value) => {
                  onChange(value);
                }}
              />
            </Box>
            <FormHelperText sx={{ color: "#d32f2f" }}>
              {Boolean(formik.errors.body) && Boolean(formik.touched.body)
                ? "Field Required!"
                : ""}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#f79c35"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={formik.isSubmitting}
          />

          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              onClose(false);
            }}
          >
            Back
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting}
            color="primary"
          >
            {updateData ? "Update" : "Save"}
          </Button>
        </Box>
      </form>
    </AppDialog>
  );
}

export default AddNoteDialog;
AddNoteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setNote: PropTypes.func,
  referenceId: PropTypes.any,
  opportunityId: PropTypes.any,

  referenceType: PropTypes.any,
  updateData: PropTypes.func,
};
