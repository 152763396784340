import axiosInstance from "../../../utils/AxiosInstance";

export const CreateQuotationApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Quotation/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const UpdateConsideredApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put("/Quotation/UpdateConsider", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteQuoteApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/Quotation/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
