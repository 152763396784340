import {
  Box,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import DocumentsGrid from "./DocumentsGrid";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import AddDocumentDialog from "../DocumentsDialog/AddDocumentDialog/AddDocumentDialog";
import { fetchOpportunityDocumentsData } from "../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import { useDispatch } from "react-redux";
import { SnackBarContext } from "../../context/SnackBarContext";

const iconMap = {
  design: <FolderIcon fontSize="large" style={{ color: "#673AB7" }} />,
  video: <PlayCircleFilledIcon fontSize="large" style={{ color: "#673AB7" }} />,
  music: <AudiotrackIcon fontSize="large" style={{ color: "#673AB7" }} />,
};

function DocumentTypes({ documents, opportunityId, viewFiles, setviewFiles }) {
  console.log("documents", documents);
  const [folderData, setFolderData] = useState([]);
  const [files, setFiles] = useState([]);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const [categorizedDocuments, setCategorizedDocuments] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIdx, setmenuIdx] = useState(null);
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch();

  useEffect(() => {
    const categorized = documents.reduce((acc, document) => {
      const { type } = document;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(document);
      return acc;
    }, {});

    setCategorizedDocuments(categorized);
  }, [documents]);

  useEffect(() => {
    if (categorizedDocuments) {
      let arr = [];
      Object.keys(categorizedDocuments).forEach((type) => {
        arr.push({
          fileCount: categorizedDocuments[type]?.length,
          type: "design",
          title: type,
          files: categorizedDocuments[type],
        });
      });
      setFolderData(arr);
    }
  }, [categorizedDocuments]);
  const handleMenuClick = (event, idx) => {
    setAnchorEl(event.currentTarget);
    setmenuIdx(idx);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleViewFiles = (folder) => {
    setFiles(folder?.files);
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  console.log(opportunityId, "opp");
  const classes = useStyles();
  return (
    <>
      {opportunityId !== 0 && (
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: "#f9f9f9",
            boxShadow: "0 1px 10px rgb(0 0 0 / 0.2)",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {/* <Typography className={classes?.fieldsHeading} m={2} color="primary">
          Document Types
        </Typography> */}
          <Typography
            ml={2}
            mt={2}
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              color: "#0288d1",
            }}
          >
            Select the document you wish to view, or upload a new document
          </Typography>
          <Box display="flex" justifyContent="start" flexWrap="nowrap">
            {folderData.map((folder, index) => (
              <Box
                key={index}
                sx={{
                  minWidth: 200,
                  borderRadius: 2,
                  m: 2,
                  border: "1px solid #E3E3E3",
                  display: "inline-block",
                  transition: "background-color 0.3s ease",
                  backgroundColor: selected === index && "#f0efef",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f0efef",
                  },
                }}
                onClick={() => {
                  handleViewFiles(folder);
                  setviewFiles(true);
                  setSelected(index);
                }}
              >
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {iconMap[folder.type]}
                    <IconButton
                      id="basic-button"
                      aria-controls={openMenu ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? "true" : undefined}
                      onClick={(e) => {
                        handleMenuClick(e, index);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu && menuIdx === index}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                        sx: { padding: 0 },
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      elevation={2}
                      PaperProps={{
                        sx: {
                          borderRadius: "12px",
                          padding: "5px",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ padding: "6px", borderRadius: "8px" }}
                        onClick={() => {
                          handleMenuClose();
                          setviewFiles(true);
                        }}
                      >
                        <Box className={classes.boxStyle}>
                          <Typography className={classes?.itemStyle}>
                            View Files
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                    {folder.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {folder.fileCount} file(s)
                  </Typography>
                </CardContent>
              </Box>
            ))}

            <Box
              sx={{
                minWidth: 200,
                borderRadius: 2,
                m: 2,
                border: "1px solid #E3E3E3",
                display: "inline-block",
                transition: "background-color 0.3s ease",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#f0efef",
                },
              }}
              onClick={() => {
                if (opportunityId == "") {
                  setsnackBarData({
                    openToast: true,
                    type: "error",
                    message: "Please select opportunity and get documents",
                  });
                  return;
                }
                setOpen(true);
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                    <svg
                      fill="#cccccc"
                      height="80px"
                      width="80px"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 490.95 490.95"
                      xmlSpace="preserve"
                      stroke="#cccccc"
                      strokeWidth="0.00490955"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#CCCCCC"
                        strokeWidth="0.9819100000000001"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          id="XMLID_448_"
                          d="M445.767,308.42l-53.374-76.49v-20.656v-11.366V97.241c0-6.669-2.604-12.94-7.318-17.645L312.787,7.301 
          C308.073,2.588,301.796,0,295.149,0H77.597C54.161,0,35.103,19.066,35.103,42.494V425.68c0,23.427,19.059,42.494,42.494,42.494 
          h159.307h39.714c1.902,2.54,3.915,5,6.232,7.205c10.033,9.593,23.547,15.576,38.501,15.576c26.935,0-1.247,0,34.363,0 
          c14.936,0,28.483-5.982,38.517-15.576c11.693-11.159,17.348-25.825,17.348-40.29v-40.06c16.216-3.418,30.114-13.866,37.91-28.811 
          C459.151,347.704,457.731,325.554,445.767,308.42z M170.095,414.872H87.422V53.302h175.681v46.752 
          c0,16.655,13.547,30.209,30.209,30.209h46.76v66.377h-0.255v0.039c-17.685-0.415-35.529,7.285-46.934,23.46l-61.586,88.28 
          c-11.965,17.134-13.387,39.284-3.722,57.799c7.795,14.945,21.692,25.393,37.91,28.811v19.842h-10.29H170.095z 
          M410.316,345.771c-2.03,3.866-5.99,6.271-10.337,6.271h-0.016h-32.575v83.048c0,6.437-5.239,11.662-11.659,11.662h-0.017H321.35
          h-0.017c-6.423,0-11.662-5.225-11.662-11.662v-83.048h-32.574h-0.016c-4.346,0-8.308-2.405-10.336-6.271 
          c-2.012-3.866-1.725-8.49,0.783-12.07l61.424-88.064c2.189-3.123,5.769-4.984,9.57-4.984h0.017c3.802,0,7.38,1.861,9.568,4.984 
          l61.427,88.064C412.04,337.28,412.328,341.905,410.316,345.771z"
                        />
                      </g>
                    </svg>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Upload Document
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Box>
      )}
      {viewFiles && <DocumentsGrid files={files} />}
      <AddDocumentDialog
        open={open}
        onClose={(success) => {
          if (success) {
            dispatch(
              fetchOpportunityDocumentsData({
                opportunityId: opportunityId,
              })
            );
          }
          setOpen(false);
        }}
        selectedRow={{ opportunityId: opportunityId }}
      />
    </>
  );
}

export default DocumentTypes;
DocumentTypes.propTypes = {
  documents: PropTypes.any,
  opportunityId: PropTypes.any,
  viewFiles: PropTypes.any,
  setviewFiles: PropTypes.any,
};

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    // color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
  boxStyle: { display: "flex", alignItems: "center", gap: "8px" },
  iconStyle: { fontSize: "1rem !important" },
  itemStyle: {
    fontSize: "0.9rem !important",
    fontWeight: "600 !important",
    color: "rgb(38 50 56 /1)",
  },
}));
