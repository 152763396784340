import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Typography,
  TextField,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { EditorState, RichUtils, getDefaultKeyBinding } from "draft-js";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SnackBarContext } from "../../context/SnackBarContext";
import { CreateWorkDefintionApi } from "../../Apis/Estimations/WorkDefinition/WorkDefinition";
import EditIcon from "@mui/icons-material/Edit";
import { stateFromHTML } from "draft-js-import-html";

function WorkDefinition({
  handleSkip,
  isStepOptional,
  handleNext,
  steps,
  activeStep,
  classes,
  exclusionsArr,
  setAlternatesArr,
  setExclusionsArr,
  setExteriorArr,
  setInteriorArr,
  alternatesArr,
  exteriorArr,
  interiorArr,
  opportunityId,
  estimationData,
  setEstimationId,
  setWorkDefinitionChanged,
}) {
  const validationSchema = yup.object({
    type: yup.string().required("Required Field!"),
  });
  const [wallpaperPrioritize, setWallpaperPrioritize] = useState(false);
  const [note, setNotes] = useState("");
  const [expandedArray, setExpandedArray] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [loading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);

  const styleToHTML = (style) => {
    if (style.startsWith("color-")) {
      const color = style.replace("color-", "");
      return <span style={{ color: color }} />;
    }
  };

  useEffect(() => {
    const html = convertToHTML({
      styleToHTML,
    })(editorState.getCurrentContent());

    if (html !== "<p></p>") {
      setNotes(html);
    }
  }, [editorState]);

  useEffect(() => {
    if (estimationData) {
      setWallpaperPrioritize(estimationData?.wallpaperPrioritize);
    }
  }, [estimationData]);

  const onChange = async (value) => {
    setEditorState(value);
  };

  const handleKeyCommand = (command, editorState) => {
    // Intercept Enter key press
    if (command === "split-block") {
      // Create a new EditorState with a soft line break instead of a new paragraph
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    // Handle Enter key press with shift
    if (e.keyCode === 13 /* 'Enter' key */ && e.shiftKey) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };

  const handleAccordionChange = (panel) => {
    setExpandedArray((prevExpanded) =>
      prevExpanded.includes(panel)
        ? prevExpanded.filter((p) => p !== panel)
        : [...prevExpanded, panel]
    );
  };

  const handleSave = async () => {
    setLoading(true);
    const formData = {
      wallpaperPrioritize,
      opportunityId,
      interiorPainting: interiorArr?.map((item) => ({
        customKey: item?.customKey,
        description: item?.description,
      })),
      exteriorPainting: exteriorArr?.map((item) => ({
        customKey: item?.customKey,
        description: item?.description,
      })),
      alternates: alternatesArr?.map((item) => ({
        customKey: item?.customKey,
        description: item?.description,
      })),
      exclusion: exclusionsArr?.map((item) => ({
        customKey: item?.customKey,
        description: item?.description,
      })),
    };

    if (estimationData) {
      formData.estimationId = estimationData?.estimationId;
    }

    const response = await CreateWorkDefintionApi(formData);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      setEstimationId(response?.data?.newEstimation?.estimationId);
      setWorkDefinitionChanged(false);
    }

    setLoading(false);
  };

  return (
    <div className={classes.formContainer}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          type: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          if (editorState == null) {
            return;
          }
          if (data?.type === "INT") {
            if (editIndex === null) {
              let obj = {
                customKey: `${data.type}.${
                  interiorArr.length > 0 ? interiorArr.length + 1 : 1
                }`,
                description: note,
              };
              setInteriorArr((prevInteriorArr) => [...prevInteriorArr, obj]);
            } else {
              let newArr = [...interiorArr]?.map((item, idx) => {
                if (idx == editIndex) {
                  return {
                    ...item,
                    description: note,
                  };
                } else {
                  return item;
                }
              });

              setInteriorArr(newArr);
              setEditIndex(null);
              resetForm();
            }

            setEditorState(EditorState.createEmpty());
            handleAccordionChange("INT");
          } else if (data?.type === "EXT") {
            if (editIndex === null) {
              let obj = {
                customKey: `${data.type}.${
                  exteriorArr.length > 0 ? exteriorArr.length + 1 : 1
                }`,
                description: note,
              };
              setExteriorArr((prevExteriorArr) => [...prevExteriorArr, obj]);
            } else {
              let newArr = [...exteriorArr]?.map((item, idx) => {
                if (idx == editIndex) {
                  return {
                    ...item,
                    description: note,
                  };
                } else {
                  return item;
                }
              });
              setExteriorArr(newArr);
              setEditIndex(null);
              resetForm();
            }

            handleAccordionChange("EXT");
            setEditorState(EditorState.createEmpty());
          } else if (data?.type === "ALT") {
            if (editIndex === null) {
              let obj = {
                customKey: `${data.type}.${
                  alternatesArr.length > 0 ? alternatesArr.length + 1 : 1
                }`,
                description: note,
              };
              setAlternatesArr((prevAlternatesAr) => [
                ...prevAlternatesAr,
                obj,
              ]);
            } else {
              let newArr = [...alternatesArr]?.map((item, idx) => {
                if (idx == editIndex) {
                  return {
                    ...item,
                    description: note,
                  };
                } else {
                  return item;
                }
              });
              setAlternatesArr(newArr);
              setEditIndex(null);
              resetForm();
            }

            handleAccordionChange("ALT");
            setEditorState(EditorState.createEmpty());
          } else if (data?.type === "EXC") {
            if (editIndex === null) {
              let obj = {
                customKey: `${data.type}.${
                  exclusionsArr.length > 0 ? exclusionsArr.length + 1 : 1
                }`,
                description: note,
              };
              setExclusionsArr((prevExclusionsArr) => [
                ...prevExclusionsArr,
                obj,
              ]);
            } else {
              let newArr = [...exclusionsArr]?.map((item, idx) => {
                if (idx == editIndex) {
                  return {
                    ...item,
                    description: note,
                  };
                } else {
                  return item;
                }
              });
              setExclusionsArr(newArr);
              setEditIndex(null);
              resetForm();
            }

            handleAccordionChange("EXC");
            setEditorState(EditorState.createEmpty());
          }

          setWorkDefinitionChanged(true);
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Box sx={{ marginTop: "20px" }}>
                <Box sx={{ display: "flex", alignContent: "center" }}>
                  <Checkbox
                    inputProps={{
                      "aria-label": "Wallpaper installation included ",
                    }}
                    checked={wallpaperPrioritize}
                    onChange={(e) => {
                      setWallpaperPrioritize(e.target.checked);
                    }}
                  />

                  <Typography sx={{ pt: "10px" }}>
                    Wallpaper installation included
                  </Typography>
                </Box>
                {/* <Typography className={classes?.fieldsHeading}>
                  Notes
                </Typography> */}
                <Divider sx={{ mb: 2 }} />
                <Typography className={classes?.fieldsHeading}>
                  Define Work Line Items
                </Typography>
                <TextField
                  select
                  name="type"
                  variant="outlined"
                  size="small"
                  value={values.type}
                  onChange={handleChange}
                  label="Work Type"
                  onBlur={handleBlur}
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                  sx={{ marginBottom: "20px", width: "50%" }}
                >
                  <MenuItem value={"INT"}>Interior Painting</MenuItem>
                  <MenuItem value={"EXT"}>Exterior Painting</MenuItem>
                  <MenuItem value={"ALT"}>Alternates</MenuItem>
                  <MenuItem value={"EXC"}>Exclusions</MenuItem>
                </TextField>
                <FormControl fullWidth>
                  <Box
                    sx={{
                      maxHeight: "500px",
                      overflow: "auto",
                      border:
                        Boolean(errors.body) && Boolean(touched.body)
                          ? "1px solid #d32f2f"
                          : "1px solid #bdbdbd",
                      padding: "8px",
                    }}
                  >
                    <Editor
                      placeholder="Start defining your work here"
                      editorState={editorState}
                      handleKeyCommand={handleKeyCommand}
                      keyBindingFn={mapKeyToEditorCommand}
                      onEditorStateChange={(value) => {
                        onChange(value);
                      }}
                    />
                  </Box>
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    {Boolean(errors.body) && Boolean(touched.body)
                      ? "Field Required!"
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "end", my: "5px" }}>
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={
                    editorState.getCurrentContent().hasText() ? false : true
                  }
                  color="primary"
                >
                  {editIndex !== null ? "Update Work" : "Add Work"}
                </Button>
              </Box>

              <Box sx={{ marginTop: "20px" }}>
                {/* <Typography className={classes?.fieldsHeading}>
                  Notes
                </Typography>
                <Divider sx={{ mb: 2 }} /> */}
                <Accordion
                  expanded={expandedArray.includes("INT")}
                  onChange={() => handleAccordionChange("INT")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className={classes?.fieldsHeading}>
                      Interior Painting
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {interiorArr.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row?.customKey}
                              </TableCell>

                              <TableCell>
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: row?.description,
                                  }}
                                >
                                  {}
                                </Box>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="info"
                                      onClick={() => {
                                        const contentState = stateFromHTML(
                                          row?.description
                                        );
                                        const newEditorState =
                                          EditorState.createWithContent(
                                            contentState
                                          );
                                        setEditorState(newEditorState);
                                        setFieldValue("type", "INT");
                                        setEditIndex(idx);
                                      }}
                                    >
                                      <EditIcon sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="error"
                                      onClick={() => {
                                        setInteriorArr((prevExteriorArr) =>
                                          prevExteriorArr.filter(
                                            (item) =>
                                              item.customKey !== row?.customKey
                                          )
                                        );
                                        setWorkDefinitionChanged(true);
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedArray.includes("EXT")}
                  onChange={() => handleAccordionChange("EXT")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className={classes?.fieldsHeading}>
                      Exterior Painting
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {exteriorArr.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{row?.customKey}</TableCell>

                              <TableCell component="th" scope="row">
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: row?.description,
                                  }}
                                >
                                  {}
                                </Box>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="info"
                                      onClick={() => {
                                        const contentState = stateFromHTML(
                                          row?.description
                                        );
                                        const newEditorState =
                                          EditorState.createWithContent(
                                            contentState
                                          );
                                        setEditorState(newEditorState);
                                        setFieldValue("type", "EXT");
                                        setEditIndex(idx);
                                      }}
                                    >
                                      <EditIcon sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="error"
                                      onClick={() => {
                                        setExteriorArr((prevExteriorArr) =>
                                          prevExteriorArr.filter(
                                            (item) =>
                                              item.customKey !== row?.customKey
                                          )
                                        );
                                        setWorkDefinitionChanged(true);
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedArray.includes("ALT")}
                  onChange={() => handleAccordionChange("ALT")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className={classes?.fieldsHeading}>
                      Alternates
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {alternatesArr.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{row?.customKey}</TableCell>
                              <TableCell component="th" scope="row">
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: row?.description,
                                  }}
                                >
                                  {}
                                </Box>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="info"
                                      onClick={() => {
                                        const contentState = stateFromHTML(
                                          row?.description
                                        );
                                        const newEditorState =
                                          EditorState.createWithContent(
                                            contentState
                                          );
                                        setEditorState(newEditorState);
                                        setFieldValue("type", "ALT");
                                        setEditIndex(idx);
                                      }}
                                    >
                                      <EditIcon sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="error"
                                      onClick={() => {
                                        setAlternatesArr((prevExteriorArr) =>
                                          prevExteriorArr.filter(
                                            (item) =>
                                              item.customKey !== row?.customKey
                                          )
                                        );
                                        setWorkDefinitionChanged(true);
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedArray.includes("EXC")}
                  onChange={() => handleAccordionChange("EXC")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <Typography className={classes?.fieldsHeading}>
                      Exclusions
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {exclusionsArr.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{row?.customKey}</TableCell>

                              <TableCell component="th" scope="row">
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: row?.description,
                                  }}
                                ></Box>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="info"
                                      onClick={() => {
                                        const contentState = stateFromHTML(
                                          row?.description
                                        );
                                        const newEditorState =
                                          EditorState.createWithContent(
                                            contentState
                                          );
                                        setEditorState(newEditorState);
                                        setFieldValue("type", "EXC");
                                        setEditIndex(idx);
                                      }}
                                    >
                                      <EditIcon sx={{ fontSize: "1rem" }} />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <IconButton
                                      sx={{ padding: "5px" }}
                                      color="error"
                                      onClick={() => {
                                        setExclusionsArr((prevExteriorArr) =>
                                          prevExteriorArr.filter(
                                            (item) =>
                                              item.customKey !== row?.customKey
                                          )
                                        );
                                        setWorkDefinitionChanged(true);
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        sx={{ fontSize: "1rem" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#f79c35"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={loading}
                />

                <Button
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="error"
                >
                  Back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={
                    loading ||
                    (!wallpaperPrioritize &&
                      !interiorArr?.length &&
                      !exteriorArr?.length &&
                      !alternatesArr?.length &&
                      !exclusionsArr?.length)
                  }
                  onClick={handleSave}
                >
                  {estimationData ? "Update" : "Save"}
                </Button>
                <Button
                  //   type="submit"
                  onClick={handleNext}
                  variant="contained"
                  disabled={loading}
                  color="primary"
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default WorkDefinition;
WorkDefinition.propTypes = {
  activeStep: PropTypes.any,
  steps: PropTypes.any,
  handleNext: PropTypes.any,
  isStepOptional: PropTypes.any,
  handleBack: PropTypes.any,
  handleSkip: PropTypes.any,
  placeholder: PropTypes.any,
  setTagCount: PropTypes.any,
  classes: PropTypes.any,
  setExclusionsArr: PropTypes.func,
  setExteriorArr: PropTypes.func,
  setAlternatesArr: PropTypes.func,
  setInteriorArr: PropTypes.func,
  exclusionsArr: PropTypes.array,
  alternatesArr: PropTypes.array,
  interiorArr: PropTypes.array,
  exteriorArr: PropTypes.array,
  opportunityId: PropTypes.any,
  estimationData: PropTypes.any,
  setEstimationId: PropTypes.func,
  setWorkDefinitionChanged: PropTypes.func,
};
