import React, { useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";

import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { SnackBarContext } from "../../context/SnackBarContext";
import AppDialog from "../common/AppDialog/AppDialog";
import {
  CreateChangeReqApi,
  UpdateChangeReqApi,
} from "../../Apis/ChangeReq/ChangeReqApi";
import { fetchChangeReqDataById } from "../../redux/slice/ChangeReqSlice/ChangeReqSlice";

const validationSchema = yup.object({
  //   refernceDocument: yup.string().required("Field Required!"),
  description: yup.string().required("Field Required!"),
});

function ChangeReqDialog({ open, onClose, selectedRow, opportunityId }) {
  const { setsnackBarData } = useContext(SnackBarContext);
  console.log("selectedRow", selectedRow);

  const dispatch = useDispatch();

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={selectedRow ? "Update Change Request" : "Add Change Request"}
      minHeight="auto"
    >
      <Box>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{
            refernceDocument: null,
            description: selectedRow ? selectedRow?.description : "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            const formData = new FormData();
            data?.refernceDocument &&
              formData.append("refernceDocument", data?.refernceDocument);
            formData.append("description", data?.description);
            formData.append("status", data?.status ? data?.status : "New");
            formData.append("opportunityId", opportunityId);

            let response;
            if (!selectedRow) {
              response = await CreateChangeReqApi(formData);
            } else {
              response = await UpdateChangeReqApi(
                selectedRow?.changeRequestId,
                formData
              );
            }
            response?.snackBarData?.message &&
              setsnackBarData(response?.snackBarData);

            if (response?.success) {
              resetForm();
              onClose();
              dispatch(fetchChangeReqDataById(opportunityId));
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Form noValidate autoComplete="off">
                {selectedRow?.status === "Updated" && (
                  <FormControlLabel
                    sx={{ m: 0 }}
                    label="Close Request"
                    labelPlacement="start"
                    control={
                      <Checkbox
                        name="status"
                        onChange={(e) => {
                          console.log(e.target.checked);
                          e.target.checked && setFieldValue("status", "Closed");
                        }}
                        color="primary"
                      />
                    }
                  />
                )}
                <TextField
                  sx={{
                    mt: 2,
                  }}
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={"Description"}
                  multiline
                  rows={5}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.description && touched?.description}
                  helperText={
                    errors?.description && touched?.description
                      ? errors?.description
                      : ""
                  }
                />

                <TextField
                  sx={{
                    mt: 2,
                  }}
                  type="file"
                  name="refernceDocument"
                  variant="outlined"
                  fullWidth
                  size="small"
                  //   value={values.refernceDocument}
                  //   onChange={handleChange}
                  onChange={(e) => {
                    setFieldValue(`refernceDocument`, e.target.files[0]);
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.refernceDocument && Boolean(errors.refernceDocument)
                  }
                  helperText={
                    touched.refernceDocument && errors.refernceDocument
                  }
                />
                {!values.refernceDocument && selectedRow && (
                  <Typography
                    variant="body2"
                    sx={{
                      cursor: "pointer",
                      color: "#551a8b",
                      textDecoration: "underline",
                      p: 1,
                    }}
                    onClick={() => {
                      window.open(selectedRow?.documentsUrl, "_blank");
                    }}
                  >
                    View Selected Document
                  </Typography>
                )}

                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#f79c35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isSubmitting}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {selectedRow ? "Update" : "Add"}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppDialog>
  );
}

export default ChangeReqDialog;
ChangeReqDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
  opportunityId: PropTypes.any,
};
