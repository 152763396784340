import { React, useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  Autocomplete,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { City, State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersPopulateData } from "../../../../../redux/slice/UsersSlice/UsersSlice";
import * as Yup from "yup";
import {
  CreateUserApi,
  UpdateUserApi,
} from "../../../../../Apis/Users/CreateUserApi";

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));

function UserForm() {
  const classes = useStyles();
  const { UsersPopulateData, status } = useSelector((state) => state.Users);
  const [alldata, setalldata] = useState([]);
  console.log(status);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);
  const [showPassword, setshowPassword] = useState(false);
  const [confirmpass, setconfirmpass] = useState(false);
  const [cities, setCities] = useState([]);
  const userData = useLocation();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (userData?.state) {
      formik.setFieldValue("name", userData.state?.name);
      formik.setFieldValue("email", userData.state?.email);
      formik.setFieldValue("password", "********");
      formik.setFieldValue("phone", userData.state?.phone);
      formik.setFieldValue("state", userData.state?.state);
      formik.setFieldValue("city", userData.state?.city);
      formik.setFieldValue("address", userData.state?.address);
      formik.setFieldValue("zipcode", userData.state?.zipcode);
      formik.setFieldValue("gender", userData.state?.gender);
      formik.setFieldValue("dateOfBirth", userData.state?.dateOfBirth);
      formik.setFieldValue("roleId", userData.state?.roleId);
      formik.setFieldValue("confirmPassword", "********");
    }
  }, [userData]);
  const validationSchema = Yup.object({
    gender: Yup.string().required("Field required!"),
    phone: Yup.string().required("Field required!"),
    email: Yup.string()
      .email("Enter a valid Email")
      .required("Field required!"),
    roleId: Yup.string().required("Field required!"),
    address: Yup.string().required("Field required!"),
    city: Yup.string().required("Field required!"),
    state: Yup.string().required("Field required!"),
    password: Yup.string().required("Field required!"),
    name: Yup.string().required("Field required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Both Passwords must be the same.")
      .required("Field required!"),
  });

  const formik = useFormik({
    initialValues: {
      gender: "",
      name: "",
      dateOfBirth: null,
      address: "",
      zipcode: "",
      roleId: "",
      email: "",
      state: "",
      city: "",
      password: "",
      confirmPassword: "",
      phone: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      if (userData.state) {
        const response = await UpdateUserApi({
          userId: userData?.state?.userId,
          name: values.name,
          phone: values.phone,
          email: values.email,
          address: values?.address,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
          dateOfBirth: values?.dateOfBirth,
          gender: values.gender,
        });
        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);
        console.log("response.data", response);
        if (response?.success) {
          // dispatch(fetchAllReceivings());
          resetForm();
          setLoading(false);
          navigate(-1);
        } else {
          setLoading(false);
        }
      } else {
        const response = await CreateUserApi({
          ...values,
        });
        response?.snackBarData?.message &&
          setsnackBarData(response?.snackBarData);
        if (response?.success) {
          // dispatch(fetchAllReceivings());
          resetForm();
          setLoading(false);
          navigate(-1);
        } else {
          setLoading(false);
        }
      }
    },
  });
  const handlePhoneChange = (value) => {
    formik.setFieldValue("phone", value);
  };

  // const restNumber = () => {
  //   setCountryCode("us");
  //   setDialCode("");
  //   setPhone("");
  // };

  useEffect(() => {
    dispatch(fetchUsersPopulateData());
  }, [dispatch]);
  useEffect(() => {
    let value = State.getStatesOfCountry("US");
    value = value?.map((data) => {
      let obj = {
        label: data?.name,
        value: data?.isoCode,
      };
      return obj;
    });
    setStates(value);
  }, []);
  useEffect(() => {
    if (state) {
      let value = City.getCitiesOfState("US", state);
      value = value?.map((data) => {
        return data?.name;
      });
      if (state === "FL") {
        value.push("Parrish");
      }
      setCities(value);
    } else {
      setCities([]);
    }
  }, [state]);
  useEffect(() => {
    if (UsersPopulateData) {
      setalldata(UsersPopulateData);
    } else {
      setalldata([]);
    }
  }, [UsersPopulateData]);
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        width: "100%",
        height: "calc(100vh - 100px)",
        overflowY: "scroll",
      }}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes?.fieldsHeading}>
              Personal Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  label="Full Name"
                  onBlur={formik.handleBlur}
                  error={formik.errors?.name && formik.touched?.name}
                  helperText={
                    formik.errors?.name && formik.touched?.name
                      ? formik.errors?.name
                      : ""
                  }
                ></TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type="date"
                  name="dateOfBirth"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.dateOfBirth || ""}
                  onChange={formik.handleChange}
                  label="Date Of Birth"
                  InputLabelProps={{ shrink: true }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.dateOfBirth &&
                    Boolean(formik.errors.dateOfBirth)
                  }
                  helperText={
                    formik.touched.dateOfBirth && formik.errors.dateOfBirth
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  select
                  name="gender"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  label="Gender"
                  onBlur={formik.handleBlur}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  helperText={formik.touched.gender && formik.errors.gender}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <MuiPhoneNumber
                  defaultCountry={"us"}
                  disableAreaCodes={true}
                  regions={["america"]}
                  onlyCountries={["us"]}
                  value={formik.values?.phone}
                  onChange={handlePhoneChange}
                  variant="outlined"
                  label="Phone"
                  name="number"
                  size="small"
                  fullWidth
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  // error={phoneError}
                  // helperText={phoneError && "Number must contain 11 digits"}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes?.fieldsHeading}>
              Address Details
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  type="text"
                  name="address"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  label="Address"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={formik.touched.state && Boolean(formik.errors.state)}
                >
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    style={{ width: "100%" }}
                    options={states}
                    value={formik.values?.state}
                    // getOptionLabel={(option) => option.label}
                    onChange={(e, v) => {
                      setState(v?.value);
                      formik.setFieldValue("state", v?.label);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" />
                    )}
                  />
                  <FormHelperText>
                    {formik.touched.state && formik.errors.state}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={formik.touched.city && Boolean(formik.errors.city)}
                >
                  <Autocomplete
                    disablePortal
                    size="small"
                    style={{ width: "100%" }}
                    value={formik.values?.city}
                    id="combo-box-demo"
                    options={cities}
                    onChange={(e, v) => {
                      formik.setFieldValue("city", v);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} label="City" />
                    )}
                  />
                  <FormHelperText>
                    {formik.touched.city && formik.errors.city}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  name="zipcode"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  label="Zip Code"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.zipcode && Boolean(formik.errors.zipcode)
                  }
                  helperText={formik.touched.zipcode && formik.errors.zipcode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes?.fieldsHeading}>
              Credentials and Role
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={userData?.state}
                  type="email"
                  name="email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  label="Email"
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={userData?.state}
                  select
                  name="roleId"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.roleId}
                  onChange={formik.handleChange}
                  label="Role"
                  onBlur={formik.handleBlur}
                  error={formik.touched.roleId && Boolean(formik.errors.roleId)}
                  helperText={formik.touched.roleId && formik.errors.roleId}
                >
                  {alldata.map((role, idx) => (
                    <MenuItem key={idx} value={role?.roleId}>
                      {role?.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                >
                  <InputLabel htmlFor="confirmPassword">Password</InputLabel>
                  <OutlinedInput
                    disabled={userData?.state}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    label="Password"
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setshowPassword(!showPassword);
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                >
                  <InputLabel htmlFor="confirmPassword">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    disabled={userData?.state}
                    type={confirmpass ? "text" : "password"}
                    name="confirmPassword"
                    id="confirmPassword"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    label="Confirm Password"
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setconfirmpass(!confirmpass);
                          }}
                          edge="end"
                        >
                          {confirmpass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? formik.errors.confirmPassword
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#f79c35"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loading}
          />

          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default UserForm;
