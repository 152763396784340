import axiosInstance, { axiosInstanceForm } from "../../utils/AxiosInstance";

export const CreateContactApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.post("/Contact/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateContactApi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.put(`/Contact/Update/${id}`, data);
    console.log("response", response.data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteContactApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/Contact/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
