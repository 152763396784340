import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  ChangeReqData: null,
  ChangeReqDataId: null,

  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  AllGCs: null,
};

export const fetchChangeReqData = createAsyncThunk(
  "ChageRequest/GetAll",
  async () => {
    const response = await axiosInstance.get(`ChageRequest/GetAll`);
    return response?.data;
  }
);

export const fetchChangeReqDataById = createAsyncThunk(
  "ChageRequest/GetById",
  async (id) => {
    const response = await axiosInstance.get(
      `ChageRequest/GetByOpportunityId/${id}`
    );
    return response?.data;
  }
);

const ChangeReqSlice = createSlice({
  name: "ChangeReqSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchChangeReqData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChangeReqData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.ChangeReqData = action.payload;
      })
      .addCase(fetchChangeReqData.rejected, (state, action) => {
        state.ChangeReqData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchChangeReqDataById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChangeReqDataById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.ChangeReqDataId = action.payload;
      })
      .addCase(fetchChangeReqDataById.rejected, (state, action) => {
        state.ChangeReqDataId = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default ChangeReqSlice.reducer;
