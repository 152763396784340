import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Step, StepButton, Stepper, Typography } from "@mui/material";
import WorkDefinition from "./WorkDefinition";
import Estimation from "./Estimation";
import { makeStyles } from "@mui/styles";
import ThirdParty from "./ThirdParty/ThirdParty";
import RequestQuotation from "./RequestQuotation/RequestQuotation";
import { useLocation } from "react-router-dom";
import ChangeReqView from "../ProposalReview/ChangeReqView";
const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));
function EstimationsForm() {
  const classes = useStyles();
  const selectedData = useLocation();
  const [interiorArr, setInteriorArr] = useState([]);
  const [exteriorArr, setExteriorArr] = useState([]);
  const [alternatesArr, setAlternatesArr] = useState([]);
  const [exclusionsArr, setExclusionsArr] = useState([]);
  const [estimationId, setEstimationId] = useState(null);
  const steps = [
    "Work Definition",
    "Request for Quotation",
    "Third Party",
    "Estimation",
  ];
  selectedData?.state?.estimationData && steps.unshift("Change Request");
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [workDefinitionChanged, setWorkDefinitionChanged] = useState(false);

  useEffect(() => {
    if (selectedData?.state?.estimationData) {
      setInteriorArr(selectedData?.state?.estimationData?.interiorPainting);
      setExteriorArr(selectedData?.state?.estimationData?.exteriorPainting);
      setAlternatesArr(selectedData?.state?.estimationData?.alternates);
      setExclusionsArr(selectedData?.state?.estimationData?.exclusions);
      setEstimationId(selectedData?.state?.estimationData?.estimationId);
    }
  }, [selectedData]);

  const isStepOptional = () => {
    return null;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep !== 2 || !workDefinitionChanged) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      if (activeStep === steps.length - 1) {
        handleReset();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        width: "100%",
        minHeight: "calc(100vh - 100px)",
      }}
    >
      <Stepper
        sx={{ mt: 5 }}
        nonLinear
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={label}
              {...stepProps}
              disabled={label === "Estimation" && workDefinitionChanged}
            >
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {selectedData?.state?.estimationData ? (
        activeStep === 0 && selectedData?.state?.estimationData ? (
          <div className={classes.formContainer}>
            <ChangeReqView
              view={true}
              opportunityId={selectedData?.state?.selectedRowId}
              handleNext={handleNext}
              steps={steps}
              activeStep={activeStep}
            />
          </div>
        ) : activeStep === 1 ? (
          <WorkDefinition
            interiorArr={interiorArr}
            exclusionsArr={exclusionsArr}
            alternatesArr={alternatesArr}
            exteriorArr={exteriorArr}
            setAlternatesArr={setAlternatesArr}
            setExclusionsArr={setExclusionsArr}
            setExteriorArr={setExteriorArr}
            setInteriorArr={setInteriorArr}
            handleBack={handleBack}
            handleSkip={handleSkip}
            isStepOptional={isStepOptional}
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            classes={classes}
            opportunityId={selectedData?.state?.selectedRowId}
            estimationData={selectedData?.state?.estimationData}
            setEstimationId={setEstimationId}
            setWorkDefinitionChanged={setWorkDefinitionChanged}
          />
        ) : activeStep === 2 ? (
          <RequestQuotation
            handleBack={handleBack}
            handleSkip={handleSkip}
            isStepOptional={isStepOptional}
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            classes={classes}
            opportunityId={selectedData?.state?.selectedRowId}
          />
        ) : activeStep === 3 ? (
          <ThirdParty
            handleBack={handleBack}
            handleSkip={handleSkip}
            isStepOptional={isStepOptional}
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            classes={classes}
            opportunityId={selectedData?.state?.selectedRowId}
          />
        ) : activeStep === 4 ? (
          <Estimation
            handleBack={handleBack}
            interiorArr={interiorArr}
            exclusionsArr={exclusionsArr}
            alternatesArr={alternatesArr}
            exteriorArr={exteriorArr}
            setAlternatesArr={setAlternatesArr}
            setExclusionsArr={setExclusionsArr}
            setExteriorArr={setExteriorArr}
            setInteriorArr={setInteriorArr}
            handleSkip={handleSkip}
            isStepOptional={isStepOptional}
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            classes={classes}
            opportunityId={selectedData?.state?.selectedRowId}
            opportunityStatus={
              selectedData?.state?.estimationData?.opportunity?.status
            }
            estimationId={estimationId}
          />
        ) : null
      ) : activeStep === 0 ? (
        <WorkDefinition
          interiorArr={interiorArr}
          exclusionsArr={exclusionsArr}
          alternatesArr={alternatesArr}
          exteriorArr={exteriorArr}
          setAlternatesArr={setAlternatesArr}
          setExclusionsArr={setExclusionsArr}
          setExteriorArr={setExteriorArr}
          setInteriorArr={setInteriorArr}
          handleBack={handleBack}
          handleSkip={handleSkip}
          isStepOptional={isStepOptional}
          handleNext={handleNext}
          steps={steps}
          activeStep={activeStep}
          classes={classes}
          opportunityId={selectedData?.state?.selectedRowId}
          estimationData={selectedData?.state?.estimationData}
          setEstimationId={setEstimationId}
          setWorkDefinitionChanged={setWorkDefinitionChanged}
        />
      ) : activeStep === 1 ? (
        <RequestQuotation
          handleBack={handleBack}
          handleSkip={handleSkip}
          isStepOptional={isStepOptional}
          handleNext={handleNext}
          steps={steps}
          activeStep={activeStep}
          classes={classes}
          opportunityId={selectedData?.state?.selectedRowId}
        />
      ) : activeStep === 2 ? (
        <ThirdParty
          handleBack={handleBack}
          handleSkip={handleSkip}
          isStepOptional={isStepOptional}
          handleNext={handleNext}
          steps={steps}
          activeStep={activeStep}
          classes={classes}
          opportunityId={selectedData?.state?.selectedRowId}
        />
      ) : activeStep === 3 ? (
        <Estimation
          handleBack={handleBack}
          interiorArr={interiorArr}
          exclusionsArr={exclusionsArr}
          alternatesArr={alternatesArr}
          exteriorArr={exteriorArr}
          setAlternatesArr={setAlternatesArr}
          setExclusionsArr={setExclusionsArr}
          setExteriorArr={setExteriorArr}
          setInteriorArr={setInteriorArr}
          handleSkip={handleSkip}
          isStepOptional={isStepOptional}
          handleNext={handleNext}
          steps={steps}
          activeStep={activeStep}
          classes={classes}
          opportunityId={selectedData?.state?.selectedRowId}
          opportunityStatus={
            selectedData?.state?.estimationData?.opportunity?.status
          }
          estimationId={estimationId}
        />
      ) : null}
    </Box>
  );
}

export default EstimationsForm;
