import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchGCs } from "../../../redux/slice/ContactsSlice/ContactSlice";
import AppDialog from "../../common/AppDialog/AppDialog";
import { AddBulkGeneralContractor } from "../../../Apis/Opportunity/opportunityApis";
import { SnackBarContext } from "../../../context/SnackBarContext";
import Loader from "../../Loader/Loader";
import ContactsFormDialog from "../../Contacts/ContactsFormDialog/ContactsFormDialog";

function GCsDialog({ open, onClose, opportunityId, screen }) {
  const [alldata, setAlldata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showAddContactDialog, setShowAddContactDialog] = useState(false);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);

  const { AllGCs } = useSelector((state) => state?.Contacts);

  useEffect(() => {
    dispatch(fetchGCs("GC"));
  }, [dispatch]);

  useEffect(() => {
    if (AllGCs?.data) {
      setAlldata(AllGCs?.data);
      setFilteredData(AllGCs?.data);
    } else {
      setAlldata([]);
      setFilteredData([]);
    }
  }, [AllGCs]);

  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleFilterChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilterText(value);

    const filtered = alldata.filter(
      (item) =>
        item.display_name.toLowerCase().includes(value) ||
        item.email.toLowerCase().includes(value) ||
        item.phone.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const handleAction = async () => {
    setLoading(true);

    const payload = {
      opportunityId: opportunityId,
      contactIds: selectedRows,
      changeStatus: screen === "proposal" ? false : true,
    };
    console.log(payload);
    const response = await AddBulkGeneralContractor(payload);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    console.log("Response:", response);

    if (response?.success) {
      setSelectedRows([]);
      onClose(true);
    }
    setLoading(false);
  };

  return (
    <>
      <AppDialog
        open={open}
        onClose={() => {
          onClose(false);
        }}
        title="General Contractors"
        minHeight="auto"
        maxWidth="md"
      >
        <Box my={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={2}
          >
            <Box width={"50%"}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                value={filterText}
                onChange={handleFilterChange}
                sx={{ mb: 2 }}
              />
            </Box>
            <Box
              width={"50%"}
              sx={{ textAlign: "end" }}
            >
              {selectedRows.length > 0 ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ mx: "5px" }}
                    onClick={handleAction}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => setSelectedRows([])}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setShowAddContactDialog(true);
                  }}
                >
                  Add New
                </Button>
              )}
            </Box>
          </Box>

          <TableContainer
            component={Box}
            sx={{ marginTop: "5px", maxHeight: "300px" }}
          >
            <Table
              sx={{ minWidth: "100%" }}
              aria-label="simple table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Sr.</TableCell>
                  <TableCell>Select</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(item.contactId)}
                        onChange={() => handleSelectRow(item.contactId)}
                      />
                    </TableCell>
                    <TableCell>{item.display_name}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.phone}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {loading && <Loader />}
      </AppDialog>

      <ContactsFormDialog
        open={showAddContactDialog}
        onClose={(success) => {
          if (success) {
            dispatch(fetchGCs("GC"));
          }
          setShowAddContactDialog(false);
        }}
        screen="create_job"
      />
    </>
  );
}

export default GCsDialog;

GCsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  opportunityId: PropTypes.any,
  screen: PropTypes.any,
};
