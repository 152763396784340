import React, { useContext, useState } from "react";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as yup from "yup";
import AppDialog from "../../../../common/AppDialog/AppDialog";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import {
  CreateProjectTypeAPi,
  CreateSourceAPi,
  CreateTagAPi,
  UpdateProjectTypeAPi,
  UpdateSourceAPi,
  UpdateTagAPi,
} from "../../../../../Apis/OpportunitySettings/OpportunitySettingsApis";
// import { useDispatch } from "react-redux";
// import {
//   fetchProjectTypesData,
//   fetchSourcesData,
//   fetchTagsData,
// } from "../../../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = yup.object({
  title: yup.string().required("Field Required!"),
});

function AddUpdateDialog({ open, onClose, selectedRow, heading, onSuccess }) {
  // const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [selectedColor, setselectedColor] = useState("");
  const [colorErr, setColorErr] = useState(false);
  const [borderColor, setborderColor] = useState(null);
  const [colors] = useState([
    "#337CFF",
    "#FB8A3F",
    "#27B6BB",
    "#DB7093",
    "#FADA5E",
    "#32CD32",
    "#ff66ff",
    "#336600",
    "#b300b3",
    "#e64d00",
    "#999966",
  ]);
  useEffect(() => {
    if (selectedRow && heading === "Tag") {
      setselectedColor(selectedRow?.colorCode);
      setborderColor(colors.indexOf(selectedRow?.colorCode));
    }
  }, [selectedRow]);
  return (
    <AppDialog
      open={open}
      onClose={() => {
        setselectedColor("");
        setborderColor(null);
        setColorErr(false);
        onClose();
      }}
      title={selectedRow ? `Update ${heading}` : `Add ${heading}`}
      minHeight="auto"
    >
      <Box my={2}>
        <Formik
          validateOnChange={true}
          initialValues={{
            title: selectedRow?.title || "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            const formData = {
              title: data?.title,
              ...(heading === "Tag" && { colorCode: selectedColor }),
            };
            if (heading === "Tag" && selectedColor === "") {
              setColorErr(true);
              return;
            }
            setSubmitting(true);
            const response = selectedRow
              ? heading === "Source"
                ? await UpdateSourceAPi(selectedRow?.sourceId, formData)
                : heading === "Type"
                ? await UpdateProjectTypeAPi(selectedRow?.typeId, formData)
                : heading === "Tag"
                ? await UpdateTagAPi(selectedRow?.tagId, formData)
                : null
              : heading === "Source"
              ? await CreateSourceAPi(formData)
              : heading === "Type"
              ? await CreateProjectTypeAPi(formData)
              : heading === "Tag"
              ? await CreateTagAPi(formData)
              : null;

            response?.snackBarData?.message &&
              setsnackBarData(response?.snackBarData);

            if (response?.success) {
              // heading === "Source"
              //   ? dispatch(fetchSourcesData())
              //   : heading === "Type"
              //   ? dispatch(fetchProjectTypesData())
              //   : heading === "Tag"
              //   ? dispatch(fetchTagsData())
              //   : null;
              resetForm();
              setselectedColor("");
              setColorErr(false);
              setborderColor(null);
              onSuccess(
                heading === "Source"
                  ? response?.data?.sourceId
                  : heading === "Type"
                  ? response?.data?.typeId
                  : heading === "Tag"
                  ? response?.data?.tagId
                  : null
              );
              onClose();
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            errors,
            touched,
          }) => (
            <Form
              noValidate
              autoComplete="off"
            >
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                label={"Title"}
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors?.title && touched?.title}
                helperText={
                  errors?.title && touched?.title ? errors?.title : ""
                }
              />
              {heading === "Tag" && (
                <FormControl
                  fullWidth
                  sx={{
                    mt: "10px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <Typography color={colorErr ? "error" : ""}>
                    Select a Color for your tag:{" "}
                  </Typography>
                  {colors?.map((clr, idx) => {
                    return (
                      <Box
                        key={idx}
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: clr,
                          borderRadius: "100%",
                          border: borderColor === idx ? "2px solid white" : clr,
                          boxShadow:
                            borderColor === idx
                              ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                              : null,
                          transform:
                            borderColor === idx ? "scale(0.9.5)" : null,
                          transition: "0.2s",
                        }}
                        onClick={() => {
                          setColorErr(false);
                          setborderColor(idx);
                          setselectedColor(clr);
                        }}
                      ></Box>
                    );
                  })}
                </FormControl>
              )}

              <Box
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#f79c35"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={isSubmitting}
                />

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  {selectedRow ? "Update" : "Add"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </AppDialog>
  );
}

export default AddUpdateDialog;
AddUpdateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
  heading: PropTypes.any,
  onSuccess: PropTypes.func,
};
