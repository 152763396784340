import React from "react";
import AppDialog from "../../common/AppDialog/AppDialog";
import ContactsForm from "../ContactsForm";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function ContactsFormDialog({
  open,
  onClose,
  screen,
  contactDialogFor,
  setFieldValue,
}) {
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Add Contact"
      minHeight="auto"
      maxWidth="lg"
    >
      <Box>
        <ContactsForm
          parent="dialog"
          onClose={onClose}
          screen={screen}
          contactDialogFor={contactDialogFor}
          setFieldValue={setFieldValue}
        />
      </Box>
    </AppDialog>
  );
}

export default ContactsFormDialog;
ContactsFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  screen: PropTypes.string,
  contactDialogFor: PropTypes.string,
  setFieldValue: PropTypes.func,
};
