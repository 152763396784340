import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import NoData from "../../Assets/Images/no_data.png";

function ThirdPartyView({ thirdPartyData }) {
  return (
    <TableContainer sx={{ height: "350px", overflowY: "scroll" }}>
      <Table aria-label="simple table">
        <TableHead className="table-head">
          <TableRow>
            <TableCell>Sr</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Quotation</TableCell>

            <TableCell>Consider</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {thirdPartyData.length > 0 ? (
            thirdPartyData?.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {idx + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.description}
                </TableCell>
                <TableCell>{row?.company?.name}</TableCell>
                <TableCell
                  sx={{
                    cursor: row?.file && "pointer",
                    color: row?.file && "#551a8b",
                    textDecoration: row?.file && "underline",
                  }}
                  onClick={() => {
                    row?.file && window.open(row?.file, "_blank");
                  }}
                >
                  {row?.file ? `Open` : "N/A"}
                </TableCell>
                <TableCell>{row.considered ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={5} align="center">
                <img
                  style={{ marginTop: "65px" }}
                  width={100}
                  height={100}
                  src={NoData}
                  alt="No data available"
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ThirdPartyView;
ThirdPartyView.propTypes = {
  thirdPartyData: PropTypes.any,
};
