import { Box, Button, gridClasses } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { fetchFollowUpData } from "../../redux/slice/FollowUpSlice/FollowUpSlice";
import AddFollowUpsDialog from "../../components/FollowUps/AddFollowUpsDialog";

function Follow_Ups() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [showNextFollowUpDialog, setShowNextFollowUpDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [cachedRows, setCachedRows] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 100,
  });
  const dispatch = useDispatch();

  const { FollowUpData, status, TotalCount } = useSelector(
    (state) => state?.FollowUp
  );

  useEffect(() => {
    dispatch(
      fetchFollowUpData({
        page: pageState.page,
        pageSize: pageState.pageSize,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (FollowUpData) {
      setalldata(FollowUpData);
    } else {
      setalldata([]);
    }
  }, [FollowUpData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "id",
      headerName: "Sr",
      minWidth: 30,
      flex: "auto",
      renderCell: (params) => {
        return (pageState.page - 1) * pageState.pageSize + params.value;
      },
    },

    {
      field: "title",
      headerName: "Opportunity Title",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              color: `${
                params?.row?.status === "Pending"
                  ? "#FFC107"
                  : params?.row?.status === "Completed"
                  ? "#4CAF50"
                  : "#F44336"
              }`,
              fontWeight: "bold",
            }}
          >
            {params?.row?.status}
          </Box>
        );
      },
    },
    {
      field: "scheduleOn",
      headerName: "Schedule On",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "MM/DD/YYYY").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY "
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "MM/DD/YYYY").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY"
        );
      },
    },
    {
      field: "followUp",
      headerName: "Follow Up",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {moment(params?.row?.scheduleOn, "MM/DD/YYYY").isSameOrBefore(
              moment(),
              "day"
            ) ? (
              params?.row?.status === "Pending" ? (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => {
                    setSelectedRow(params?.row?.rowData);
                    setShowNextFollowUpDialog(true);
                  }}
                >
                  Send
                </Button>
              ) : (
                "N/A"
              )
            ) : (
              "N/A"
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          title: item?.opportunity?.title,
          status: item.status,
          scheduleOn: moment(item?.scheduleOn).format("MM/DD/YYYY"),
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(item?.updatedAt).format("MM/DD/YYYY"),
          rowData: item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    const fetchData = () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      if (cachedRows[pageState.page]) {
        setPageState((old) => ({ ...old, isLoading: false }));
        return;
      } else {
        try {
          dispatch(
            fetchFollowUpData({
              page: pageState.page,
              pageSize: pageState.pageSize,
            })
          );
        } catch (error) {
          console.error("Failed to fetch opportunities", error);
        } finally {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      }
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setCachedRows((prev) => ({
      ...prev,
      [pageState.page]: rows,
    }));
  }, [rows]);

  const rows1 = cachedRows[pageState.page] || [];
  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows1}
          pagination
          paginationMode="server"
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState.pageSize,
                page: pageState.page - 1,
              },
            },
          }}
          onPaginationModelChange={(model) => {
            console.log("model", model);
            setPageState((old) => ({ ...old, page: model.page + 1 }));
          }}
          pageSizeOptions={false}
          rowCount={TotalCount}
          loading={pageState.isLoading}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
        {status === "loading" ? <Loader /> : null}

        <AddFollowUpsDialog
          open={showNextFollowUpDialog}
          onClose={(callApi) => {
            setShowNextFollowUpDialog(false);
            setSelectedRow(null);

            if (callApi) {
              dispatch(
                fetchFollowUpData({
                  page: pageState.page,
                  pageSize: pageState.pageSize,
                })
              );
            }
          }}
          selectedRow={selectedRow}
        />
      </Box>
    </>
  );
}

export default Follow_Ups;
