import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  ContactsData: [],
  TotalCount: 0,
  Notes: null,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  AllGCs: null,
};

export const fetchContactsData = createAsyncThunk(
  "Contact/GetAll",
  async ({ page, pageSize }) => {
    const response = await axiosInstance.get(
      `/Contact/GetAll?page=${page}&limit=${pageSize}`
    );
    return response?.data;
  }
);
export const fetchGCs = createAsyncThunk(
  "Contact/GetContactsByType",
  async (type) => {
    const response = await axiosInstance.get(
      `Contact/GetContactsByType/${type}`
    );
    return response?.data;
  }
);
export const fetchNotes = createAsyncThunk("Notes/GetAll", async (data) => {
  const response = await axiosInstance.get(
    `Notes/GetAll?referenceType=${data.type || ""}&referenceId=${
      data.id || ""
    }&opportunityId=${data?.opportunityId || ""}`
  );
  return response?.data;
});

const ContactSlice = createSlice({
  name: "ContactSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchContactsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContactsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.ContactsData = action.payload.contactdata;
        state.TotalCount = action.payload.TotalCount;
      })
      .addCase(fetchContactsData.rejected, (state, action) => {
        state.ContactsData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchGCs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGCs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.AllGCs = action.payload;
      })
      .addCase(fetchGCs.rejected, (state, action) => {
        state.AllGCs = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.Notes = action.payload;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.Notes = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default ContactSlice.reducer;
