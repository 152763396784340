import Cookies from "universal-cookie";
import axiosInstance from "../../utils/AxiosInstance";

const cookies = new Cookies();

export const LoginAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/Login", data);
    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    if (response?.data?.success) {
      cookies.set("access_token", response?.data?.data?.token, {
        path: "/",
      });
      cookies.set("LoginId", response.data?.data?.user?.userId, {
        path: "/",
      });
    }

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const ForgotPasswordAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/ForgetPassword", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const VerifyOTPAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/VerifyOTP", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const ResetPasswordAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Auth/ResetPassword", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
