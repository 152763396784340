import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchThirdPartyData } from "../../../redux/slice/EstimationSlice/EstimationSlice";
import Loader from "../../Loader/Loader";
import ThirdPartyDialog from "./ThirdPartyDialog";
import DocumentsDialog from "../../DocumentsDialog/DocumentsDialog";
import {
  DeleteThirdPartyApi,
  UpdateConsideredApi,
} from "../../../Apis/Estimations/ThirdParty/ThirdPartyApis";
import AddDocumentDialog from "../../DocumentsDialog/AddDocumentDialog/AddDocumentDialog";
import AppConfirmationDialog from "../../ConfirmationDialog/ConfirmationDialog";
import { SnackBarContext } from "../../../context/SnackBarContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
function ThirdParty({
  handleBack,
  handleSkip,
  isStepOptional,
  handleNext,
  steps,
  activeStep,
  classes,
  opportunityId,
}) {
  const dispatch = useDispatch();
  const [documentApiCalling, setDocumentApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAddDocumentDialog, setShowAddDocumentDialog] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);

  const { OpportunityDocumentsData } = useSelector(
    (state) => state?.OpportunitySettings
  );
  const { ThirdPartyData, status2 } = useSelector(
    (state) => state?.Estimations
  );
  const [alldata, setalldata] = useState([]);
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(fetchThirdPartyData(opportunityId));
  }, [dispatch]);
  useEffect(() => {
    if (ThirdPartyData) {
      setalldata(ThirdPartyData);
    } else {
      setalldata([]);
    }
  }, [ThirdPartyData]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (documentApiCalling && OpportunityDocumentsData) {
      setDocumentApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setLoading(false);

      setDocumentApiCalling(false);
    }
  }, [OpportunityDocumentsData]);
  const handleConsider = async (status, id) => {
    const resp = await UpdateConsideredApi({
      considered: status,
      id: id,
    });
    if (resp.success) {
      dispatch(fetchThirdPartyData(opportunityId));
    }
  };
  const deleteItem = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteThirdPartyApi(selectedRow?.thirdPartyId);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      dispatch(fetchThirdPartyData(opportunityId));
      setSelectedRow(null);
    }
    setLoading(false);
  };
  return (
    <div className={classes.formContainer}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell>Sr</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Quotation</TableCell>
              <TableCell>Consider</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell align="right">
                <Button
                  color="primary"
                  sx={{
                    float: "right",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  New
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alldata.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {idx + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row?.description}
                </TableCell>
                <TableCell>{row?.company?.name}</TableCell>
                {row?.file ? (
                  <TableCell
                    sx={{
                      cursor: "pointer",
                      color: "#551a8b",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      window.open(row?.file, "_blank");
                    }}
                  >
                    {/* {document?.documentsUrl
                ? `${document?.documentsUrl?.slice(0, 30)}...`
                : ""} */}
                    {row?.file ? `Open` : ""}
                  </TableCell>
                ) : (
                  <TableCell>
                    <Button
                      onClick={() =>
                        setShowAddDocumentDialog(row?.thirdPartyId)
                      }
                      size="small"
                      variant="outlined"
                      color="info"
                    >
                      Upload
                    </Button>
                  </TableCell>
                )}
                <TableCell>
                  {row.considered ? (
                    <Button
                      onClick={() => handleConsider(false, row?.thirdPartyId)}
                      size="small"
                      variant="outlined"
                      color="error"
                    >
                      Mark Flase
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleConsider(true, row?.thirdPartyId)}
                      size="small"
                      variant="outlined"
                      color="success"
                      disabled={!row?.file}
                    >
                      Mark True
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ padding: "5px" }}
                      color="error"
                      onClick={() => {
                        setSelectedRow(row);
                        setShowConfirmation(true);
                      }}
                    >
                      <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {/* <Tooltip title="Documents">
                    <IconButton
                      sx={{ padding: "5px" }}
                      color="secondary"
                      onClick={() => {
                        setDocumentApiCalling(true);
                        setSelectedRow(row);
                        dispatch(
                          fetchOpportunityDocumentsData({
                            opportunityId: row?.opportunityId,
                            refName: "ThirdParty",
                            refId: row?.thirdPartyId,
                          })
                        );
                      }}
                    >
                      <ArticleIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip> */}

                <TableCell align="right"></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <ThreeDots
          height="40"
          width="40"
          radius="9"
          color="#f79c35"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={loading}
        />

        <Button onClick={handleBack} variant="outlined" color="error">
          Back
        </Button>
        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        <Button
          //   type="submit"
          onClick={handleNext}
          variant="contained"
          disabled={loading}
          color="primary"
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
      {status2 === "loading" || documentApiCalling ? <Loader /> : null}
      {open && (
        <ThirdPartyDialog
          opportunityId={opportunityId}
          open={open}
          onClose={onClose}
        />
      )}
      <DocumentsDialog
        refId={selectedRow?.thirdPartyId}
        refName={"ThirdParty"}
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />
      <AddDocumentDialog
        refName={"Third Party Quotation"}
        refId={showAddDocumentDialog}
        open={showAddDocumentDialog ? true : false}
        onClose={() => {
          setShowAddDocumentDialog(null);
          dispatch(fetchThirdPartyData(opportunityId));
        }}
        selectedRow={{ opportunityId: opportunityId }}
      />
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteItem();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Tag"
          title={`Are you sure, you want to delete third party quotation ${selectedRow?.company?.name}?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
    </div>
  );
}

export default ThirdParty;

ThirdParty.propTypes = {
  activeStep: PropTypes.any,
  steps: PropTypes.any,
  handleNext: PropTypes.any,
  isStepOptional: PropTypes.any,
  handleBack: PropTypes.any,
  handleSkip: PropTypes.any,
  placeholder: PropTypes.any,
  setTagCount: PropTypes.any,
  classes: PropTypes.any,
  opportunityId: PropTypes.any,
};
