import HomeIcon from "@mui/icons-material/Home";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import WorkIcon from "@mui/icons-material/Work";
import PreviewIcon from "@mui/icons-material/Preview";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactsIcon from "@mui/icons-material/Contacts";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SummarizeIcon from "@mui/icons-material/Summarize";

export const adminSideBarData = [
  {
    name: "Dashboard",
    icon: <HomeIcon />,
    url: "/",
    roleName: "Dashboard",
    subItems: [],
  },
  {
    name: "Bidding",
    icon: <EmojiPeopleIcon />,
    url: "/bidding",
    roleName: "Opportunity",
    open: false,
    subItems: [
      {
        name: "On Hold",
        url: "/bidding/on-hold",
        roleName: "Opportunity",
      },
      {
        name: "Awaiting Estimate",
        url: "/bidding/awaiting-estimate",
        roleName: "Opportunity",
      },
      {
        name: "Estimate In Progress",
        url: "/bidding/estimate-in-progress",
        roleName: "Opportunity",
      },
      {
        name: "Estimate Complete",
        url: "/bidding/estimate-complete",
        roleName: "Opportunity",
      },
      {
        name: "Open",
        url: "/bidding/open",
        roleName: "Opportunity",
      },
      {
        name: "Sold",
        url: "/bidding/sold",
        roleName: "Opportunity",
      },
      {
        name: "Lost",
        url: "/bidding/lost",
        roleName: "Opportunity",
      },
      {
        name: "No Opportunity",
        url: "/bidding/no-opportunity",
        roleName: "Opportunity",
      },
    ],
  },
  {
    name: "Qualifications",
    icon: <TroubleshootIcon />,
    url: "/qualifications",
    subItems: [],
    roleName: "Qualifications",
  },
  {
    name: "Create Job",
    icon: <WorkIcon />,
    url: "/create_Job",
    subItems: [],
    roleName: "Create_Job",
  },
  {
    name: "Estimating",
    icon: <QueryStatsIcon />,
    url: "/estimations",
    roleName: "Estimations",
    open: false,
    subItems: [
      {
        name: "Awaiting Estimate",
        url: "/estimations/awaiting-estimate",
        roleName: "Estimations",
      },
      {
        name: "Estimate In Progress",
        url: "/estimations/estimate-in-progress",
        roleName: "Estimations",
      },
      {
        name: "Estimate Complete",
        url: "/estimations/estimate-complete",
        roleName: "Estimations",
      },
    ],
  },
  {
    name: "Proposal Review",
    icon: <PreviewIcon />,
    url: "/proposal_review",
    subItems: [],
    roleName: "Proposal_Review",
  },
  {
    name: "Follow Ups",
    icon: <FollowTheSignsIcon />,
    url: "/follow_Ups",
    subItems: [],
    roleName: "Follow_Ups",
  },
  {
    name: "Contacts",
    icon: <ContactsIcon />,
    url: "/contacts",
    subItems: [],
    roleName: "Contacts",
  },
  {
    name: "Companies",
    icon: <ApartmentIcon />,
    url: "/companies",
    subItems: [],
    roleName: "Companies",
  },
  {
    name: "Documents",
    icon: <AssignmentIcon />,
    url: "/documents",
    subItems: [],
    roleName: "Documents",
  },
  {
    name: "Reports",
    icon: <SummarizeIcon />,
    roleName: "Reports",
    open: false,
    subItems: [
      {
        name: "Sales Summary",
        url: "/repots/sales-summary",
        roleName: "Reports",
      },
    ],
  },
  {
    name: "Settings",
    icon: <SettingsIcon />,
    url: "/settings",
    subItems: [],
    roleName: "Settings",
  },
];
