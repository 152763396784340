import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchOpportunitySummaryData } from "../../../redux/slice/DashboardSlice/DashboardSlice";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function StatCards() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { OpportunitySummaryData } = useSelector((state) => state?.Dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOpportunitySummaryData());
  }, []);
  const handleClick = (key) => {
    let screen =
      key === "On Hold"
        ? "on-hold"
        : key === "Pending"
        ? "awaiting-estimate"
        : key === "In Progress"
        ? "estimate-in-progress"
        : key === "Open"
        ? "open"
        : key === "Lost"
        ? "lost"
        : key === "Sold"
        ? "sold"
        : key === "Under Review"
        ? "proposal_review"
        : key === "No Opportunity"
        ? "no-opportunity"
        : "";
    if (screen) {
      if (screen === "proposal_review") {
        navigate(`/proposal_review`);
      } else {
        navigate(`/bidding/${screen}`);
      }
    }
  };
  return (
    <div className={classes.gridContainer}>
      <Grid container spacing={3}>
        {OpportunitySummaryData &&
          Object?.keys(OpportunitySummaryData)?.map((key, index) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
              <Box
                sx={{
                  backgroundColor:
                    key === "On Hold"
                      ? "#ffa07a"
                      : key === "Pending"
                      ? "#f0e68c"
                      : key === "In Progress"
                      ? "#87ceeb"
                      : key === "Open"
                      ? "#98fb98"
                      : key === "Lost"
                      ? "#ff6347"
                      : key === "Sold"
                      ? "#3cb371"
                      : key === "No Opportunity"
                      ? "#d3d3d3"
                      : "#b0c4de",
                  color:
                    key === "On Hold"
                      ? "#fff"
                      : key === "Pending"
                      ? "#8b4513"
                      : key === "In Progress"
                      ? "#fff"
                      : key === "Open"
                      ? "#006400"
                      : key === "Lost"
                      ? "#8b0000"
                      : key === "Sold"
                      ? "#ffffff"
                      : key === "No Opportunity"
                      ? "#696969"
                      : "#4682b4",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  borderRadius: "0.75rem",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.6)",
                  },
                }}
                onClick={() => handleClick(key)}
              >
                <Box className={classes.statsContainer}>
                  <Box className={`${classes.infoContainer}`}>
                    <Typography
                      sx={{
                        color: "primary",
                        lineHeight: 1.5,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {key}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1.5rem",
                      }}
                    >
                      {OpportunitySummaryData[key]}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
export default StatCards;

const useStyles = makeStyles({
  gridContainer: {
    margin: "48px 0",
  },

  statsContainer: {
    padding: "16px",
  },

  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});
