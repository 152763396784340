import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import AppDialog from "../common/AppDialog/AppDialog";
import { useSelector } from "react-redux";
import AddDocumentDialog from "./AddDocumentDialog/AddDocumentDialog";

function DocumentsDialog({ open, onClose, selectedRow, refName, refId }) {
  const [showAddDocumentDialog, setShowAddDocumentDialog] = useState(false);

  const { OpportunityDocumentsData } = useSelector(
    (state) => state?.OpportunitySettings
  );

  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title="Documents"
        minHeight="auto"
        maxWidth="md"
      >
        <Box my={2}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setShowAddDocumentDialog(true);
              }}
            >
              Add Document
            </Button>
          </Box>
          <TableContainer
            component={Box}
            sx={{ marginTop: "10px", maxHeight: "300px" }}
          >
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Sr. </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>File Type</TableCell>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {OpportunityDocumentsData?.map((document, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{document?.type}</TableCell>
                      <TableCell>{document?.fileType}</TableCell>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          color: "#551a8b",
                          textDecoration: "underline",
                          wordBreak: "break-word",
                        }}
                        onClick={() => {
                          window.open(document?.documentsUrl, "_blank");
                        }}
                      >
                        {/* {document?.documentsUrl
                          ? `${document?.documentsUrl?.slice(0, 30)}...`
                          : ""} */}
                        {document?.documentsUrl ? `Open` : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AppDialog>

      <AddDocumentDialog
        refName={refName}
        refId={refId}
        open={showAddDocumentDialog}
        onClose={() => {
          setShowAddDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />
    </>
  );
}

export default DocumentsDialog;
DocumentsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
  refId: PropTypes.any,
  refName: PropTypes.any,
};
