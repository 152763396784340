import { TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function GetOtp({ values, errors, touched, handleChange, handleBlur }) {
  return (
    <>
      <TextField
        label="Email"
        variant="outlined"
        size="small"
        type="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
    </>
  );
}

export default GetOtp;
GetOtp.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};
