import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { ThreeDots } from "react-loader-spinner";
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TextField,
  InputAdornment,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SnackBarContext } from "../../context/SnackBarContext";
import { CreateUpdateEstimationApi } from "../../Apis/Estimations/CreateUpdateEstimation/CreateUpdateEstimation";
import { ChangeOpportunityStatusBulk } from "../../Apis/Opportunity/opportunityApis";
function Estimation({
  handleBack,
  // handleSkip,
  // isStepOptional,
  // handleNext,
  // steps,
  // activeStep,
  opportunityId,
  classes,
  exclusionsArr,
  setAlternatesArr,
  setExteriorArr,
  setInteriorArr,
  alternatesArr,
  exteriorArr,
  interiorArr,
  estimationId,
  opportunityStatus,
}) {
  const [loading, setLoading] = useState(false);

  const { setsnackBarData } = useContext(SnackBarContext);
  const getTotalPrice = () => {
    return (
      (interiorArr?.reduce(
        (total, item) => Number(total) + Number(item.price),
        0
      ) || 0) +
      (alternatesArr?.reduce(
        (total, item) => Number(total) + Number(item.price),
        0
      ) || 0) +
      (exteriorArr?.reduce(
        (total, item) => Number(total) + Number(item.price),
        0
      ) || 0)
    );
  };
  const handleSave = async () => {
    setLoading(true);
    const formData = {
      estimationId,
      interiorPainting: interiorArr
        ?.filter((ele) => ele?.price)
        ?.map((item) => ({
          customKey: item?.customKey,
          price: parseFloat(item?.price),
        })),
      exteriorPainting: exteriorArr
        ?.filter((ele) => ele?.price)
        ?.map((item) => ({
          customKey: item?.customKey,
          price: parseFloat(item?.price),
        })),
      alternates: alternatesArr
        ?.filter((ele) => ele?.price)
        ?.map((item) => ({
          customKey: item?.customKey,
          price: parseFloat(item?.price),
        })),
      exclusion: exclusionsArr
        ?.filter((ele) => ele?.price)
        ?.map((item) => ({
          customKey: item?.customKey,
          price: parseFloat(item?.price),
        })),
    };

    const response = await CreateUpdateEstimationApi(formData);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    // if (response?.success) {
    // }

    setLoading(false);
  };
  const handleRequestForReview = async () => {
    const response = await ChangeOpportunityStatusBulk({
      status: "Under Review",
      opportunityIds: [opportunityId],
    });

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
  };
  console.log(estimationId);
  return (
    <div className={classes.formContainer}>
      <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
        <Button
          onClick={handleRequestForReview}
          variant="contained"
          color="secondary"
          disabled={
            opportunityStatus == "Under Review" || !estimationId ? true : false
          }
        >
          Send for Review
        </Button>
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography className={classes?.fieldsHeading}>
            Interior Painting ($
            {interiorArr
              ?.reduce((total, item) => Number(total) + Number(item?.price), 0)
              ?.toLocaleString() || 0}
            )
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>Key</TableCell>
                  <TableCell sx={{ width: "60%" }}>Description</TableCell>
                  <TableCell sx={{ width: "30%" }}>Price</TableCell>
                  {/* <TableCell sx={{ width: "10%" }}></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {interiorArr?.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell sx={{ width: "10%" }}>
                      {row?.customKey}
                    </TableCell>

                    <TableCell sx={{ width: "50%" }}>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: row?.description,
                        }}
                      >
                        {}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: "30%" }}>
                      <TextField
                        size="small"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={interiorArr[idx]?.price || ""}
                        onChange={(e) => {
                          let value = e.target.value;
                          let newArr = [...interiorArr];
                          newArr[idx]["price"] = value;
                          setInteriorArr(newArr);
                        }}
                      />
                    </TableCell>
                    {/* <TableCell sx={{ width: "10%" }}>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ padding: "5px" }}
                          color="error"
                          onClick={() => {
                            setInteriorArr((prevExteriorArr) =>
                              prevExteriorArr.filter(
                                (item) => item.key !== row?.key
                              )
                            );
                          }}
                        >
                          <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography className={classes?.fieldsHeading}>
            Exterior Painting ($
            {exteriorArr
              ?.reduce((total, item) => Number(total) + Number(item?.price), 0)
              ?.toLocaleString() || 0}
            )
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>Key</TableCell>
                  <TableCell sx={{ width: "60%" }}>Description</TableCell>
                  <TableCell sx={{ width: "30%" }}>Price</TableCell>
                  {/* <TableCell sx={{ width: "10%" }}></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {exteriorArr?.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell sx={{ width: "10%" }}>
                      {row?.customKey}
                    </TableCell>

                    <TableCell sx={{ width: "50%" }}>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: row?.description,
                        }}
                      >
                        {}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: "30%" }}>
                      <TextField
                        size="small"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={exteriorArr[idx]?.price || ""}
                        onChange={(e) => {
                          let value = e.target.value;
                          let newArr = [...exteriorArr];
                          newArr[idx]["price"] = value;
                          setExteriorArr(newArr);
                        }}
                      />
                    </TableCell>
                    {/* <TableCell sx={{ width: "10%" }}>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ padding: "5px" }}
                          color="error"
                          onClick={() => {
                            setExteriorArr((prevExteriorArr) =>
                              prevExteriorArr.filter(
                                (item) => item.key !== row?.key
                              )
                            );
                          }}
                        >
                          <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography className={classes?.fieldsHeading}>
            Alternates ($
            {alternatesArr
              ?.reduce((total, item) => Number(total) + Number(item?.price), 0)
              ?.toLocaleString() || 0}
            )
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>Key</TableCell>
                  <TableCell sx={{ width: "60%" }}>Description</TableCell>
                  <TableCell sx={{ width: "30%" }}>Price</TableCell>
                  {/* <TableCell sx={{ width: "10%" }}></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {alternatesArr?.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell sx={{ width: "10%" }}>
                      {row?.customKey}
                    </TableCell>
                    <TableCell sx={{ width: "50%" }}>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: row?.description,
                        }}
                      >
                        {}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: "30%" }}>
                      <TextField
                        size="small"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={alternatesArr[idx]?.price || ""}
                        onChange={(e) => {
                          let value = e.target.value;
                          let newArr = [...alternatesArr];
                          newArr[idx]["price"] = value;
                          setAlternatesArr(newArr);
                        }}
                      />
                    </TableCell>
                    {/* <TableCell sx={{ width: "10%" }}>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ padding: "5px" }}
                          color="error"
                          onClick={() => {
                            setAlternatesArr((prevExteriorArr) =>
                              prevExteriorArr.filter(
                                (item) => item.key !== row?.key
                              )
                            );
                          }}
                        >
                          <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography className={classes?.fieldsHeading}>Exclusions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell sx={{ width: "10%" }}>Key</TableCell>
                  <TableCell sx={{ width: "90%" }}>Description</TableCell>
                  {/* <TableCell sx={{ width: "30%" }}>Price</TableCell> */}
                  {/* <TableCell sx={{ width: "10%" }}></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {exclusionsArr.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell sx={{ width: "10%" }}>
                      {row?.customKey}
                    </TableCell>

                    <TableCell sx={{ width: "80%" }}>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: row?.description,
                        }}
                      ></Box>
                    </TableCell>
                    {/* <TableCell sx={{ width: "30%" }}>
                      <TextField
                        size="small"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={exclusionsArr[idx]?.price || ""}
                        onChange={(e) => {
                          let value = e.target.value;
                          let newArr = [...exclusionsArr];
                          newArr[idx]["price"] = value;
                          setExclusionsArr(newArr);
                        }}
                      />
                    </TableCell> */}
                    {/* <TableCell sx={{ width: "10%" }}>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ padding: "5px" }}
                          color="error"
                          onClick={() => {
                            setExclusionsArr((prevExteriorArr) =>
                              prevExteriorArr.filter(
                                (item) => item.key !== row?.key
                              )
                            );
                          }}
                        >
                          <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ display: "flex", justifyContent: "end", my: 2 }}>
        <TextField
          size="small"
          label="Total"
          disabled={true}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: { fontWeight: "bold" },
          }}
          value={getTotalPrice()}
        />
      </Box>
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <ThreeDots
          height="40"
          width="40"
          radius="9"
          color="#f79c35"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={loading}
        />

        <Button
          onClick={handleBack}
          variant="outlined"
          color="error"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || !estimationId}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </div>
  );
}

export default Estimation;
Estimation.propTypes = {
  activeStep: PropTypes.any,
  steps: PropTypes.any,
  handleNext: PropTypes.any,
  isStepOptional: PropTypes.any,
  handleBack: PropTypes.any,
  handleSkip: PropTypes.any,
  placeholder: PropTypes.any,
  setTagCount: PropTypes.any,
  classes: PropTypes.any,
  setExclusionsArr: PropTypes.func,
  setExteriorArr: PropTypes.func,
  setAlternatesArr: PropTypes.func,
  setInteriorArr: PropTypes.func,
  exclusionsArr: PropTypes.array,
  alternatesArr: PropTypes.array,
  interiorArr: PropTypes.array,
  exteriorArr: PropTypes.array,
  estimationId: PropTypes.any,
  opportunityId: PropTypes.any,
  opportunityStatus: PropTypes.any,
};
