import axiosInstance from "../../utils/AxiosInstance";

export const CreateRoleAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Role/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.message,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateRoleAPi = async (roleId, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(`/Role/Update/${roleId}`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.message,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
