import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  FollowUpData: null,
  TotalCount: 0,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
};

export const fetchFollowUpData = createAsyncThunk(
  "FollowUps/GetAll",
  async ({ page, pageSize }) => {
    const response = await axiosInstance.get(
      `/FollowUps/GetAll?page=${page}&limit=${pageSize}`
    );
    return response?.data;
  }
);

const FollowUpSlice = createSlice({
  name: "FollowUpSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchFollowUpData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFollowUpData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.FollowUpData = action.payload.data.getFollowUps;
        state.TotalCount = action.payload.data.TotalCount;
      })
      .addCase(fetchFollowUpData.rejected, (state, action) => {
        state.FollowUpData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default FollowUpSlice.reducer;
