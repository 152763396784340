import React, { useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { SnackBarContext } from "../../../context/SnackBarContext";
import AppDialog from "../../common/AppDialog/AppDialog";
import { fetchUnderReviewOpportunity } from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import moment from "moment";
import { MarkOpenApi } from "../../../Apis/ProposalReview/ProposalReviewApi";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

const validationSchema = yup.object({
  opportunities: yup.array().of(
    yup.object().shape({
      opportunityId: yup.string().required("Field Required!"),
      opportunityValue: yup.string().required("Field Required!"),
    })
  ),
});

function MarkOpenDialog({ open, onClose, checkedRowsData }) {
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title="Mark Open"
      minHeight="auto"
    >
      <Box my={2}>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{
            opportunities: checkedRowsData?.map((row) => ({
              opportunityId: row?.opportunityId || "",
              opportunityValue: row?.opportunityValue || "",
              jobNo: row?.jobNo || "",
            })),
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            let formData = {
              followUpDate: moment().add(1, "day"),
              opportunities: data.opportunities?.map((item) => ({
                opportunityId: item?.opportunityId,
                opportunityValue: item?.opportunityValue,
              })),
            };

            const response = await MarkOpenApi(formData);
            setSubmitting(false);

            response?.snackBarData?.message &&
              setsnackBarData(response?.snackBarData);
            if (response?.success) {
              dispatch(fetchUnderReviewOpportunity());
              resetForm();
              onClose();
            }
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form
                noValidate
                autoComplete="off"
              >
                <FieldArray name="opportunities">
                  {() => (
                    <Box>
                      <TableContainer
                        component={Box}
                        sx={{ marginTop: "10px", maxHeight: "300px" }}
                      >
                        <Table
                          sx={{ minWidth: "100%" }}
                          aria-label="simple table"
                        >
                          <TableHead className="table-head">
                            <TableRow>
                              <TableCell>Sr. </TableCell>
                              <TableCell>Job #</TableCell>
                              <TableCell>Value</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.opportunities?.map((opportunity, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    verticalAlign: "baseline",
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell sx={{ minWidth: "120px" }}>
                                    {values.opportunities[index]?.jobNo}
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      variant="outlined"
                                      type="number"
                                      size="small"
                                      sx={{ minWidth: "150px" }}
                                      fullWidth
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <AttachMoneyOutlinedIcon color="primary" />
                                          </InputAdornment>
                                        ),
                                      }}
                                      name={`opportunities[${index}].opportunityValue`}
                                      value={
                                        values.opportunities[index]
                                          ?.opportunityValue
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      error={
                                        errors?.opportunities &&
                                        touched?.opportunities
                                          ? errors?.opportunities[index]
                                              ?.opportunityValue &&
                                            touched?.opportunities[index]
                                              ?.opportunityValue
                                          : false
                                      }
                                      helperText={
                                        errors?.opportunities &&
                                        touched?.opportunities
                                          ? errors?.opportunities[index]
                                              ?.opportunityValue &&
                                            touched?.opportunities[index]
                                              ?.opportunityValue
                                            ? errors?.opportunities[index]
                                                ?.opportunityValue
                                            : ""
                                          : ""
                                      }
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </FieldArray>

                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                >
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppDialog>
  );
}

export default MarkOpenDialog;
MarkOpenDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  checkedRowsData: PropTypes.array,
};
