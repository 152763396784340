import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequestQuoteData } from "../../../redux/slice/EstimationSlice/EstimationSlice";
import Loader from "../../Loader/Loader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RequestQuotationDialog from "./RequestQuotationDialog";
import DocumentsDialog from "../../DocumentsDialog/DocumentsDialog";
import ProductInfoDialog from "./ProductInfoDialog";
import AddDocumentDialog from "../../DocumentsDialog/AddDocumentDialog/AddDocumentDialog";
import {
  DeleteQuoteApi,
  UpdateConsideredApi,
} from "../../../Apis/Estimations/RequestForQuotation/RequestForQuotation";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AppConfirmationDialog from "../../ConfirmationDialog/ConfirmationDialog";
import { SnackBarContext } from "../../../context/SnackBarContext";

function RequestQuotation({
  handleBack,
  handleSkip,
  isStepOptional,
  handleNext,
  steps,
  activeStep,
  classes,
  opportunityId,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dispatch = useDispatch();
  const { RequestQuoteData, status } = useSelector(
    (state) => state?.Estimations
  );
  const [documentApiCalling, setDocumentApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showAddDocumentDialog, setShowAddDocumentDialog] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);

  const [selectedRow, setSelectedRow] = useState(null);
  const { OpportunityDocumentsData } = useSelector(
    (state) => state?.OpportunitySettings
  );
  const [alldata, setalldata] = useState([]);
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(fetchRequestQuoteData(opportunityId));
  }, [dispatch]);
  useEffect(() => {
    if (RequestQuoteData) {
      setalldata(RequestQuoteData);
    } else {
      setalldata([]);
    }
  }, [RequestQuoteData]);
  const [loading, setLoading] = useState(false);
  console.log(setLoading);
  useEffect(() => {
    if (documentApiCalling && OpportunityDocumentsData) {
      setDocumentApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setDocumentApiCalling(false);
    }
  }, [OpportunityDocumentsData]);
  const handleConsider = async (status, id) => {
    const resp = await UpdateConsideredApi({
      considered: status,
      id: id,
    });
    if (resp.success) {
      dispatch(fetchRequestQuoteData(opportunityId));
    }
  };
  const deleteItem = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteQuoteApi(selectedRow?.quotationId);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      dispatch(fetchRequestQuoteData(opportunityId));
      setSelectedRow(null);
    }
    setLoading(false);
  };
  return (
    <div className={classes.formContainer}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead className="table-head">
            <TableRow>
              <TableCell>Sr</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Reference</TableCell>
              <TableCell>Consider</TableCell>
              <TableCell>Action</TableCell>
              <TableCell align="right">
                <Button
                  color="primary"
                  sx={{
                    float: "right",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  New
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alldata.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{row?.company?.name}</TableCell>
                {row?.file ? (
                  <TableCell
                    sx={{
                      cursor: row?.file && "pointer",
                      color: row?.file && "#551a8b",
                      textDecoration: row?.file && "underline",
                    }}
                    onClick={() => {
                      row?.file && window.open(row?.file, "_blank");
                    }}
                  >
                    {/* {document?.documentsUrl
                ? `${document?.documentsUrl?.slice(0, 30)}...`
                : ""} */}
                    {row?.file ? `Open` : ""}
                  </TableCell>
                ) : (
                  <TableCell>
                    <Button
                      onClick={() => setShowAddDocumentDialog(row?.quotationId)}
                      size="small"
                      variant="outlined"
                      color="info"
                    >
                      Upload
                    </Button>
                  </TableCell>
                )}
                <TableCell>
                  {row.considered ? (
                    <Button
                      onClick={() => handleConsider(false, row?.quotationId)}
                      size="small"
                      variant="outlined"
                      color="error"
                    >
                      Mark Flase
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleConsider(true, row?.quotationId)}
                      size="small"
                      variant="outlined"
                      color="success"
                      disabled={!row?.file}
                    >
                      Mark True
                    </Button>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="View Products">
                    <IconButton
                      sx={{ padding: "5px" }}
                      color="info"
                      onClick={() => {
                        setSelectedRow(row);
                        setShowInfoDialog(true);
                      }}
                    >
                      <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ padding: "5px" }}
                      color="error"
                      onClick={() => {
                        setSelectedRow(row);
                        setShowConfirmation(true);
                      }}
                    >
                      <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell align="right"></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <ThreeDots
          height="40"
          width="40"
          radius="9"
          color="#f79c35"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={loading}
        />

        <Button
          onClick={handleBack}
          variant="outlined"
          color="error"
        >
          Back
        </Button>
        {isStepOptional(activeStep) && (
          <Button
            color="inherit"
            onClick={handleSkip}
            sx={{ mr: 1 }}
          >
            Skip
          </Button>
        )}
        <Button
          //   type="submit"
          onClick={handleNext}
          variant="contained"
          disabled={loading}
          color="primary"
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
      {status === "loading" || documentApiCalling ? <Loader /> : null}
      {open && (
        <RequestQuotationDialog
          opportunityId={opportunityId}
          open={open}
          onClose={onClose}
        />
      )}
      <DocumentsDialog
        refId={selectedRow?.quotationId}
        refName={"Quotation"}
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />
      {showInfoDialog && (
        <ProductInfoDialog
          open={showInfoDialog}
          onClose={() => {
            setSelectedRow(null);
            setShowInfoDialog(false);
          }}
          selectedRow={selectedRow}
        />
      )}
      <AddDocumentDialog
        refName={"Product Quotation"}
        refId={showAddDocumentDialog}
        open={showAddDocumentDialog ? true : false}
        onClose={() => {
          setShowAddDocumentDialog(null);
          dispatch(fetchRequestQuoteData(opportunityId));
        }}
        selectedRow={{ opportunityId: opportunityId }}
      />
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteItem();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Tag"
          title={`Are you sure, you want to delete quotation ${selectedRow?.company?.name}?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
    </div>
  );
}

export default RequestQuotation;

RequestQuotation.propTypes = {
  activeStep: PropTypes.any,
  steps: PropTypes.any,
  handleNext: PropTypes.any,
  isStepOptional: PropTypes.any,
  handleBack: PropTypes.any,
  handleSkip: PropTypes.any,
  placeholder: PropTypes.any,
  setTagCount: PropTypes.any,
  classes: PropTypes.any,
  opportunityId: PropTypes.any,
};
