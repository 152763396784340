import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AppDialog from "../common/AppDialog/AppDialog";

function DescriptionDialog({ open, onClose, description }) {
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title="Description"
      minHeight="auto"
    >
      <Box my={2}>
        <Typography>{description}</Typography>
      </Box>
    </AppDialog>
  );
}

export default DescriptionDialog;
DescriptionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  description: PropTypes.any,
};
