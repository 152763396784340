import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { CreateFollowUpsApi } from "../../../Apis/FollowUps/FollowUps";
import AppDialog from "../../common/AppDialog/AppDialog";
import { SnackBarContext } from "../../../context/SnackBarContext";

const validationSchema = Yup.object({
  scheduleOn: Yup.string().required("Field Required!"),
});

function AddScheduleOnDialog({ open, onClose, selectedRow }) {
  const { setsnackBarData } = useContext(SnackBarContext);

  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Schedule Follow-Up"
      minHeight="auto"
    >
      <Formik
        initialValues={{
          scheduleOn: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          let formData = {
            opportunityId: selectedRow?.opportunityId,
            scheduleOn: moment.utc(values?.scheduleOn),
          };

          const response = await CreateFollowUpsApi(formData);

          setSubmitting(false);
          response?.snackBarData?.message &&
            setsnackBarData(response?.snackBarData);

          if (response?.success) {
            resetForm();
            onClose(true);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isSubmitting,
        }) => {
          return (
            <Form>
              <Box
                my={2}
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <TextField
                  label="Schedule On"
                  variant="outlined"
                  type="datetime-local"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  inputProps={{
                    min: moment().format("YYYY-MM-DDTHH:mm"),
                  }}
                  name="scheduleOn"
                  value={values.scheduleOn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.scheduleOn && touched?.scheduleOn}
                  helperText={
                    errors?.scheduleOn && touched?.scheduleOn
                      ? errors?.scheduleOn
                      : ""
                  }
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#f79c35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isSubmitting}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </AppDialog>
  );
}

export default AddScheduleOnDialog;
AddScheduleOnDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
};
