import React, { useContext, useEffect, useState } from "react";
import { Box, InputAdornment, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../context/useAuth";
import { SnackBarContext } from "../../context/SnackBarContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserRolesData } from "../../redux/slice/RolesSlice/RolesSlice";
import bgImg from "../../Assets/Images/Authentication/bgImg.jpg";
import BrandLogo from "../../Assets/Images/Authentication/authImg.jpg";
import { LoginAPi } from "../../Apis/Auth/AuthApis";
import { adminSideBarData } from "../../Resources/SidebarData";

const validationSchema = yup.object().shape({
  email: yup
    .string("Please enter email")
    .email("Please enter valid email")
    .required("Email is required"),
  passwordHash: yup.string("Enter Password").required("Password is required"),
});

function SignInForm() {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { UserRolesData } = useSelector((state) => state?.Roles);

  const { user, signup } = useAuth();

  const { setsnackBarData } = useContext(SnackBarContext);
  const { setBreadcrumbsItems } = useContext(BreadcrumbsContext);

  useEffect(() => {
    if (user) {
      setBreadcrumbsItems([
        {
          id: 0,
          url: "/",
          name: "Dashboard",
        },
        {
          id: 1,
          url: "/",
          name: "Home",
        },
      ]);
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (UserRolesData) {
      let dashboard = UserRolesData?.Screens?.find(
        (role) => role?.name === "Dashboard"
      );

      if (dashboard) {
        let dashboardUrl = adminSideBarData?.find(
          (item) => item?.roleName === "Dashboard"
        )?.url;
        navigate(dashboardUrl);
      } else {
        let firstScreen = adminSideBarData?.find(
          (item) => item?.roleName === UserRolesData?.Screens[0]?.name
        );

        if (firstScreen?.subItems?.length) {
          navigate(firstScreen?.subItems[0]?.url);
        } else {
          navigate(firstScreen?.url);
        }
      }
    }
  }, [UserRolesData]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      passwordHash: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const response = await LoginAPi(values);

      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);

      if (response?.success) {
        signup(response?.data?.user?.userId);
        dispatch(fetchUserRolesData());
        setBreadcrumbsItems([
          {
            id: 0,
            url: "/",
            name: "Dashboard",
          },
          {
            id: 1,
            url: "/",
            name: "Home",
          },
        ]);
        resetForm();
      }
      setSubmitting(false);
    },
  });

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.overlay}></Box>
      <Box
        className={classes.cardContainer}
        sx={{
          minWidth: { xs: "22rem", sm: "24rem" },
        }}
      >
        <Box className={classes.whiteContainer}>
          <Box
            sx={{
              height: "30px",
            }}
          ></Box>
          <Box className={classes.headingContainer}>
            <img
              src={BrandLogo}
              alt="logo"
              className={classes.brandLogo}
            />
          </Box>
          <form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
            <TextField
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              label="Email"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <TextField
              type={showPassword ? "text" : "password"}
              name="passwordHash"
              value={formik.passwordHash}
              onChange={formik.handleChange}
              label="Password"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ fontSize: "20px" }} />
                      ) : (
                        <Visibility sx={{ fontSize: "20px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.passwordHash &&
                Boolean(formik.errors.passwordHash)
              }
              helperText={
                formik.touched.passwordHash && formik.errors.passwordHash
              }
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  lineHeight: 1.5,
                  color: "#f79c35",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot Password
              </Typography>
            </Box>

            <LoadingButton
              type="submit"
              variant="contained"
              disableElevation
              color="primary"
              sx={{
                fontSize: "0.75rem",
                fontWeight: 700,
                lineHeight: "1rem",
                padding: "0.75rem 1.5rem",
                backgroundImage: !formik?.isSubmitting
                  ? "linear-gradient(to right top, #f69220, #fdcb91)"
                  : "",
              }}
              loading={formik?.isSubmitting}
              loadingIndicator="Signing In…"
            >
              Sign in
            </LoadingButton>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
export default SignInForm;

const useStyles = makeStyles(() => ({
  mainContainer: {
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    zIndex: 0,
  },

  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    backgroundColor: "#00000080",
  },

  brandLogo: {
    display: "block",
    width: "250px",
    height: "90px",
    borderRadius: "20px",
    objectFit: "contain",
  },

  cardContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },

  whiteContainer: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    borderRadius: "0.75rem",
  },

  headingContainer: {
    backgroundColor: "#fff",
    boxShadow: "#fcdbb6 0px 2px 8px 0px",
    borderRadius: "0.75rem",
    height: "90px",
    display: "grid",
    placeItems: "center",
    margin: "-3.5rem 1rem 1rem",
    color: "#fff",
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1.5rem",
  },
}));
