import { Autocomplete, Box, Button, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDocumentPopulate,
  fetchOpportunityDocumentsData,
} from "../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import Loader from "../../components/Loader/Loader";
import * as Yup from "yup";
import { useFormik } from "formik";
import DocumentTypes from "../../components/Documents/DocumentTypes";

function Documents() {
  const dispatch = useDispatch();

  const [viewFiles, setviewFiles] = useState(false);
  const { DocumentPopulateData, OpportunityDocumentsData, status } =
    useSelector((state) => state?.OpportunitySettings);
  const validationSchema = Yup.object({
    opportunityId: Yup.string().required("Field required!"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      opportunityId: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { setSubmitting }) => {
      setviewFiles(false);
      let data = {
        opportunityId: values.opportunityId,
      };
      dispatch(fetchOpportunityDocumentsData(data));
      setSubmitting(true);

      setSubmitting(false);
    },
  });
  useEffect(() => {
    dispatch(fetchAllDocumentPopulate());
    let data = {
      opportunityId: 0,
    };
    dispatch(fetchOpportunityDocumentsData(data));
  }, [dispatch]);
  return (
    <>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Box
          gap={2}
          display="flex"
          alignItems="center"
          sx={{ flexWrap: "wrap" }}
        >
          <Autocomplete
            options={DocumentPopulateData || []}
            getOptionLabel={(option) =>
              `${option?.title}${option?.jobNo ? ` (${option?.jobNo})` : ""}`
            }
            value={
              DocumentPopulateData?.find(
                (opp) => opp?.opportunityId === formik?.values?.opportunityId
              ) || null
            }
            onChange={(event, newValue) => {
              formik.setFieldValue(
                "opportunityId",
                newValue ? newValue?.opportunityId : ""
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Opportunity"
                variant="outlined"
                size="small"
                sx={{ minWidth: "300px" }}
                error={
                  formik.errors?.opportunityId && formik.touched?.opportunityId
                }
                helperText={
                  formik.errors?.opportunityId && formik.touched?.opportunityId
                    ? formik.errors?.opportunityId
                    : ""
                }
              />
            )}
          />
          <Button
            type="submit"
            variant="contained"
            size="small"
            color="secondary"
          >
            Get
          </Button>
        </Box>
      </form>
      <DocumentTypes
        viewFiles={viewFiles}
        setviewFiles={setviewFiles}
        opportunityId={formik.values?.opportunityId}
        documents={OpportunityDocumentsData}
      />
      {status === "loading" ? <Loader /> : null}
    </>
  );
}

export default Documents;
