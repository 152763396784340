import axiosInstance from "../../utils/AxiosInstance";

export const ChangeOpportunityStatusBulk = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(
      "/Opportunity/BulkUpdateStatus",
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const AddBulkGeneralContractor = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(
      "/Opportunity/BulkGeneralContractor",
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    console.log(error);
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
