import React, { useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { SnackBarContext } from "../../../context/SnackBarContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AppDialog from "../../common/AppDialog/AppDialog";
import AddIcon from "@mui/icons-material/Add";
import { fetchOpportunityDocumentsData } from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import { CreateNewDocumentsAPi } from "../../../Apis/OpportunitySettings/OpportunitySettingsApis";

const validationSchema = yup.object({
  documents: yup
    .array()
    .of(
      yup.object().shape({
        file: yup.object().required("Field Required!"),
        type: yup.string().required("Field Required!"),
      })
    )
    .min(1, "Please add a document!"),
});

function AddDocumentDialog({ open, onClose, selectedRow, refName, refId }) {
  const dispatch = useDispatch();
  console.log(selectedRow);
  const { setsnackBarData } = useContext(SnackBarContext);
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title="Add Documents"
      minHeight="auto"
      maxWidth="md"
    >
      <Box my={2}>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{
            documents: [
              {
                type: refName ? refName : "",
                file: "",
              },
            ],
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            let formData = new FormData();

            formData.append(`opportunityId`, selectedRow?.opportunityId);
            if (refId && refName) {
              formData.append(`reference`, refName);
              formData.append(`referenceId`, refId);
            }

            let types = [];
            data?.documents?.forEach((document) => {
              if (document.type && document.file) {
                types.push(document?.type);
              }
            });

            if (types?.length) {
              formData.append(`types`, JSON.stringify(types));
            }

            for (let i = 0; i < data?.documents?.length; i++) {
              if (data?.documents[i].type && data?.documents[i].file) {
                formData.append(`documents`, data?.documents[i].file?.file);
              }
            }

            const response = await CreateNewDocumentsAPi(formData);
            setSubmitting(false);

            response?.snackBarData?.message &&
              setsnackBarData(response?.snackBarData);
            if (response?.success) {
              if (!refId) {
                dispatch(
                  fetchOpportunityDocumentsData({
                    opportunityId: selectedRow?.opportunityId,
                    refName: null,
                    refId: null,
                  })
                );
              }
              resetForm();
              onClose(true);
            }
          }}
        >
          {({
            values,
            handleBlur,
            setFieldValue,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form noValidate autoComplete="off">
                <FieldArray name="documents">
                  {({ push, remove }) => (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {!refName && (
                          <Tooltip title="Add Document">
                            <IconButton
                              color="success"
                              size="small"
                              sx={{ padding: "6px" }}
                              onClick={() =>
                                push({
                                  type: "",
                                  file: "",
                                })
                              }
                            >
                              <AddIcon fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>

                      <TableContainer
                        component={Box}
                        sx={{ marginTop: "10px", maxHeight: "300px" }}
                      >
                        <Table
                          sx={{ minWidth: "100%" }}
                          aria-label="simple table"
                        >
                          <TableHead className="table-head">
                            <TableRow>
                              <TableCell>Sr. </TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Document</TableCell>
                              {!refName && <TableCell>Delete</TableCell>}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.documents?.map((document, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    verticalAlign: "baseline",
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    <TextField
                                      variant="outlined"
                                      size="small"
                                      disabled={refName ? true : false}
                                      fullWidth
                                      sx={{ minWidth: "200px" }}
                                      select
                                      name={`documents[${index}].type`}
                                      value={values.documents[index]?.type}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `documents[${index}].type`,
                                          e.target.value
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        errors?.documents && touched?.documents
                                          ? errors?.documents[index]?.type &&
                                            touched?.documents[index]?.type
                                          : false
                                      }
                                      helperText={
                                        errors?.documents && touched?.documents
                                          ? errors?.documents[index]?.type &&
                                            touched?.documents[index]?.type
                                            ? errors?.documents[index]?.type
                                            : ""
                                          : ""
                                      }
                                    >
                                      <MenuItem value="Addendum">
                                        Addendum
                                      </MenuItem>
                                      <MenuItem value="Bid Docs">
                                        Bid Docs
                                      </MenuItem>
                                      <MenuItem value="Billings">
                                        Billings
                                      </MenuItem>
                                      <MenuItem value="Tracking Sheet">
                                        Tracking Sheet
                                      </MenuItem>
                                      <MenuItem value="Change Order">
                                        Change Order
                                      </MenuItem>
                                      <MenuItem value="Change Order Document Template">
                                        Change Order Document Template
                                      </MenuItem>
                                      <MenuItem value="Contract Docs">
                                        Contract Docs
                                      </MenuItem>
                                      <MenuItem value="Correspondence">
                                        Correspondence
                                      </MenuItem>
                                      <MenuItem value="Drawings">
                                        Drawings
                                      </MenuItem>
                                      <MenuItem value="Invoices">
                                        Invoices
                                      </MenuItem>
                                      <MenuItem value="Post Mortem">
                                        Post Mortem
                                      </MenuItem>
                                      <MenuItem value="Proposal">
                                        Proposal
                                      </MenuItem>
                                      <MenuItem value="RFI">RFI</MenuItem>
                                      <MenuItem value="Schedules">
                                        Schedules
                                      </MenuItem>
                                      <MenuItem value="Specifications">
                                        Specifications
                                      </MenuItem>
                                      <MenuItem value="Warranty">
                                        Warranty
                                      </MenuItem>
                                      <MenuItem value="Work Order">
                                        Work Order
                                      </MenuItem>
                                      <MenuItem value="Third Party Quotation">
                                        Third Party Quotation
                                      </MenuItem>
                                      <MenuItem value="Product Quotation">
                                        Product Quotation
                                      </MenuItem>
                                    </TextField>
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      variant="outlined"
                                      type="file"
                                      size="small"
                                      fullWidth
                                      name={`documents[${index}].file`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `documents[${index}].file`,
                                          {
                                            src: URL.createObjectURL(
                                              e.target.files[0]
                                            ),
                                            file: e.target.files[0],
                                          }
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        errors?.documents && touched?.documents
                                          ? errors?.documents[index]?.file &&
                                            touched?.documents[index]?.file
                                          : false
                                      }
                                      helperText={
                                        errors?.documents && touched?.documents
                                          ? errors?.documents[index]?.file &&
                                            touched?.documents[index]?.file
                                            ? errors?.documents[index]?.file
                                            : ""
                                          : ""
                                      }
                                    />
                                  </TableCell>
                                  {!refName && (
                                    <TableCell>
                                      <Tooltip title="Remove Document">
                                        <IconButton
                                          color="error"
                                          size="small"
                                          sx={{ padding: "6px" }}
                                          disabled={values?.types?.length == 1}
                                          onClick={() => {
                                            remove(index);
                                          }}
                                        >
                                          <DeleteOutlineIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}
                </FieldArray>

                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
                >
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppDialog>
  );
}

export default AddDocumentDialog;
AddDocumentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
  refId: PropTypes.any,
  refName: PropTypes.any,
};
