import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ProductInfoDialog from "../Estimations/RequestQuotation/ProductInfoDialog";
import NoData from "../../Assets/Images/no_data.png";
function RequstQuotataionView({ quotationData }) {
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <TableContainer sx={{ height: "350px", overflowY: "scroll" }}>
      <Table aria-label="simple table">
        <TableHead className="table-head">
          <TableRow>
            <TableCell>Sr</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Consider</TableCell>
            <TableCell>Quotation</TableCell>
            <TableCell>View Detail</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotationData.length > 0 ? (
            quotationData?.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{row?.company?.name}</TableCell>
                <TableCell>{row?.considered ? "Yes" : "No"}</TableCell>
                <TableCell
                  sx={{
                    cursor: row?.file && "pointer",
                    color: row?.file && "#551a8b",
                    textDecoration: row?.file && "underline",
                  }}
                  onClick={() => {
                    row?.file && window.open(row?.file, "_blank");
                  }}
                >
                  {row?.file ? `Open` : "N/A"}
                </TableCell>

                <TableCell>
                  <Tooltip title="View Products">
                    <IconButton
                      sx={{ padding: "5px" }}
                      color="info"
                      onClick={() => {
                        setSelectedRow(row);
                        setShowInfoDialog(true);
                      }}
                    >
                      <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={5} align="center">
                <img
                  style={{ marginTop: "65px" }}
                  width={100}
                  height={100}
                  src={NoData}
                  alt="No data available"
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {showInfoDialog && (
        <ProductInfoDialog
          open={showInfoDialog}
          onClose={() => {
            setSelectedRow(null);
            setShowInfoDialog(false);
          }}
          selectedRow={selectedRow}
        />
      )}
    </TableContainer>
  );
}

export default RequstQuotataionView;
RequstQuotataionView.propTypes = {
  quotationData: PropTypes.any,
};
