import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import AppConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import CompaniesDetailDialog from "../../components/Companies/CompaniesDetailDialog/CompaniesDetailDialog";
import CompanyContactsDialog from "../../components/Companies/CompanyContactsDialog/CompanyContactsDialog";
import { SnackBarContext } from "../../context/SnackBarContext";
import {
  fetchCompanyContracts,
  fetchCompanyData,
} from "../../redux/slice/CompanySlice/CompanySlice";
import {
  DeleteCompanyApi,
  UpdateCompanyApi,
} from "../../Apis/Companies/CompaniesApis";

function Companies() {
  const [alldata, setAlldata] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(100);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCompanyDetails, setShowCompanyDetail] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);

  const { TotalCount, status } = useSelector((state) => state?.Companies);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!alldata[page]) {
      setLoading(true);
      dispatch(fetchCompanyData({ page, pageSize: rowsPerPage }))
        .unwrap()
        .then((data) => {
          setAlldata((prev) => ({
            ...prev,
            [page]: data?.data?.companiesdata,
          }));
        })
        .finally(() => setLoading(false));
    }
  }, [dispatch, page, rowsPerPage, alldata]);

  useEffect(() => {
    if (alldata[page]) {
      const rows = alldata[page]?.map((item, idx) => ({
        id: idx + 1,
        ...item,
      }));
      setFilteredData(rows);
    }
  }, [alldata, page]);

  const handleFilterChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilterText(value);
    const filtered = alldata[page]?.filter(
      (item) =>
        item.name.toLowerCase().includes(value) ||
        item.email.toLowerCase().includes(value) ||
        item.phone.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleLogo = async (e, id) => {
    setLoading(true);
    const formData = new FormData();
    e.target.files[0] && formData.append("image", e.target.files[0]);
    const response = await UpdateCompanyApi(id, formData);
    setLoading(false);
    setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(fetchCompanyData({ page, pageSize: rowsPerPage }));
    }
  };

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const deleteCompany = async () => {
    setLoading(true);
    setShowConfirmation(false);
    const response = await DeleteCompanyApi(selectedRow?.companyId);
    setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(fetchCompanyData({ page, pageSize: rowsPerPage }));
      setSelectedRow(null);
    }
    setLoading(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <TextField
          label="Search By Name, Email or Phone"
          variant="outlined"
          size="small"
          value={filterText}
          onChange={handleFilterChange}
          sx={{ mb: 2, width: "50%" }}
        />
        <Button
          color="primary"
          sx={{ float: "right" }}
          variant="contained"
          onClick={() => navigate("/companies/form")}
        >
          New
        </Button>
      </Box>
      <Box className="data-grid-box-company">
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
        >
          {filteredData?.map((company, idx) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={idx}>
              <Card
                sx={{
                  textAlign: "center",
                  boxShadow: "none",
                  borderRadius: "10px",
                  p: "20px 15px",
                  mb: "15px",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <Box>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Tooltip title="Actions" arrow placement="top">
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => {
                          handleClick(e);
                          setSelectedRow(company);
                        }}
                      >
                        <MoreVertIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Menu
                    elevation={0}
                    PaperProps={{ style: { borderRadius: "20px" } }}
                    sx={{ borderRadius: "25px" }}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ "aria-labelledby": "basic-button" }}
                  >
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          navigate("/companies/form", { state: selectedRow });
                        }}
                      >
                        <ListItemIcon>
                          <IconButton aria-label="edit" size="small">
                            <EditIcon fontSize="inherit" color="info" />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          setShowConfirmation(true);
                        }}
                      >
                        <ListItemIcon>
                          <IconButton aria-label="delete" size="small">
                            <DeleteIcon fontSize="inherit" color="error" />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "148px",
                      height: "148px",
                      position: "relative",
                    }}
                  >
                    {company?.image ? (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          src={company?.image}
                          alt="Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "100%",
                          }}
                        />
                      </Box>
                    ) : (
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        src={"./"}
                        onClick={handleUploadClick}
                      >
                        <Typography>Upload Logo</Typography>
                      </Avatar>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      name="product_images"
                      style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: "0px",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        handleLogo(e, company?.companyId);
                      }}
                      ref={inputRef}
                    />
                  </Box>
                </Box>
                {/* <img
                src={company?.image || BrandLogo}
                alt="Company Logo"
                width="148px"
                height="148px"
                style={{ borderRadius: "100%" }}
              /> */}{" "}
                <Tooltip title={company?.name}>
                  <Typography
                    as="h4"
                    sx={{
                      fontSize: 16,
                      fontWeight: 500,
                      mt: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {company?.name}
                  </Typography>
                </Tooltip>
                <Typography
                  as="h4"
                  sx={{
                    fontSize: 13,
                    color: "#5A5966",
                    mb: 2,
                  }}
                >
                  ${company?.monetaryValue?.toLocaleString() || 0}
                </Typography>
                <Box>
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      setSelectedRow(company);
                      setShowCompanyDetail(true);
                    }}
                    sx={{
                      textTransform: "capitalize",
                      // borderRadius: "8px",
                      // m: "0 5px",
                      // color: "#fff !important",
                    }}
                  >
                    Details
                  </Button>

                  <Button
                    onClick={() => {
                      setSelectedRow(company);
                      setShowContactDetails(true);

                      dispatch(fetchCompanyContracts(company?.companyId));
                    }}
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "8px",
                      m: "0 5px",
                      color: "#fff !important",
                      backgroundColor: "#5A5966 !important",
                    }}
                  >
                    Contacts
                  </Button>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textAlign: "center",
                    mt: "30px",
                  }}
                >
                  <Box>
                    <CallIcon
                      sx={{
                        color: "#5A5966",
                        mb: "3px",
                      }}
                    />
                    <Typography fontWeight="500" fontSize="13px">
                      {company?.phone}
                    </Typography>
                  </Box>

                  <Box>
                    <MailOutlineIcon
                      sx={{
                        color: "#5A5966",
                        mb: "3px",
                      }}
                    />
                    <Typography fontWeight="500" fontSize="13px">
                      {company?.email}
                    </Typography>
                  </Box>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textAlign: "center",
                    mt: "30px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "calc(50% - 10px)",
                    }}
                  >
                    <CallIcon
                      sx={{
                        color: "#5A5966",
                        mb: "3px",
                      }}
                    />
                    <Tooltip title={company?.phone}>
                      <Typography
                        fontWeight="500"
                        fontSize="13px"
                        sx={{
                          ml: "8px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {company?.phone}
                      </Typography>
                    </Tooltip>
                  </Box>

                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "calc(50% - 10px)",
                    }}
                  >
                    <MailOutlineIcon
                      sx={{
                        color: "#5A5966",
                        mb: "3px",
                      }}
                    />
                    <Tooltip title={company?.email}>
                      <Typography
                        fontWeight="500"
                        fontSize="13px"
                        sx={{
                          ml: "8px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {company?.email}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        <TablePagination
          component="div"
          count={TotalCount || 0}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </Box>
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteCompany();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Company"
          title={`Are you sure, you want to delete company ${selectedRow?.name}?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
      {status === "loading" || loading ? <Loader /> : null}
      {showCompanyDetails && (
        <CompaniesDetailDialog
          open={showCompanyDetails}
          onClose={() => {
            setSelectedRow(null);
            setShowCompanyDetail(false);
          }}
          state={selectedRow}
        />
      )}
      {showContactDetails && (
        <CompanyContactsDialog
          open={showContactDetails}
          onClose={() => {
            setSelectedRow(null);
            setShowContactDetails(false);
          }}
          state={selectedRow}
        />
      )}
    </>
  );
}
export default Companies;
