import axiosInstance from "../../../utils/AxiosInstance";

export const CreateThirdPartyApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/ThirdParty/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const UpdateConsideredApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put("/ThirdParty/UpdateConsider", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteThirdPartyApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/ThirdParty/Delete/${id}`);
    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
