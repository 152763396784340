import { Routes, Route } from "react-router-dom";
import BcrumbsContextProvider from "./context/BcrumbsContextProvider";
import { useAuth } from "./context/useAuth";
import { SnackBarContextProvider } from "./context/SnackBarContext";
import SnackBar from "./components/SnackBar/SnackBar";
import Layout from "./Layouts/Layout";
import NoAccess from "./pages/NotFound/NoAccess";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AdminRoutes from "./AllRoutes/AllRoutes";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import { fetchUserRolesData } from "./redux/slice/RolesSlice/RolesSlice";
import SkeletonLayout from "./Layouts/SkeletonLayout";
import theme from "./theme/theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

function App() {
  const { userRoles, setRoles } = useAuth();

  const dispatch = useDispatch();
  const { UserRolesData, status } = useSelector((state) => state?.Roles);

  useEffect(() => {
    dispatch(fetchUserRolesData());
  }, [dispatch]);

  useEffect(() => {
    if (UserRolesData) setRoles(UserRolesData?.Screens);
  }, [UserRolesData]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BcrumbsContextProvider>
          <SnackBarContextProvider>
            <Routes>
              {AdminRoutes().map((route, idx) => {
                if (route?.roleName) {
                  return userRoles?.find(
                    (screen) => screen?.key === route.roleName
                  ) ? (
                    <Route
                      key={idx}
                      exact
                      path={route.path}
                      element={route.component}
                    />
                  ) : (
                    <Route
                      key={idx}
                      exact
                      path="*"
                      element={
                        <RequireAuth>
                          {status === "loading" ? (
                            <SkeletonLayout />
                          ) : (
                            <Layout activePage={<NoAccess />} />
                          )}
                        </RequireAuth>
                      }
                    />
                  );
                } else {
                  return (
                    <Route
                      key={idx}
                      exact
                      path={route.path}
                      element={route.component}
                    />
                  );
                }
              })}
            </Routes>
            <SnackBar />
          </SnackBarContextProvider>
        </BcrumbsContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
