import {
  Box,
  IconButton,
  Tooltip,
  Button,
  gridClasses,
  Radio,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import {
  fetchAllInProgressOpportunity,
  fetchAllPendingOpportunity,
  fetchEstimationByOpportunityIdData,
  fetchOpportunityDocumentsData,
} from "../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import ArticleIcon from "@mui/icons-material/Article";
import DocumentsDialog from "../../components/DocumentsDialog/DocumentsDialog";

import GCsDialog from "../../components/CreateJob/GCsDialog/GCsDialog";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";

function Estimations() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [documentApiCalling, setDocumentApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [opportunityId, setopportunityId] = useState(null);
  const [isEstimationAdded, setIsEstimationAdded] = useState(false);
  const [estimationApiLoading, setEstimationApiLoading] = useState(false);
  const [cachedRows, setCachedRows] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 100,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);
  const {
    OpportunityInProgress,
    status,
    OpportunityDocumentsData,
    EstimationsByOpportunityIdData,
    TotalCount,
  } = useSelector((state) => state?.OpportunitySettings);

  useEffect(() => {
    // dispatch(fetchAllInProgressOpportunity());
    dispatch(
      fetchAllInProgressOpportunity({
        status: "In Progress",
        page: pageState.page,
        pageSize: pageState.pageSize,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (OpportunityInProgress) {
      setalldata(OpportunityInProgress);
    } else {
      setalldata([]);
    }
  }, [OpportunityInProgress]);

  const handleChangeStatus = () => {
    navigate("/estimations/form", {
      state: { selectedRowId },
    });
    handleBreadcrumbs("Form", "/estimations/form");
    // setopportunityId(id);
  };

  const handleBreadcrumbs = (name, url) => {
    setBreadcrumbsItems([
      {
        id: breadcrumbsItems.length,
        url: "/",
        name: "Dashboard",
      },
      {
        id: breadcrumbsItems.length,
        url: "/estimations",
        name: "Estimations",
      },
      {
        id: breadcrumbsItems.length,
        url: url,
        name: name,
      },
    ]);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          {selectedRowId ? (
            <Box sx={{ display: "flex", gap: "5px" }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  if (isEstimationAdded) {
                    dispatch(fetchEstimationByOpportunityIdData(selectedRowId));
                    setEstimationApiLoading(true);
                  } else {
                    handleChangeStatus();
                  }
                }}
              >
                {isEstimationAdded ? "View Estimation" : "Add Estimation"}
              </Button>
              <Button
                onClick={() => {
                  setSelectedRowId(null);
                }}
                variant="outlined"
                size="small"
                color="error"
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Box>
              <GridToolbarExport />
              <GridToolbarFilterButton />
            </Box>
          )}

          <Box sx={{ display: "flex", alignItems: "center", gap: "19px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Typography
                component="span"
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#B4F8C8",
                }}
              />
              <Typography
                component="span"
                sx={{
                  color: "rgb(107, 114, 128)",
                  textTransform: "uppercase",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                Estimation Added
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Typography
                component="span"
                sx={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#A0E7E5",
                }}
              />
              <Typography
                component="span"
                sx={{
                  color: "rgb(107, 114, 128)",
                  textTransform: "uppercase",
                  fontSize: "10px",
                  fontWeight: 500,
                }}
              >
                Change Request Added
              </Typography>
            </Box>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const handleCheckboxChange = (rowData) => {
    setSelectedRowId(rowData?.opportunityId);
    setIsEstimationAdded(rowData?.isEstimationAdded);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr",
      minWidth: 30,
      flex: "auto",
      renderCell: (params) => {
        return (pageState.page - 1) * pageState.pageSize + params.value;
      },
    },
    {
      field: "select",
      headerName: "Select",
      minWidth: 50,
      flex: "auto",
      renderCell: (params) => (
        <Radio
          checked={selectedRowId === params.row.opportunityId}
          onChange={() => handleCheckboxChange(params.row?.rowData)}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "source",
      headerName: "Source",
      minWidth: 110,
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/contacts/form", {
                state: params?.row?.rowData?.contact,
              });
            }}
          >
            {params?.row?.contact}
          </Box>
        );
      },
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/companies/form", {
                state: params?.row?.rowData?.company,
              });
            }}
          >
            {params?.row?.company}
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "clientDuteDate",
      headerName: "Client Due Date",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        const dateValue = params.value;
        return dateValue ? moment(dateValue, "MM/DD/YYYY").toISOString() : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        return dateValue
          ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
              "MM/DD/YYYY"
            )
          : "N/A";
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "MM/DD/YYYY").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY"
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 70,
      flex: "auto",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Documents">
              <IconButton
                sx={{ padding: "5px" }}
                color="secondary"
                onClick={() => {
                  setDocumentApiCalling(true);
                  setSelectedRow(params?.row?.rowData);
                  dispatch(
                    fetchOpportunityDocumentsData({
                      opportunityId: params?.row?.rowData?.opportunityId,
                      refName: null,
                      refId: null,
                    })
                  );
                }}
              >
                <ArticleIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          title: item?.title,
          opportunityId: item?.opportunityId,
          description: item?.description,
          source: item?.opportunitySource?.title,
          contact: item?.contact?.display_name,
          company: item?.company?.name,
          status: item.status,
          type: item?.type?.title,
          isEstimationAdded: item?.isEstimationAdded,
          clientDuteDate: moment(item?.clientDueDate).format("MM/DD/YYYY"),
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(item?.updatedAt).format("MM/DD/YYYY"),
          rowData: item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (documentApiCalling && OpportunityDocumentsData) {
      setDocumentApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setDocumentApiCalling(false);
    }
  }, [OpportunityDocumentsData]);

  useEffect(() => {
    if (estimationApiLoading) {
      if (EstimationsByOpportunityIdData) {
        navigate("/estimations/form", {
          state: {
            selectedRowId,
            estimationData: EstimationsByOpportunityIdData,
          },
        });
        handleBreadcrumbs("Form", "/estimations/form");
      }
      setEstimationApiLoading(false);
    }
  }, [EstimationsByOpportunityIdData]);

  useEffect(() => {
    const fetchData = () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      if (cachedRows[pageState.page]) {
        setPageState((old) => ({ ...old, isLoading: false }));
        return;
      } else {
        try {
          dispatch(
            fetchAllInProgressOpportunity({
              status: "In Progress",
              page: pageState.page,
              pageSize: pageState.pageSize,
            })
          );
        } catch (error) {
          console.error("Failed to fetch opportunities", error);
        } finally {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      }
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setCachedRows((prev) => ({
      ...prev,
      [pageState.page]: rows,
    }));
  }, [rows]);

  const rows1 = cachedRows[pageState.page] || [];

  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows1}
          pagination
          paginationMode="server"
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState.pageSize,
                page: pageState.page - 1,
              },
            },
          }}
          onPaginationModelChange={(model) => {
            console.log("model", model);
            setPageState((old) => ({ ...old, page: model.page + 1 }));
          }}
          pageSizeOptions={false}
          rowCount={TotalCount}
          loading={pageState.isLoading}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />

        {status === "loading" ? <Loader /> : null}
      </Box>
      <DocumentsDialog
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />
      <GCsDialog
        open={opportunityId ? true : false}
        onClose={(success) => {
          if (success) {
            dispatch(
              fetchAllPendingOpportunity({
                status: "Pending",
                page: pageState.page,
                pageSize: pageState.pageSize,
              })
            );
          }
          setopportunityId(null);
          setSelectedRowId(null);
        }}
        opportunityId={selectedRowId}
      />{" "}
    </>
  );
}

export default Estimations;
