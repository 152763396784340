import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  SourcesData: null,
  TagsData: null,
  TypesData: null,
  AllOpportunitiesData: [],
  OpportunitiesPopulateData: null,
  OpportunityDocumentsData: [],
  OpportunityQualified: [],
  TotalCount: 0,
  OpportunityPending: [],
  OpportunityInProgress: [],
  OpportunityOpenData: [],
  EstimationsByOpportunityIdData: null,
  ProposalReviewByIdData: null,
  OpportunityByIdData: null,
  DocumentPopulateData: null,
  OpportunitySalesSummaryData: null,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchSourcesData = createAsyncThunk(
  "/OpportunitySource/GetAll",
  async () => {
    const response = await axiosInstance.get(`/OpportunitySource/GetAll`);
    return response?.data;
  }
);

export const fetchTagsData = createAsyncThunk(
  "/OpportunityTag/GetAll",
  async () => {
    const response = await axiosInstance.get(`/OpportunityTag/GetAll`);
    return response?.data;
  }
);

export const fetchProjectTypesData = createAsyncThunk(
  "/Type/GetAll",
  async () => {
    const response = await axiosInstance.get(`/Type/GetAll`);
    return response?.data;
  }
);

export const fetchAllOpportunityByStatus = createAsyncThunk(
  "/Opportunity/GetByStatus",
  async ({ status, page, pageSize }) => {
    const response = await axiosInstance.get(
      `/Opportunity/GetByStatus?status=${status}&page=${page}&limit=${pageSize}`
    );
    return response?.data;
  }
);
export const fetchAllPendingOpportunity = createAsyncThunk(
  "/Opportunity/GetByStatus/Pending",
  async (status, page, pageSize) => {
    const response = await axiosInstance.get(
      `/Opportunity/GetByStatus?status=${status}&page=${page}&limit=${pageSize}`
    );
    return response?.data;
  }
);
export const fetchAllInProgressOpportunity = createAsyncThunk(
  "/Opportunity/GetByStatus/InpProgress",
  async ({ status, page, pageSize }) => {
    const response = await axiosInstance.get(
      `/Opportunity/GetByStatus?status=${status}&page=${page}&limit=${pageSize}`
    );
    return response?.data;
  }
);

export const fetchUnderReviewOpportunity = createAsyncThunk(
  "/Opportunity/GetByStatus/Under Review",
  async ({ status, page, pageSize }) => {
    const response = await axiosInstance.get(
      `/Opportunity/GetByStatus?status=${status}&page=${page}&limit=${pageSize}`
    );
    return response?.data;
  }
);
export const fetchAllOpportunityData = createAsyncThunk(
  "/Opportunity/GetAll",
  async ({ page, pageSize }) => {
    const response = await axiosInstance.get(
      `/Opportunity/GetByStatus?page=${page}&limit=${pageSize}`
    );
    return response?.data;
  }
);
export const fetchAllDocumentPopulate = createAsyncThunk(
  "/Opportunity/DocumentPopulate",
  async () => {
    const response = await axiosInstance.get(`/Opportunity/DocumentPopulate`);
    return response?.data;
  }
);
export const fetchOpportunityPopulateData = createAsyncThunk(
  "/Opportunity/Populate",
  async () => {
    const response = await axiosInstance.get(`/Opportunity/Populate`);
    return response?.data;
  }
);

export const fetchOpportunityDocumentsData = createAsyncThunk(
  "/Opportunity/Document/getByOppurtunityId",
  async (data) => {
    const response = await axiosInstance.get(
      data?.refName == null
        ? `/Opportunity/Document/getByOppurtunityId/${data?.opportunityId}`
        : `/Opportunity/Document/getByOppurtunityId/${data?.opportunityId}?referenceName=${data?.refName}&referenceId=${data?.refId}`
    );
    return response?.data;
  }
);

export const fetchEstimationByOpportunityIdData = createAsyncThunk(
  "/Estimation/GetByOppotunityId",
  async (id) => {
    const response = await axiosInstance.get(
      `/Estimation/GetByOppotunityId/${id}`
    );
    return response?.data;
  }
);

export const fetchProposalReviewId = createAsyncThunk(
  "/Opportunity/Populate/ProposalReview",
  async (id) => {
    const response = await axiosInstance.get(
      `/Opportunity/Populate/ProposalReview/${id}`
    );
    return response?.data;
  }
);

export const fetchOpportunityByIdData = createAsyncThunk(
  "/Opportunity/GetOpportunityDataById",
  async (id) => {
    const response = await axiosInstance.get(
      `/Opportunity/GetOpportunityDataById/${id}`
    );
    return response?.data;
  }
);

export const fetchOpportunitySalesSummaryData = createAsyncThunk(
  "/Opportunity/Summary",
  async (payload) => {
    const response = await axiosInstance.get(
      `/Opportunity/Summary?startDate=${payload?.startDate || null}&endDate=${
        payload?.endDate || null
      }`
    );

    return response?.data;
  }
);

const OpportunitySettingsSlice = createSlice({
  name: "OpportunitySettingsSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchSourcesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSourcesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.SourcesData = action.payload.data;
      })
      .addCase(fetchSourcesData.rejected, (state, action) => {
        state.SourcesData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchProjectTypesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjectTypesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.TypesData = action.payload.data;
      })
      .addCase(fetchProjectTypesData.rejected, (state, action) => {
        state.TypesData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchTagsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTagsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.TagsData = action.payload.data;
      })
      .addCase(fetchTagsData.rejected, (state, action) => {
        state.TagsData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllOpportunityData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOpportunityData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.AllOpportunitiesData = action.payload.data.opportunities;
      })
      .addCase(fetchAllOpportunityData.rejected, (state, action) => {
        state.AllOpportunitiesData = [];
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchOpportunityPopulateData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOpportunityPopulateData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunitiesPopulateData = action.payload.data;
      })
      .addCase(fetchOpportunityPopulateData.rejected, (state, action) => {
        state.OpportunitiesPopulateData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchOpportunityDocumentsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOpportunityDocumentsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunityDocumentsData = action.payload.data;
      })
      .addCase(fetchOpportunityDocumentsData.rejected, (state, action) => {
        state.OpportunityQualified = [];
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllOpportunityByStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllOpportunityByStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunityQualified = action.payload.data.opportunities;
        state.TotalCount = action.payload.data.TotalCount;
      })
      .addCase(fetchAllOpportunityByStatus.rejected, (state, action) => {
        state.OpportunityQualified = [];
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllPendingOpportunity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPendingOpportunity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunityPending = action.payload.data;
      })
      .addCase(fetchAllPendingOpportunity.rejected, (state, action) => {
        state.OpportunityPending = [];
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllInProgressOpportunity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllInProgressOpportunity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunityInProgress = action.payload.data.opportunities;
        state.TotalCount = action.payload.data.TotalCount;
      })
      .addCase(fetchAllInProgressOpportunity.rejected, (state, action) => {
        state.OpportunityInProgress = [];
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchEstimationByOpportunityIdData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchEstimationByOpportunityIdData.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.message = action.payload.message;
          state.EstimationsByOpportunityIdData = action.payload.data;
        }
      )
      .addCase(fetchEstimationByOpportunityIdData.rejected, (state, action) => {
        state.EstimationsByOpportunityIdData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchProposalReviewId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProposalReviewId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.ProposalReviewByIdData = action.payload.data;
      })
      .addCase(fetchProposalReviewId.rejected, (state, action) => {
        state.ProposalReviewByIdData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchUnderReviewOpportunity.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUnderReviewOpportunity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunityOpenData = action.payload.data.opportunities;
        state.TotalCount = action.payload.data.TotalCount;
      })
      .addCase(fetchUnderReviewOpportunity.rejected, (state, action) => {
        state.OpportunityOpenData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchOpportunityByIdData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOpportunityByIdData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunityByIdData = action.payload.data;
      })
      .addCase(fetchOpportunityByIdData.rejected, (state, action) => {
        state.OpportunityByIdData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchAllDocumentPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllDocumentPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.DocumentPopulateData = action.payload.data;
      })
      .addCase(fetchAllDocumentPopulate.rejected, (state, action) => {
        state.DocumentPopulateData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchOpportunitySalesSummaryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOpportunitySalesSummaryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.OpportunitySalesSummaryData = action.payload.data;
      })
      .addCase(fetchOpportunitySalesSummaryData.rejected, (state, action) => {
        state.OpportunitySalesSummaryData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default OpportunitySettingsSlice.reducer;
