import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const BreadcrumbsContext = createContext();

function BcrumbsContextProvider({ children }) {
  //   const [breadcrumbsItems, setBreadcrumbsItems] = useState([]);
  const [breadcrumbsItems, setBreadcrumbsItems] = useState(() => {
    const storedBreadcrumbs = localStorage.getItem("breadcrumbs");
    return storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
  });
  useEffect(() => {
    if (breadcrumbsItems)
      localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbsItems));
  }, [breadcrumbsItems]);
  //   useEffect(() => {
  //     setBreadcrumbsItems([
  //       {
  //         id: 0,
  //         url: "/",
  //         name: "Dashboard",
  //       },
  //       {
  //         id: 1,
  //         url: "/",
  //         name: "Home",
  //       },
  //     ]);
  //   }, []);

  return (
    <BreadcrumbsContext.Provider
      value={{ breadcrumbsItems, setBreadcrumbsItems }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
}

export default BcrumbsContextProvider;
BcrumbsContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
