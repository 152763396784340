import React, { useState } from "react";
import Navbar from "../components/Header/Navbar/Navbar";
import Box from "@mui/material/Box";
import SideDrawer from "../components/Header/SideDrawer/SideDrawer";
import PropTypes from "prop-types";

const Layout = ({ activePage }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  return (
    <Box
      sx={{
        display: { xs: "block", lg: "flex" },
      }}
    >
      <Box
        sx={{
          width: { xs: "auto", lg: "308px" },
          height: { xs: "auto", lg: "auto" },
        }}
      >
        <SideDrawer
          drawerOpen={drawerOpen}
          toggleDrawer={toggleDrawer}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "auto", lg: "calc(100% - 308px)" },
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Navbar toggleDrawer={toggleDrawer} />
        {activePage}
      </Box>
    </Box>
  );
};

export default Layout;
Layout.propTypes = {
  activePage: PropTypes.any.isRequired,
};
