import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import { SnackBarContext } from "../../context/SnackBarContext";
import AppDialog from "../common/AppDialog/AppDialog";
import Loader from "../Loader/Loader";
import ProposalPrintNew from "./ProposalPrintNew";
import { pdf } from "@react-pdf/renderer";
import GCsDialog from "../CreateJob/GCsDialog/GCsDialog";
import {
  fetchAllPendingOpportunity,
  fetchProposalReviewId,
} from "../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import { useDispatch } from "react-redux";
import { SendProposalApi } from "../../Apis/ProposalReview/ProposalReviewApi";

function SendProposalDialog({
  open,
  onClose,
  contacts,
  exclusionsArr,
  interiorArr,
  exteriorArr,
  alternatesArr,
  estimationData,
  opportunityData,
  extSum,
  altSum,
  intSum,
  opportunityId,
  status,
}) {
  const dispatch = useDispatch();
  const [openGcDialog, setOpenGcDialog] = useState(false);

  const [alldata, setAlldata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [filterText, setFilterText] = useState("");
  const [category, setCategory] = useState("");

  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  useEffect(() => {
    if (contacts) {
      setAlldata(contacts);
      setFilteredData(contacts);
    } else {
      setAlldata([]);
      setFilteredData([]);
    }
  }, [contacts]);

  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // const handleFilterChange = (event) => {
  //   const value = event.target.value.toLowerCase();
  //   setFilterText(value);

  //   const filtered = alldata.filter(
  //     (item) =>
  //       item.display_name.toLowerCase().includes(value) ||
  //       item.email.toLowerCase().includes(value) ||
  //       item.phone.toLowerCase().includes(value)
  //   );
  //   setFilteredData(filtered);
  // };

  const generatePDFBlob = async (contact) => {
    try {
      const { blob, file } = await generatePDF(contact);
      return { contactId: contact.contactId, blob: blob, file: file };
    } catch (error) {
      console.error("Error generating PDF:", error);
      throw error;
    }
  };
  const generatePDF = async (contact) => {
    // Replace setTimeout with your actual PDF generation logic
    const pdfData = (
      <ProposalPrintNew
        exclusionsArr={exclusionsArr}
        alternatesArr={alternatesArr}
        exteriorArr={exteriorArr}
        interiorArr={interiorArr}
        estimationData={estimationData}
        opportunityData={opportunityData}
        intSum={intSum || 0}
        extSum={extSum || 0}
        altSum={altSum || 0}
        contact={contact}
      />
    );

    // Generate PDF blob synchronously
    const blob = await pdf(pdfData).toBlob();

    // Create a File object from the blob
    const file = new File([blob], `${contact.display_name}_proposal.pdf`, {
      type: "application/pdf",
    });

    return { blob: blob, file: file };
  };
  const generatePDFs = async () => {
    setLoading(true);

    try {
      const pdfPromises = selectedRows.map(async (contactId) => {
        const contact = alldata.find((item) => item.contactId === contactId);
        const pdf = await generatePDFBlob(contact);
        return pdf;
      });

      const pdfs = await Promise.all(pdfPromises);

      const formData = new FormData();
      formData.append("opportunityId", opportunityId);
      formData.append("status", "Pending");
      formData.append("followupCategory", category);

      for (let i = 0; i < pdfs.length; i++) {
        formData.append(`contactIds[${i}]`, pdfs[i].contactId);
        formData.append(`fileAttachement[${i}]`, pdfs[i].file);
      }

      setLoading(true);

      const response = await SendProposalApi(formData);

      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);

      if (response?.success) {
        onClose(true);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error generating PDFs:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AppDialog
        open={open}
        onClose={() => {
          onClose(false);
        }}
        title="Send Proposal"
        minHeight="auto"
        maxWidth="md"
      >
        <Box my={2}>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Box width={"50%"}>
              {/* <TextField
                label="Search"
                variant="outlined"
                size="small"
                fullWidth
                value={filterText}
                onChange={handleFilterChange}
                sx={{ mb: 1 }}
              /> */}
              <TextField
                label="Next Followup"
                // variant="outlined"
                size="small"
                fullWidth
                select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                sx={{ mb: 1 }}
              >
                <MenuItem value={"Weekly"}>Weekly</MenuItem>
                <MenuItem value={"Monthly"}>Monthly</MenuItem>
              </TextField>
            </Box>
            <Box width={"50%"} sx={{ textAlign: "end" }}>
              {category && selectedRows.length > 0 ? (
                <>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={generatePDFs}
                    sx={{ mx: "5px" }}
                  >
                    Send
                  </Button>

                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => setSelectedRows([])}
                  >
                    Cancel
                  </Button>
                </>
              ) : null}
            </Box>
          </Box>

          <h3>General Contractors</h3>
          <TableContainer
            component={Box}
            sx={{ marginTop: "5px", maxHeight: "300px" }}
          >
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Sr.</TableCell>
                  <TableCell>Select</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>
                    {" "}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setOpenGcDialog(true)}
                      sx={{ textTransform: "capitalize" }}
                    >
                      Add GCs
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(item.contactId)}
                        onChange={() => handleSelectRow(item.contactId)}
                      />
                    </TableCell>
                    <TableCell>{item.display_name}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.phone}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {loading && <Loader />}
        {/* {pdfFiles.length > 0 && (
          <Box mt={2}>
            <h3>Generated PDFs:</h3>
            {pdfFiles.map(({ contactId, blob }) => (
              <Box key={contactId} mb={2}>
                <a
                  href={URL.createObjectURL(blob)}
                  download={`${
                    alldata.find((item) => item.contactId === contactId)
                      .display_name
                  }_proposal.pdf`}
                >
                  Download PDF for{" "}
                  {
                    alldata.find((item) => item.contactId === contactId)
                      .display_name
                  }
                </a>
              </Box>
            ))}
          </Box>
        )} */}
      </AppDialog>
      {status === "loading" ? <Loader /> : null}
      {openGcDialog && (
        <GCsDialog
          open={openGcDialog}
          onClose={(success) => {
            if (success) {
              dispatch(fetchAllPendingOpportunity("Pending"));
              dispatch(fetchProposalReviewId(opportunityId));
            }
            setOpenGcDialog(false);
          }}
          opportunityId={opportunityId}
          screen={"proposal"}
        />
      )}
    </>
  );
}

export default SendProposalDialog;

SendProposalDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  opportunityId: PropTypes.any,
  contacts: PropTypes.any,
  exclusionsArr: PropTypes.any,
  interiorArr: PropTypes.any,
  exteriorArr: PropTypes.any,
  alternatesArr: PropTypes.any,
  estimationData: PropTypes.any,
  intSum: PropTypes.any,
  altSum: PropTypes.any,
  extSum: PropTypes.any,
  contact: PropTypes.any,
  status: PropTypes.any,
  opportunityData: PropTypes.any,
};
