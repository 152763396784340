import React, { useEffect } from "react";
import PropTypes from "prop-types";
import OTPInput from "react-otp-input";
import { Box, FormHelperText, Typography } from "@mui/material";

function VerifyOTP({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  otpSending,
  resendOTP,
}) {
  useEffect(() => {
    const countdown = setInterval(() => {
      if (values?.duration > 0) {
        setFieldValue("duration", values?.duration - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [values?.duration]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <>
      <Box>
        <OTPInput
          value={values.otp}
          onChange={(val) => setFieldValue("otp", val)}
          placeholder={true}
          numInputs={4}
          renderInput={(props) => (
            <input
              placeholder="--"
              name="otp"
              onBlur={handleBlur}
              {...props}
            />
          )}
          inputStyle={{
            // Custom styles for the input fields
            width: "80px",
            height: "3rem",
            margin: "0 0.5rem",
            fontSize: "2rem",
            borderRadius: "4px",
            border: "1px solid rgba(0,0,0,0.3)",
          }}
        />
        {touched?.otp && errors?.otp && (
          <FormHelperText sx={{ color: "#d32f2f" }}>
            {errors?.otp}
          </FormHelperText>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Typography>Expires in {formatTime(values?.duration)}</Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography>Didn’t receive OTP?</Typography>
            <Typography
              sx={{
                cursor: otpSending ? "not-allowed" : "pointer",
                color: otpSending ? "#fac385" : "#f79c35",
                fontWeight: 500,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                if (!otpSending) resendOTP();
              }}
            >
              Resend
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default VerifyOTP;
VerifyOTP.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  otpSending: PropTypes.bool,
  resendOTP: PropTypes.func,
};
