import React from "react";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import StatCards from "../../components/Dashboard/StatCards/StatCards";
import StatCharts from "../../components/Dashboard/StatCharts/StatCharts";

function Dashboard() {
  const { status } = useSelector((state) => state?.Dashboard);

  return (
    <main>
      <StatCards />
      <StatCharts />

      {status === "loading" ? <Loader /> : null}
    </main>
  );
}

export default Dashboard;
