import { Box, Button, IconButton, Tooltip, gridClasses } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { fetchContactsData } from "../../redux/slice/ContactsSlice/ContactSlice";
import AppConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SnackBarContext } from "../../context/SnackBarContext";
import { DeleteContactApi } from "../../Apis/Contacts/CreateContactsApi";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContactsDetailDialog from "../../components/Contacts/ContactsDetailDialog/ContactsDetailDialog";

function Contacts() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [cachedRows, setCachedRows] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 100,
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);

  const { ContactsData, status, TotalCount } = useSelector(
    (state) => state?.Contacts
  );

  useEffect(() => {
    dispatch(
      fetchContactsData({
        page: pageState.page,
        pageSize: pageState.pageSize,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (ContactsData) {
      setalldata(ContactsData);
    } else {
      setalldata([]);
    }
  }, [ContactsData]);

  const deleteContact = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteContactApi(selectedRow?.contactId);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(
        fetchContactsData({
          page: pageState.page,
          pageSize: pageState.pageSize,
        })
      );
      setSelectedRow(null);
    }
    setLoading(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Box>

          <Box>
            <Button
              color="primary"
              sx={{
                float: "right",
              }}
              variant="contained"
              size="small"
              onClick={() => navigate("/contacts/form")}
            >
              New
            </Button>
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "Sr", minWidth: 50, flex: "auto",renderCell: (params) => {
      return (pageState.page-1) * pageState.pageSize + (params.value );
    }, },
    {
      field: "company",
      headerName: "Company",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "display_name",
      headerName: "Name",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 160,
      flex: 1,
    },
    // {
    //   field: "state",
    //   headerName: "State",
    //   minWidth: 120,
    //   flex: 1,
    // },
    // {
    //   field: "city",
    //   headerName: "City",
    //   minWidth: 120,
    //   flex: 1,
    // },

    // {
    //   field: "active",
    //   headerName: "Status",
    //   minWidth: 120,
    //   flex: 1,
    //   renderCell: (params) =>
    //     params.row.active == true ? "Active" : "Inactive",
    // },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 70,
      flex: "auto",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit">
              <IconButton
                sx={{ padding: "5px" }}
                color="info"
                onClick={() => {
                  navigate("/contacts/form", {
                    state: params?.row,
                  });
                }}
              >
                <EditIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                sx={{ padding: "5px" }}
                color="error"
                onClick={() => {
                  setSelectedRow(params?.row);
                  setShowConfirmation(true);
                }}
              >
                <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Details">
              <IconButton
                sx={{ padding: "5px" }}
                color="success"
                onClick={() => {
                  setShowContactDetails(true);
                  setSelectedRow(params.row);
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          company: item?.companies?.name,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    const fetchData = () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      if (cachedRows[pageState.page]) {
        setPageState((old) => ({ ...old, isLoading: false }));
        return;
      } else {
        try {
          dispatch(
            fetchContactsData({
              page: pageState.page,
              pageSize: pageState.pageSize,
            })
          );
        } catch (error) {
          console.error("Failed to fetch opportunities", error);
        } finally {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      }
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setCachedRows((prev) => ({
      ...prev,
      [pageState.page]: rows,
    }));
  }, [rows]);

  const rows1 = cachedRows[pageState.page] || [];
  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows1}
          pagination
          paginationMode="server"
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState.pageSize,
                page: pageState.page - 1,
              },
            },
          }}
          onPaginationModelChange={(model) => {
            setPageState((old) => ({ ...old, page: model.page + 1 }));
          }}
          pageSizeOptions={false}
          rowCount={TotalCount}
          loading={pageState.isLoading}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
        {showConfirmation && (
          <AppConfirmationDialog
            open={showConfirmation}
            onDeny={() => {
              setSelectedRow(null);
              setShowConfirmation(false);
            }}
            onConfirm={() => {
              deleteContact();
            }}
            iconContainerColor="#fbeaea"
            icon={
              <DeleteOutlineIcon
                fontSize="small"
                sx={{ color: (theme) => theme?.palette?.error?.main }}
              />
            }
            dialogTitle="Delete Contact"
            title={`Are you sure, you want to delete contact ${selectedRow?.display_name}?`}
            successText="Yes"
            successBtnColor="error"
            cancelText="No"
          />
        )}
        {showContactDetails && (
          <ContactsDetailDialog
            open={showContactDetails}
            onClose={() => setShowContactDetails(false)}
            state={selectedRow}
          />
        )}
        {status === "loading" || loading ? <Loader /> : null}
      </Box>
    </>
  );
}
export default Contacts;
