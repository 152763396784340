import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  Radio,
  Tooltip,
  gridClasses,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import {
  fetchAllPendingOpportunity,
  fetchOpportunityDocumentsData,
} from "../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import ArticleIcon from "@mui/icons-material/Article";
import DocumentsDialog from "../../components/DocumentsDialog/DocumentsDialog";
import GCsDialog from "../../components/CreateJob/GCsDialog/GCsDialog";
import { useNavigate } from "react-router-dom";

function Create_Job() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [documentApiCalling, setDocumentApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [opportunityId, setOpportunityId] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { OpportunityPending, status, OpportunityDocumentsData } = useSelector(
    (state) => state?.OpportunitySettings
  );

  useEffect(() => {
    dispatch(fetchAllPendingOpportunity("Pending"));
  }, [dispatch]);

  useEffect(() => {
    if (OpportunityPending) {
      setalldata(OpportunityPending);
    } else {
      setalldata([]);
    }
  }, [OpportunityPending]);

  const handleChangeStatus = async (id) => {
    setOpportunityId(id);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          {selectedRowId ? (
            <Box sx={{ display: "flex", gap: "5px" }}>
              <Button
                onClick={() => handleChangeStatus(selectedRowId)}
                variant="contained"
                size="small"
                color="primary"
              >
                Add Bidder List
              </Button>
              <Button
                onClick={() => setSelectedRowId(null)}
                variant="outlined"
                size="small"
                color="error"
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Box>
              <GridToolbarExport />
              <GridToolbarFilterButton />
            </Box>
          )}
        </Box>
      </GridToolbarContainer>
    );
  }

  const handleCheckboxChange = (id) => {
    setSelectedRowId(id);
  };

  const columns = [
    { field: "id", headerName: "Sr", minWidth: 30, flex: "auto" },
    {
      field: "select",
      headerName: "Select",
      minWidth: 50,
      flex: "auto",
      renderCell: (params) => (
        <Radio
          checked={selectedRowId === params.row.opportunityId}
          onChange={() => handleCheckboxChange(params.row.opportunityId)}
        />
      ),
    },
    { field: "title", headerName: "Title", minWidth: 150, flex: 1 },
    { field: "source", headerName: "Source", minWidth: 110, flex: 1 },
    {
      field: "contact",
      headerName: "Contact",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/contacts/form", {
                state: params?.row?.rowData?.contact,
              });
            }}
          >
            {params?.row?.contact}
          </Box>
        );
      },
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/companies/form", {
                state: params?.row?.rowData?.company,
              });
            }}
          >
            {params?.row?.company}
          </Box>
        );
      },
    },
    { field: "type", headerName: "Type", minWidth: 120, flex: 1 },
    {
      field: "clientDueDate",
      headerName: "Client Due Date",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        const dateValue = params.value;
        return dateValue ? moment(dateValue, "MM/DD/YYYY").toISOString() : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        return dateValue
          ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
              "MM/DD/YYYY"
            )
          : "N/A";
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        return moment(params.value, "MM/DD/YYYY").toISOString();
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY"
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 70,
      flex: "auto",
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Documents">
            <IconButton
              sx={{ padding: "5px" }}
              color="secondary"
              onClick={() => {
                setDocumentApiCalling(true);
                setSelectedRow(params?.row?.rowData);
                dispatch(
                  fetchOpportunityDocumentsData({
                    opportunityId: params?.row?.rowData?.opportunityId,
                    refName: null,
                    refId: null,
                  })
                );
              }}
            >
              <ArticleIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        arr.push({
          id: idx + 1,
          title: item?.title,
          opportunityId: item?.opportunityId,
          description: item?.description,
          source: item?.opportunitySource?.title,
          contact: item?.contact?.display_name,
          company: item?.company?.name,
          status: item.status,
          type: item?.type?.title,
          clientDueDate: moment(item?.clientDueDate).format("MM/DD/YYYY"),
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(item?.updatedAt).format("MM/DD/YYYY"),
          rowData: item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (documentApiCalling && OpportunityDocumentsData) {
      setDocumentApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setDocumentApiCalling(false);
    }
  }, [OpportunityDocumentsData]);

  return (
    <Box className="data-grid-box">
      <DataGrid
        className="data_grid_parent_div"
        rows={rows}
        columns={columns}
        pageSize={100}
        pageSizeOptions={false}
        pagination
        components={{
          toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        localeText={{
          toolbarDensity: "Size",
          toolbarDensityLabel: "Size",
          toolbarDensityCompact: "Small",
          toolbarDensityStandard: "Medium",
          toolbarDensityComfortable: "Large",
        }}
      />

      {status === "loading" && <Loader />}

      <DocumentsDialog
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />
      <GCsDialog
        open={!!opportunityId}
        onClose={(success) => {
          if (success) {
            dispatch(fetchAllPendingOpportunity("Pending"));
          }
          setOpportunityId(null);
          setSelectedRowId(null);
        }}
        opportunityId={selectedRowId}
      />
    </Box>
  );
}

export default Create_Job;
