import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useLocation } from "react-router-dom";
import DescriptionDialog from "../../../components/DescriptionDialog/DescriptionDialog";
import CustomTabPanel from "../../../components/common/CustomTabPanel/CustomTabPanel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DeleteNotesApi } from "../../../Apis/Notes/NotesApi";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarContext } from "../../../context/SnackBarContext";
import AppConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import AddNoteDialog from "../../../components/AddNoteDialog/AddNoteDialog";
import { fetchNotes } from "../../../redux/slice/ContactsSlice/ContactSlice";
import Loader from "../../../components/Loader/Loader";

function OpportunityDetails() {
  const classes = useStyles();

  const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
  const [description, setDescription] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [showAddNotesDialog, setShowAddNotesDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { Notes, status } = useSelector((state) => state?.Contacts);

  const { state } = useLocation();
  useEffect(() => {
    dispatch(fetchNotes({ opportunityId: state?.opportunityId }));
  }, [dispatch, state]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const deleteNote = async () => {
    setShowConfirmation(false);

    const response = await DeleteNotesApi(selectedRow?.notesId);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(
        fetchNotes({
          opportunityId: state?.opportunityId,
        })
      );
      setSelectedRow(null);
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          padding: "5px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} xl={7}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                padding: "1.5rem",
              }}
            >
              <Box>
                <Typography className={classes?.fieldsHeading} color="primary">
                  General
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>Contact</Typography>
                    <Typography>
                      {state?.contact?.display_name || "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Opportunity Title
                    </Typography>
                    <Typography>{state?.title || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>Source</Typography>
                    <Typography>
                      {state?.opportunitySource?.title || "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Project Type
                    </Typography>
                    <Typography>{state?.type?.title || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>Tags</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {state?.tags?.length
                        ? state?.tags?.map((tag, idx) => (
                            <Chip
                              key={idx}
                              sx={{
                                backgroundColor: tag?.colorCode,
                                color: "#fff",
                              }}
                              label={tag?.title}
                            />
                          ))
                        : "N/A"}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Assigned To
                    </Typography>
                    <Typography>
                      {state?.assignedToUser?.name || "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>Status</Typography>
                    <Typography>{state?.status || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Created At
                    </Typography>
                    <Typography>
                      {state?.createdAt
                        ? moment(state?.createdAt).format("MM/DD/YYYY")
                        : "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Updated At
                    </Typography>
                    <Typography>
                      {state?.updatedAt
                        ? moment(state?.updatedAt).format("MM/DD/YYYY")
                        : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ marginTop: "20px" }}>
                <Typography className={classes?.fieldsHeading} color="primary">
                  Client
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>Company</Typography>
                    <Typography>{state?.company?.name || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Project Size
                    </Typography>
                    <Typography>{state?.projectsize || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Description
                    </Typography>

                    {state?.description ? (
                      state?.description?.length > 250 ? (
                        <Typography>
                          {state?.description}...
                          <Typography
                            component="span"
                            sx={{
                              cursor: "pointer",
                              color: "#551a8b",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                            onClick={() => {
                              setDescription(state?.description);
                              setShowDescriptionDialog(true);
                            }}
                          >
                            see more
                          </Typography>
                        </Typography>
                      ) : (
                        <Typography> {state?.description}</Typography>
                      )
                    ) : (
                      <Typography>{"N/A"}</Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Opportunity Value
                    </Typography>
                    <Typography>
                      {state?.opportunityValue?.toLocaleString() || "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Confidence
                    </Typography>
                    <Typography>
                      {state?.confidence ? `${state?.confidence}%` : "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Internal Due Date
                    </Typography>
                    <Typography>
                      {state?.internalDueDate
                        ? moment(state?.internalDueDate).format("MM/DD/YYYY")
                        : "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Client Due Date
                    </Typography>
                    <Typography>
                      {state?.clientDueDate
                        ? moment(state?.clientDueDate).format("MM/DD/YYYY")
                        : "N/A"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Bid Link
                    </Typography>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#551a8b",
                        textDecoration: "underline",
                        wordBreak: "break-all",
                      }}
                      onClick={() => {
                        window.open(state?.bidLink, "_blank");
                      }}
                    >
                      {state?.bidLink || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ marginTop: "20px" }}>
                <Typography className={classes?.fieldsHeading} color="primary">
                  Address
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold" }}>Address</Typography>
                    <Typography>{state?.address || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography sx={{ fontWeight: "bold" }}>State</Typography>
                    <Typography>{state?.state || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography sx={{ fontWeight: "bold" }}>City</Typography>
                    <Typography>{state?.city || "N/A"}</Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Zip Code
                    </Typography>
                    <Typography>{state?.zipcode || "N/A"}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} xl={5}>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                padding: "10px",
              }}
            >
              <Box sx={{ marginTop: "20px" }}>
                <Typography className={classes?.fieldsHeading} color="primary">
                  Notes
                </Typography>
                <Divider sx={{ mb: 2 }} />

                <TableContainer
                  component={Box}
                  sx={{
                    marginTop: "10px",
                    minHeight: "250px",
                    maxHeight: "380px",
                  }}
                >
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Sr. </TableCell>
                        <TableCell>Note</TableCell>
                        <TableCell>Created By</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>
                          {" "}
                          <Button
                            variant="contained"
                            sx={{ float: "right" }}
                            size="small"
                            onClick={() => {
                              setShowAddNotesDialog(true);
                            }}
                          >
                            Add New
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Notes?.data?.map((note, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell
                            dangerouslySetInnerHTML={{ __html: note?.note }}
                          ></TableCell>
                          <TableCell>{note?.createdByUser?.name}</TableCell>
                          <TableCell>
                            {moment(note?.createdAt).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                float: "right",
                                mr: 2,
                              }}
                            >
                              <Tooltip title="Edit">
                                <IconButton
                                  hidden
                                  sx={{ padding: "5px", display: "none" }}
                                  color="info"
                                  onClick={() => {
                                    setSelectedRow(note);
                                    setShowAddNotesDialog(true);
                                  }}
                                >
                                  <EditIcon sx={{ fontSize: "1rem" }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  sx={{ padding: "5px", display: "none" }}
                                  color="error"
                                  onClick={() => {
                                    setSelectedRow(note);
                                    setShowConfirmation(true);
                                  }}
                                >
                                  <DeleteOutlineIcon
                                    sx={{ fontSize: "1rem" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                padding: "10px",
              }}
            >
              {/* Tabs */}
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label="General Contractors"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label="Documents"
                  {...a11yProps(1)}
                />
              </Tabs>

              <Divider sx={{ mb: 2 }} />

              <CustomTabPanel value={tabValue} index={0}>
                <Box sx={{ marginTop: "20px" }}>
                  <TableContainer
                    component={Box}
                    sx={{
                      marginTop: "10px",
                      minHeight: "250px",
                      maxHeight: "380px",
                    }}
                  >
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell>Sr. </TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state?.contacts?.map((contact, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{contact?.display_name}</TableCell>
                              <TableCell>{contact?.phone}</TableCell>
                              <TableCell>{contact?.email}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </CustomTabPanel>

              <CustomTabPanel value={tabValue} index={1}>
                <Box sx={{ marginTop: "20px" }}>
                  <TableContainer
                    component={Box}
                    sx={{
                      marginTop: "10px",
                      minHeight: "250px",
                      maxHeight: "380px",
                    }}
                  >
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell>Sr. </TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>File Type</TableCell>
                          <TableCell>Link</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state?.documents?.map((document, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{document?.type}</TableCell>
                              <TableCell>{document?.fileType}</TableCell>
                              <TableCell
                                sx={{
                                  cursor: "pointer",
                                  color: "#551a8b",
                                  textDecoration: "underline",
                                  wordBreak: "break-word",
                                }}
                                onClick={() => {
                                  window.open(document?.documentsUrl, "_blank");
                                }}
                              >
                                {document?.documentsUrl ? `Open` : ""}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <DescriptionDialog
        open={showDescriptionDialog}
        onClose={() => {
          setDescription("");
          setShowDescriptionDialog(false);
        }}
        description={description}
      />
      {showAddNotesDialog && (
        <AddNoteDialog
          open={showAddNotesDialog}
          onClose={(success) => {
            if (success)
              dispatch(
                fetchNotes({
                  opportunityId: state?.opportunityId,
                })
              );
            setShowAddNotesDialog(false);
            setSelectedRow(null);
          }}
          opportunityId={state?.opportunityId}
          updateData={selectedRow}
        />
      )}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteNote();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Note"
          title={`Are you sure, you want to delete selected note?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
      {status === "loading" ? <Loader /> : null}
    </>
  );
}
export default OpportunityDetails;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));
