import React, { useEffect } from "react";
import AppDialog from "../../common/AppDialog/AppDialog";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyContracts } from "../../../redux/slice/CompanySlice/CompanySlice";
import Loader from "../../Loader/Loader";
import NoData from "../../../Assets/Images/no_data.png";

function CompanyContactsDialog({ open, onClose, state }) {
  const { CompanyContracts, status } = useSelector((state) => state?.Companies);

  const dispatch = useDispatch();
  useEffect(() => {
    if (state.companyId) dispatch(fetchCompanyContracts(state?.companyId));
  }, [dispatch, state]);

  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title={`${state?.name} Contacts`}
      minHeight="auto"
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer
            component={Box}
            sx={{ marginTop: "5px", maxHeight: "300px" }}
          >
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead className="table-head">
                <TableRow>
                  <TableCell width={"5%"}>Sr.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CompanyContracts?.length > 0 ? (
                  CompanyContracts?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>

                      <TableCell>{item.display_name}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.phone}</TableCell>

                      <TableCell>{item.email}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell colSpan={5} align="center">
                      <img
                        
                        width={100}
                        height={100}
                        src={NoData}
                        alt="No data available"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {status === "loading" ? <Loader /> : null}
    </AppDialog>
  );
}

export default CompanyContactsDialog;
CompanyContactsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.any,
};
