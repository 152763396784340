import React, { createContext, useState, useContext } from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

const AuthContext = createContext(null);
const cookies = new Cookies();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(cookies.get("LoginId"));
  const [userRoles, setUserRoles] = useState([]);

  const signup = (user) => {
    setUser(user);
  };

  const setRoles = (userRoles) => {
    setUserRoles(userRoles);
  };

  return (
    <AuthContext.Provider value={{ user, signup, userRoles, setRoles }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
