import React, { useContext } from "react";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import AppDialog from "../../../../common/AppDialog/AppDialog";
import { SnackBarContext } from "../../../../../context/SnackBarContext";
import { fetchAllRolesData } from "../../../../../redux/slice/RolesSlice/RolesSlice";
import {
  CreateRoleAPi,
  UpdateRoleAPi,
} from "../../../../../Apis/Roles/RolesApis";
import { ThreeDots } from "react-loader-spinner";

const validationSchema = yup.object({
  name: yup.string().required("Field Required!"),
  description: yup.string().required("Field Required!"),
  screenIds: yup.array().min(1, "Please add at least one screen"),
});

function AddUpdateRoleDialog({ open, onClose, selectedRow }) {
  const dispatch = useDispatch();
  const { RolesPopulateData } = useSelector((state) => state?.Roles);

  const { setsnackBarData } = useContext(SnackBarContext);

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={selectedRow ? "Update Role" : "Add Role"}
      minHeight="auto"
    >
      <Box my={2}>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={{
            name: selectedRow ? selectedRow?.name : "",
            description: selectedRow ? selectedRow?.description : "",
            screenIds: selectedRow
              ? selectedRow?.Screens?.map((screen) => screen?.screenId)
              : [],
          }}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, resetForm }) => {
            const formData = {
              name: data?.name,
              description: data?.description,
              screenIds: data?.screenIds,
            };

            let response;
            if (!selectedRow) {
              response = await CreateRoleAPi(formData);
            } else {
              response = await UpdateRoleAPi(selectedRow?.roleId, formData);
            }
            response?.snackBarData?.message &&
              setsnackBarData(response?.snackBarData);

            if (response?.success) {
              dispatch(fetchAllRolesData());
              resetForm();
              onClose();
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form noValidate autoComplete="off">
                <TextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={"Name"}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.name && touched?.name}
                  helperText={errors?.name && touched?.name ? errors?.name : ""}
                />

                <TextField
                  sx={{
                    mt: 2,
                  }}
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={"Description"}
                  multiline
                  rows={5}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors?.description && touched?.description}
                  helperText={
                    errors?.description && touched?.description
                      ? errors?.description
                      : ""
                  }
                />

                <Autocomplete
                  multiple
                  id="tags-outlined"
                  sx={{
                    mt: 2,
                  }}
                  options={RolesPopulateData || []}
                  getOptionLabel={(option) => option.name}
                  value={
                    RolesPopulateData?.filter((role) =>
                      values.screenIds?.includes(role?.screenId)
                    ) || []
                  }
                  onChange={(event, value) => {
                    let ids = value.map((screen) => screen?.screenId);
                    setFieldValue("screenIds", ids);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Screen Ids"
                      fullWidth
                      name="screenIds"
                      onBlur={handleBlur}
                      error={errors?.screenIds && touched?.screenIds}
                      helperText={
                        errors?.screenIds && touched?.screenIds
                          ? errors?.screenIds
                          : ""
                      }
                    />
                  )}
                />

                <Box
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#f79c35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isSubmitting}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {selectedRow ? "Update" : "Add"}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </AppDialog>
  );
}

export default AddUpdateRoleDialog;
AddUpdateRoleDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
};
