import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../../context/useAuth";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const cookies = new Cookies();

function RequireAuth({ children }) {
  const [token, setToken] = useState(cookies.get("access_token"));

  const { user, signup } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(cookies.get("access_token"));
  }, [user]);

  if (!user || !token) {
    return <Navigate to="/signin" />;
  } else {
    const { exp } = jwtDecode(token);
    const expiryTime = new Date(moment.unix(exp).format()).getTime();
    const currentTime = new Date().getTime();

    if (currentTime <= expiryTime) {
      return children;
    } else {
      cookies.remove("access_token");
      cookies.remove("LoginId");
      signup(null);
      navigate("/sigin", { replace: true });
    }
  }
}

export default RequireAuth;
RequireAuth.propTypes = {
  children: PropTypes.any.isRequired,
};
