import axiosInstance from "../../utils/AxiosInstance";

export const CreateSourceAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/OpportunitySource/Create", data);
    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateSourceAPi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(
      `/OpportunitySource/Update/${id}`,
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteSourceAPi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(
      `/OpportunitySource/Delete/${id}`
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const CreateProjectTypeAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Type/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateProjectTypeAPi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(`/Type/Update/${id}`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteProjectTypeAPi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/Type/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const CreateTagAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/OpportunityTag/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateTagAPi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(
      `/OpportunityTag/Update/${id}`,
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteTagAPi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/OpportunityTag/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const CreateOpportunityAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("/Opportunity/Create", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateOpportunityAPi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(`/Opportunity/Update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const CreateNewDocumentsAPi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(
      "/Opportunity/CreateDocument",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
