import { axiosInstanceForm } from "../../utils/AxiosInstance";

export const CreateChangeReqApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.post("/ChageRequest/Create", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const UpdateChangeReqApi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.put(
      `/ChageRequest/Update/${id}`,
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const DeleteChangeReqApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.delete(`/ChageRequest/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
