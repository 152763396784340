import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  OpportunitySummaryData: null,
  OpportunityRevenueSummaryData: null,
  OpportunitySuccessRateData: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchOpportunitySummaryData = createAsyncThunk(
  "/Dashboard/OpportunitySummary",
  async () => {
    const response = await axiosInstance.get("/Dashboard/OpportunitySummary");
    return response?.data;
  }
);

export const fetchOpportunityRevenueSummaryData = createAsyncThunk(
  "/Dashboard/OpportunityRevenueSummary",
  async () => {
    const response = await axiosInstance.get(
      "/Dashboard/OpportunityRevenueSummary"
    );
    return response?.data;
  }
);

export const fetchOpportunitySuccessRateData = createAsyncThunk(
  "/Dashboard/OpportunitySuccessRate",
  async (payload) => {
    const response = await axiosInstance.get(
      `/Dashboard/OpportunitySuccessRate?startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
    return response?.data?.data;
  }
);

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchOpportunitySummaryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOpportunitySummaryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
        state.OpportunitySummaryData = action.payload;
      })
      .addCase(fetchOpportunitySummaryData.rejected, (state, action) => {
        state.OpportunitySummaryData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchOpportunityRevenueSummaryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchOpportunityRevenueSummaryData.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.message = action.payload.message;
          state.isSuccess = action.payload.isSuccess;
          state.OpportunityRevenueSummaryData = action.payload;
        }
      )
      .addCase(fetchOpportunityRevenueSummaryData.rejected, (state, action) => {
        state.OpportunityRevenueSummaryData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchOpportunitySuccessRateData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOpportunitySuccessRateData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isSuccess = action.payload.isSuccess;
        state.OpportunitySuccessRateData = action.payload;
      })
      .addCase(fetchOpportunitySuccessRateData.rejected, (state, action) => {
        state.OpportunitySuccessRateData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default DashboardSlice.reducer;
