import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Divider, Drawer, Skeleton } from "@mui/material";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import theme from "../theme/theme";

const SkeletonLayout = () => {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("temporary");

  const { width } = useWindowDimensions();

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (width < 1200) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [width]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 288 }}
      role="presentation"
      className={classes.drawerMainContainer}
    >
      <Box className={classes.drawerHeadContainer}>
        <Skeleton
          variant="rounded"
          width="100%"
          height={40}
        />
      </Box>

      <Divider
        sx={{
          backgroundColor: "rgb(96 125 139 /1)",
        }}
      />

      <Box className={classes.drawerList}>
        <Skeleton
          variant="rounded"
          width="100%"
          height={40}
        />

        <Skeleton
          variant="rounded"
          width="100%"
          height={40}
        />

        <Skeleton
          variant="rounded"
          width="100%"
          height={40}
        />

        <Skeleton
          variant="rounded"
          width="100%"
          height={40}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: { xs: "block", lg: "flex" },
      }}
    >
      <Box
        sx={{
          width: { xs: "auto", lg: "308px" },
          height: { xs: "auto", lg: "auto" },
        }}
      >
        <Drawer
          variant={drawerVariant}
          anchor={"left"}
          open={drawerOpen}
          onClose={() => toggleDrawer(false)}
          elevation={0}
          PaperProps={{
            sx: {
              margin: "15px 0 30px 15px",
              borderRadius: "12px",
              height: "calc(100vh - 30px)",
            },
          }}
        >
          {list("left")}
        </Drawer>
      </Box>
      <Box
        sx={{
          width: { xs: "auto", lg: "calc(100% - 308px)" },
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Skeleton
          variant="rounded"
          width="100%"
          height={70}
        />
        <Box
          sx={{
            width: "100%",
            height: "calc(100vh - 100px)",
          }}
        >
          <Skeleton
            variant="rounded"
            width="100%"
            height={"100%"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonLayout;

const useStyles = makeStyles(() => ({
  drawerMainContainer: {
    height: "100vh",
    backgroundImage: "linear-gradient(to bottom right, #37474f , #263238)",
    color: "rgb(255 255 255/1)",
    overflowY: "auto",
  },

  drawerHeadContainer: {
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },

  drawerHeadLink: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    color: "rgb(255 255 255/1)",
  },

  drawerList: {
    margin: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));
