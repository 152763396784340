import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import PropTypes from "prop-types";

function Opportunity({ handleBreadcrumbs }) {
  const { paperBox, paperContentBox, contentBox, textBox, setingText } =
    useStyle();
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ fontWeight: "bold" }}>Opportunity</Typography>
      <Typography>Manage all your Opportunity in the system.</Typography>
      <Paper className={paperBox} elevation={3}>
        <Box className={paperContentBox}>
          <Box
            onClick={() => {
              navigate("/settings/sources");
              handleBreadcrumbs("Sources", "/settings/sources");
            }}
            className="settingBoxA"
            sx={{ padding: "10px", cursor: "pointer" }}
            id="settings-groups"
          >
            <Box className={contentBox}>
              <SourceOutlinedIcon fontSize="large" />
              <Box className={textBox}>
                <Typography variant="h6">Sources</Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  Add,edit or delete Sources from{" "}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  System
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            onClick={() => {
              navigate("/settings/project-types");
              handleBreadcrumbs("Project Types", "/settings/project-types");
            }}
            sx={{ padding: "10px", cursor: "pointer" }}
            id="settings-groups"
            className="settingBoxA"
          >
            <Box className={contentBox}>
              <AccountTreeOutlinedIcon fontSize="large" />
              <Box className={textBox}>
                <Typography variant="h6">Project Types</Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  Add,edit or delete Project Types from{" "}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  System
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            onClick={() => {
              navigate("/settings/tags");
              handleBreadcrumbs("Tags", "/settings/tags");
            }}
            sx={{ padding: "10px", cursor: "pointer" }}
            id="settings-groups"
            className="settingBoxA"
          >
            <Box className={contentBox}>
              <StyleOutlinedIcon fontSize="large" />
              <Box className={textBox}>
                <Typography variant="h6">Tags</Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  Add,edit or delete Tags from{" "}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  System
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
export default Opportunity;

Opportunity.propTypes = {
  handleBreadcrumbs: PropTypes.func,
};

const useStyle = makeStyles(() => {
  return {
    paperBox: {
      width: "100%",
      maxWidth: "1280px",
      padding: "20px",
      marginTop: "20px",
    },
    paperContentBox: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "10px",
    },
    contentBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    textBox: {
      height: "90px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    setingText: {
      transform: "scale(0)",
      height: "0px",
    },
  };
});
