import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AppDialog from "../../common/AppDialog/AppDialog";

function TemplateViewModal({ template, handleClosed, showModal }) {
  const [templateRowData, settemplateRowData] = useState(null);
  
  useEffect(() => {
    if (template) {
      let updatedString = template?.body;

      template?.placeholders?.map((search) => {
        updatedString = updatedString.replace(
          new RegExp(search?.key, "g"),
          `{ ${search?.title} }`
        );
      });
      console.log(updatedString);
      settemplateRowData({ ...template, body: updatedString });
    }
  }, [template?.placeholders]);
  return (
    <AppDialog
      open={showModal}
      onClose={() => {
        handleClosed();
      }}
      title={"Template View"}
      minHeight="auto"
    >
      <Box border="1px solid #bdbdbd" borderRadius="10px" padding="8px">
        <Typography
          dangerouslySetInnerHTML={{ __html: templateRowData?.body }}
        />
      </Box>
    </AppDialog>
  );
}

export default TemplateViewModal;
TemplateViewModal.propTypes = {
  showModal: PropTypes.array,
  handleClosed: PropTypes.any,
  template: PropTypes.any,
};
