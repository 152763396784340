import { React, useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, FormControl, FormHelperText } from "@mui/material";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import {
  ContentState,
  EditorState,
  Modifier,
  RichUtils,
  getDefaultKeyBinding,
} from "draft-js";
import { fetchTemplateData } from "../../../redux/slice/EmailTemplateSlice/EmailTemplateSlice";
import { UpdateEmailTemplateApi } from "../../../Apis/EmailTemplateApis/EmailTemplateApis";
import { SnackBarContext } from "../../../context/SnackBarContext";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";

function EmailTemplatesForm2() {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [tagCount, setTagCount] = useState(0);

  const [newPlaceHolders, setNewPlaceHolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const companyData = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    title: Yup.string().required("Field required!"),
    body: Yup.string().required("Field required!"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const insertText = (text, editorValue) => {
    setTagCount(tagCount + 1);
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );

    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };
  const onChange = async (value) => {
    setEditorState(value);
  };
  const handleKeyCommand = (command, editorState) => {
    // Intercept Enter key press
    if (command === "split-block") {
      // Create a new EditorState with a soft line break instead of a new paragraph
      const newState = RichUtils.insertSoftNewline(editorState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const mapKeyToEditorCommand = (e) => {
    // Handle Enter key press with shift
    if (e.keyCode === 13 /* 'Enter' key */ && e.shiftKey) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };

  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };
  const handleEdit = (values, updatedString) => {
    formik.handleChange({
      target: {
        name: "title",
        value: values?.title || "",
      },
    });
    setNewPlaceHolders(values?.placeholders);
    formik?.setFieldValue("body", updatedString);
    let body = htmlToDraft(updatedString ? updatedString : "");
    if (body) {
      const contentState = ContentState.createFromBlockArray(
        body?.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      setEditorState(editorState);
    }
  };
  useEffect(() => {
    let updatedString = companyData?.state?.body;
    if (companyData?.state?.body) {
      companyData?.state?.placeholders?.map((search) => {
        updatedString = updatedString.replace(
          new RegExp(search?.key, "g"),
          `{ ${search?.title} }`
        );
      });
    }

    if (companyData?.state?.ID) {
      formik?.setFieldValue("title", companyData?.state?.title);

      formik?.setFieldValue("body", updatedString);

      let body = htmlToDraft(updatedString ? updatedString : "");

      if (body?.contentBlocks) {
        const contentState = ContentState.createFromBlockArray(
          body?.contentBlocks
        );

        const editorState = EditorState.createWithContent(contentState);

        setEditorState(editorState);
      }
      setNewPlaceHolders(companyData?.state?.placeholders);
    } else if (companyData?.state) {
      handleEdit(companyData?.state, updatedString);
    }
  }, [companyData?.state]);
  useEffect(() => {
    let html = convertToHTML(editorState && editorState.getCurrentContent());

    if (html !== "<p></p>") {
      formik.values.body = html;
    }
  }, [editorState]);
  const handleSubmit = async (values, resetForm, setSubmitting) => {
    // const contentState = editorState.getCurrentContent();
    // const rawContent = convertToRaw(contentState);
    // const text = rawContent.blocks[0].text;

    // Replace placeholders
    let body = values.body.replace(/\{[\s\w]+\}/g, (match) => {
      let item = newPlaceHolders?.find(
        (item) => item?.title == match?.replace(/[^\w\s]/g, "")?.trim()
      );

      if (item) return item?.key;
      else return match;
    });

    const obj = {
      title: values.title,
      body: body,
      templatePlaceholders: newPlaceHolders,
    };

    setLoading(true);

    const response = await UpdateEmailTemplateApi({
      ...obj,
      templateId: companyData?.state?.templateId,
    });
    response?.snackBarData?.message && setsnackBarData(response.snackBarData);
    if (response?.success) {
      dispatch(fetchTemplateData());
      resetForm();

      navigate(-1);
    }
    setLoading(false);

    setSubmitting(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        width: "100%",
        height: "calc(100vh - 100px)",
        overflowY: "scroll",
      }}
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={classes.formContainer}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography className={classes?.fieldsHeading}>
              Email Template
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <TextField
                  name="title"
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  label="Title"
                  onBlur={formik.handleBlur}
                  error={formik.errors?.title && formik.touched?.title}
                  helperText={
                    formik.errors?.title && formik.touched?.title
                      ? formik.errors?.title
                      : ""
                  }
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography className={classes?.fieldsHeading}>Body</Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.body) && Boolean(formik.touched.body)
                  }
                >
                  <Box
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    <Box mb={2}>
                      {[
                        ...new Set(
                          companyData.state?.placeholders?.map(
                            (btn) => btn.title
                          )
                        ),
                      ].map((title, idx) => {
                        const btn = companyData.state.placeholders.find(
                          (btn) => btn.title === title
                        );
                        return (
                          <Button
                            key={idx}
                            variant="outlined"
                            sx={{ mr: 1 }}
                            color="info"
                            onClick={() => {
                              sendTextToEditor(` { ${btn?.title} } `);
                              setNewPlaceHolders((prevPlaceholders) => [
                                ...prevPlaceholders,
                                { title: btn.title, key: btn?.key },
                              ]);
                            }}
                          >
                            {btn?.title}
                          </Button>
                        );
                      })}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      maxHeight: "500px",
                      overflow: "auto",
                      border:
                        Boolean(formik.errors.body) &&
                        Boolean(formik.touched.body)
                          ? "1px solid red"
                          : "1px solid #bdbdbd",
                      padding: "8px",
                    }}
                  >
                    <Editor
                      placeholder="Start Typing your body from here"
                      editorState={editorState}
                      handleKeyCommand={handleKeyCommand}
                      keyBindingFn={mapKeyToEditorCommand}
                      onEditorStateChange={(value) => {
                        onChange(value);
                      }}
                    />
                  </Box>
                  <FormHelperText>
                    {Boolean(formik.errors.body) && Boolean(formik.touched.body)
                      ? formik.errors.body
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#f79c35"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loading}
          />

          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default EmailTemplatesForm2;
const useStyles = makeStyles(() => ({
  formContainer: {
    padding: "1.5rem",
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));
