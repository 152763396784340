import { Box, IconButton, Tooltip, gridClasses } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddUpdateDialog from "../../../components/Settings/Opportunity/ScreenComponents/AddUpdateDialog/AddUpdateDialog";
import Loader from "../../../components/Loader/Loader";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { SnackBarContext } from "../../../context/SnackBarContext";
import AppConfirmationDialog from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { fetchTemplateData } from "../../../redux/slice/EmailTemplateSlice/EmailTemplateSlice";
import { DeleteEmailTemplateApi } from "../../../Apis/EmailTemplateApis/EmailTemplateApis";
import TemplateViewModal from "./TemplateViewModal";
import { fetchTagsData } from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

function EmailTemplatesTable() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const { TemplateData, status } = useSelector((state) => state?.EmailTemplate);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTemplateData());
  }, [dispatch]);

  useEffect(() => {
    if (TemplateData) {
      setalldata(TemplateData);
    } else {
      setalldata([]);
    }
  }, [TemplateData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "5px",
            width: "100%",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Box>

          <Box>
            {/* <Button
              color="primary"
              sx={{
                float: "right",
              }}
              variant="contained"
              size="small"
              onClick={() => navigate("/settings/email-template/form")}
            >
              New
            </Button> */}
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }
  const deleteTemplate = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteEmailTemplateApi(selectedRow?.templateId);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      dispatch(fetchTemplateData());
      setSelectedRow(null);
    }
    setLoading(false);
  };
  const titleToDescriptionMap = {
    "Follow Up": `Send to the selected general contractors when the "Send" button is clicked on the follow-up screen.`,
    "Bid Acknowledgement":
      "Send to the contact when an opportunity is created.",
    "Bid Confirmation (CPC is Bidding)":
      "Send to the company when the opportunity is qualified.",
    "Bid Rejection (CPC is NOT Bidding)":
      "Send to the company when the opportunity is not qualified.",
  };

  const columns = [
    { field: "id", headerName: "Sr", minWidth: 50, flex: "auto" },
    {
      field: "title",
      headerName: "Title",
      minWidth: 300,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 400,

      renderCell: (params) => {
        const title = params.row.title;
        const description =
          titleToDescriptionMap[title] || "No description available";

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "normal",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <span>{description}</span>
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,

      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Edit">
              <IconButton
                sx={{ padding: "5px" }}
                color="info"
                onClick={() => {
                  navigate("/settings/email-template/form", {
                    state: params?.row,
                  });
                }}
              >
                <EditIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            {/* 
            <Tooltip title="Delete">
              <IconButton
                sx={{ padding: "5px" }}
                color="error"
                onClick={() => {
                  setSelectedRow(params?.row);
                  setShowConfirmation(true);
                }}
              >
                <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip> */}
            &nbsp; &nbsp;
            <Tooltip title="View Template">
              <IconButton
                sx={{ padding: "5px" }}
                color="success"
                onClick={() => {
                  setSelectedRow(params?.row);
                  setShowModal(true);
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  React.useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows}
          columns={columns}
          pageSize={100}
          pageSizeOptions={false}
          pagination
          components={{
            toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />

        {status === "loading" || loading ? <Loader /> : null}
      </Box>

      <AddUpdateDialog
        open={showAddDialog}
        onClose={() => {
          setShowAddDialog(false);
          setSelectedRow(null);
        }}
        selectedRow={selectedRow}
        heading="Tag"
        onSuccess={() => {
          dispatch(fetchTagsData());
        }}
      />

      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteTemplate();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Tag"
          title={`Are you sure, you want to delete template ${selectedRow?.title}?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
      {showModal && (
        <TemplateViewModal
          handleClosed={() => setShowModal(false)}
          showModal={showModal}
          template={selectedRow}
        />
      )}
    </>
  );
}
export default EmailTemplatesTable;
