import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import AppDialog from "../common/AppDialog/AppDialog";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { SnackBarContext } from "../../context/SnackBarContext";
import { SendFollowUpsApi } from "../../Apis/FollowUps/FollowUps";
import moment from "moment";

const validationSchema = Yup.object({
  gcList: Yup.array().min(1, "Please select a contractor!"),
});

function AddFollowUpsDialog({ open, onClose, selectedRow }) {
  const { setsnackBarData } = useContext(SnackBarContext);

  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Send Follow Up"
      minHeight="auto"
    >
      <Formik
        initialValues={{
          scheduleOn: "",
          gcList: [],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          let formData = {
            opportunityId: selectedRow?.opportunityId,
            opportunityTitle: selectedRow?.opportunity?.title,
            followUpId: selectedRow?.followUpId,
            gcList: values?.gcList?.map((gc) => ({
              contactId: gc?.contactId,
              displayName: gc?.display_name,
              email: gc?.email,
            })),
          };

          if (values.scheduleOn) {
            formData.scheduleOn = values.scheduleOn;
          }

          const response = await SendFollowUpsApi(formData);

          setSubmitting(false);
          response?.snackBarData?.message &&
            setsnackBarData(response?.snackBarData);

          if (response?.success) {
            resetForm();
            onClose(true);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => {
          return (
            <Form>
              <Box
                my={2}
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Box>
                  <Typography
                    mb={1}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#0288d1",
                    }}
                  >
                    Select the contractors you wish to send an email to, and
                    proceed by confirming your selection
                  </Typography>
                  <Autocomplete
                    multiple
                    options={selectedRow?.opportunity?.contacts || []}
                    getOptionLabel={(option) => option?.display_name}
                    renderOption={(props, option) => {
                      return (
                        <ListItem
                          {...props}
                          key={option?.contactId}
                        >
                          <Typography>
                            {option?.display_name} ({option?.phone})
                          </Typography>
                        </ListItem>
                      );
                    }}
                    fullWidth
                    value={values.gcList || []}
                    onChange={(event, value) => {
                      setFieldValue("gcList", value);
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="General Contractor"
                        size="small"
                        name="gcList"
                        onBlur={handleBlur}
                        error={errors?.gcList && touched?.gcList}
                        helperText={
                          errors?.gcList && touched?.gcList
                            ? errors?.gcList
                            : ""
                        }
                      />
                    )}
                  />
                </Box>

                <Divider />

                <Box>
                  <Typography
                    mb={1}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#0288d1",
                    }}
                  >
                    {`Kindly include the 'Next Follow-Up Date' where relevant.`}
                  </Typography>
                  <TextField
                    label="Schedule On"
                    variant="outlined"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    inputProps={{
                      min: moment(selectedRow?.scheduleOn)
                        .add(1, "day")
                        .format("YYYY-MM-DDTHH:mm"),
                    }}
                    name="scheduleOn"
                    value={values.scheduleOn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors?.scheduleOn && touched?.scheduleOn}
                    helperText={
                      errors?.scheduleOn && touched?.scheduleOn
                        ? errors?.scheduleOn
                        : ""
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#f79c35"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={isSubmitting}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    color="primary"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </AppDialog>
  );
}

export default AddFollowUpsDialog;
AddFollowUpsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
};
