import {
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChangeReqDataById } from "../../redux/slice/ChangeReqSlice/ChangeReqSlice";
import ChangeReqDialog from "./ChangeReqDialog";
import PropTypes from "prop-types";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AppConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { SnackBarContext } from "../../context/SnackBarContext";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import {
  DeleteChangeReqApi,
  UpdateChangeReqApi,
} from "../../Apis/ChangeReq/ChangeReqApi";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import NoData from "../../Assets/Images/no_data.png";

function ChangeReqView({ opportunityId, view, activeStep, steps, handleNext }) {
  const dispatch = useDispatch();
  const { ChangeReqDataId, status } = useSelector((state) => state?.ChangeReq);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [selectedRow, setSelectedRow] = useState(false);
  const deleteItem = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const response = await DeleteChangeReqApi(selectedRow?.changeRequestId);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      dispatch(fetchChangeReqDataById(opportunityId));
      setSelectedRow(null);
    }
    setLoading(false);
  };
  const onClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };
  useEffect(() => {
    if (opportunityId) dispatch(fetchChangeReqDataById(opportunityId));
  }, [dispatch, opportunityId]);

  const updateStatus = async () => {
    setLoading(true);
    setShowConfirmation(false);

    const formData = new FormData();

    formData.append("status", "Updated");
    formData.append("opportunityId", opportunityId);

    let response;

    response = await UpdateChangeReqApi(selectedRow?.changeRequestId, formData);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      dispatch(fetchChangeReqDataById(opportunityId));
    }

    setLoading(false);
  };
  return (
    <TableContainer
      sx={{ height: !view && "350px", overflowY: !view && "scroll" }}
    >
      <Table aria-label="simple table">
        <TableHead className="table-head">
          <TableRow>
            <TableCell>Sr</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Status</TableCell>
            {view ? (
              <>
                <TableCell>Document</TableCell>
                <TableCell></TableCell>
              </>
            ) : (
              <>
                <TableCell> Actions</TableCell>

                <TableCell>
                  <Button
                    color="primary"
                    sx={{ float: "right" }}
                    variant="contained"
                    size="small"
                    onClick={() => setOpen(true)}
                  >
                    New
                  </Button>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {ChangeReqDataId?.data?.length > 0
            ? ChangeReqDataId?.data?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell sx={{ maxWidth: "350px" }}>
                    {row?.description}
                  </TableCell>
                  <TableCell>
                    <StatusChip status={row?.status} />
                  </TableCell>

                  {view ? (
                    <TableCell
                      sx={{
                        cursor: row?.documentsUrl && "pointer",
                        color: row?.documentsUrl && "#551a8b",
                        textDecoration: row?.documentsUrl && "underline",
                      }}
                      onClick={() => {
                        row?.documentsUrl && window.open(row?.file, "_blank");
                      }}
                    >
                      {row?.documentsUrl ? `Open` : "N/A"}
                    </TableCell>
                  ) : (
                    <TableCell>
                      {row?.documentsUrl && (
                        <Tooltip title="View Document">
                          <IconButton
                            sx={{ padding: "5px" }}
                            color="secondary"
                            onClick={() => {
                              row?.documentsUrl &&
                                window.open(row?.documentsUrl, "_blank");
                            }}
                          >
                            <ArticleIcon sx={{ fontSize: "1rem" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {row?.status !== "Closed" && (
                        <Tooltip title="Edit Request">
                          <IconButton
                            sx={{ padding: "5px" }}
                            color="info"
                            onClick={() => {
                              setSelectedRow(row);
                              setOpen(true);
                            }}
                          >
                            <EditIcon sx={{ fontSize: "1rem" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Delete Request">
                        <IconButton
                          sx={{ padding: "5px" }}
                          color="error"
                          onClick={() => {
                            setSelectedRow(row);
                            setShowConfirmation(true);
                          }}
                        >
                          <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}

                  {view &&
                    (row?.status === "New" ? (
                      <TableCell>
                        <Button
                          onClick={() => {
                            setSelectedRow(row);
                            setShowConfirmation(true);
                          }}
                          size="small"
                          variant="outlined"
                          color="info"
                          sx={{ float: "left" }}
                        >
                          Change Status
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    ))}
                  {!view && <TableCell></TableCell>}
                </TableRow>
              ))
            : !view && (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={5} align="center">
                    <img
                      style={{ marginTop: "65px" }}
                      width={100}
                      height={100}
                      src={NoData}
                      alt="No data available"
                    />
                  </TableCell>
                </TableRow>
              )}
        </TableBody>
      </Table>

      {open && (
        <ChangeReqDialog
          opportunityId={opportunityId}
          open={open}
          onClose={onClose}
          selectedRow={selectedRow}
        />
      )}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            view ? updateStatus() : deleteItem();
          }}
          iconContainerColor={view ? "#eaeafb" : "#fbeaea"}
          icon={
            view ? (
              <ChangeCircleOutlinedIcon
                fontSize="small"
                sx={{ color: (theme) => theme?.palette?.info?.main }}
              />
            ) : (
              <DeleteOutlineIcon
                fontSize="small"
                sx={{ color: (theme) => theme?.palette?.error?.main }}
              />
            )
          }
          dialogTitle={view ? "Update Status" : "Delete Request"}
          title={
            view
              ? `Are you sure, you want to change selected request?`
              : `Are you sure, you want to delete selected request?`
          }
          successText="Yes"
          successBtnColor={view ? "info" : "error"}
          cancelText="No"
        />
      )}
      {status === "loading" || loading ? <Loader /> : null}
      {view && (
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Button onClick={() => navigate(-1)} variant="outlined" color="error">
            Back
          </Button>
          <Button
            //   type="submit"
            onClick={handleNext}
            variant="contained"
            disabled={loading}
            color="primary"
          >
            {activeStep === steps?.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      )}
    </TableContainer>
  );
}

export default ChangeReqView;

const StyledChip = styled(Chip)(({ status }) => ({
  borderRadius: 0, // Makes the chip square
  ...(status === "New" && {
    color: "#004080",
    backgroundColor: "#cce6ff",
  }),
  ...(status === "Closed" && {
    color: "#FF8C00",
    backgroundColor: "#FFE4B2",
  }),
  ...(status === "Updated" && {
    color: "#008080",
    backgroundColor: "#B2D8D8",
  }),
}));

const StatusChip = ({ status }) => {
  return <StyledChip size="small" label={status} status={status} />;
};
ChangeReqView.propTypes = {
  opportunityId: PropTypes.any,
  handleNext: PropTypes.any,
  activeStep: PropTypes.any,
  steps: PropTypes.any,

  view: PropTypes.bool,
};
StatusChip.propTypes = {
  status: PropTypes.any,
};
