import { Box } from "@mui/material";
import Roles from "../../components/Settings/Roles/Roles";
import Opportunity from "../../components/Settings/Opportunity/Opportunity";
import Others from "../../components/Settings/Others/Others";
import { useContext } from "react";
import { BreadcrumbsContext } from "../../context/BcrumbsContextProvider";
// import Users from "../../components/Settings/Users/Users";

function Settings() {
  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);
  const handleBreadcrumbs = (name, url) => {
    setBreadcrumbsItems([
      {
        id: breadcrumbsItems.length,
        url: "/",
        name: "Dashboard",
      },
      {
        id: breadcrumbsItems.length,
        url: "/settings",
        name: "Settings",
      },
      {
        id: breadcrumbsItems.length,
        url: url,
        name: name,
      },
    ]);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <Roles handleBreadcrumbs={handleBreadcrumbs} />
      <Opportunity handleBreadcrumbs={handleBreadcrumbs} />
      <Others handleBreadcrumbs={handleBreadcrumbs} />

      {/* <Users /> */}
    </Box>
  );
}

export default Settings;
