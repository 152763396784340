import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Font,
  Line,
  Svg,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { Html } from "react-pdf-html";
import BrandLogo from "../../Assets/Images/CPC_W.jpg";
// import CalibriBold from "../../Assets/Fonts/AAAAAC-Calibri-Bold.ttf";
import CalibriBold from "../../Assets/Fonts/calibri-bold.ttf";
import CalibriRegular from "../../Assets/Fonts/Calibri.ttf";

import moment from "moment";

// Font.register({ family: "Calibri", src: CalibriBold, fontWeight: 1000 });
Font.register({
  family: "Calibri",
  fonts: [
    {
      src: CalibriBold,
      fontWeight: "bold",
    },
    {
      src: CalibriRegular,
      fontWeight: "normal",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Calibri",
    fontWeight: "bold",
    padding: "0px 20px",
    paddingTop: "20px",
    paddingBottom: "120px",
    // fontSize: "12px",
    fontSize: 8,
  },
  page2: {
    fontFamily: "Calibri",
    fontWeight: "normal",
    padding: "0px 20px",
    paddingTop: "20px",
    paddingBottom: "120px",
    // fontSize: "12px",
    fontSize: 8,
  },
  section: {
    border: "1px solid black",
    padding: 3,
    marginBottom: 2,
  },
  pageHeader: {
    height: "55px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: 7,
    fontFamily: "Calibri",
    fontWeight: "bold",
    marginBottom: 25,
  },
  infoBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    // paddingBottom: 1,
    fontSize: 8,
    paddingBottom: 6,
    // fontWeight: 1000,
  },
  infoBoxText: {
    textAlign: "left",
    fontSize: 8,
  },
  titleBar: {
    padding: "5px 0",
    textAlign: "center",
    backgroundColor: "#d9d9d9",
  },
  titleBar2: {
    fontFamily: "Calibri",
    fontWeight: "bold",
    padding: "5px 0",
    textAlign: "center",
    backgroundColor: "#aeaaaa",
  },
  centerText: {
    textAlign: "center",
    fontSize: 9,
    marginBottom: 5,
  },
  leftText: {
    textAlign: "left",
    marginBottom: 5,
    fontFamily: "Calibri",
    fontWeight: "bold",
  },
  leftTextUnderline: {
    textAlign: "left",
    marginBottom: 5,
    textDecoration: "underline",
    fontFamily: "Calibri",
    fontWeight: "bold",
  },

  pointsMargin: {
    marginBottom: 6,
  },
  signatureBox: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: 10,
  },
});

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "100%",
  },
  tableRow: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  tableRowHeader: {
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "#aeaaaa",
  },
  tableCellHeader: {
    padding: 5,
    fontFamily: "Calibri",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    padding: 5,
    textAlign: "center",
  },

  rateTableCellHeader: {
    padding: 5,
    textAlign: "center",
    fontFamily: "Calibri",
    fontWeight: "bold",
  },
  rateTableCell: {
    paddingTop: 3,
    paddingBottom: 3,

    textAlign: "center",
    fontFamily: "Calibri",
    fontWeight: "bold",
  },
});
const stylesheet = {
  // clear margins for all <p> tags
  // strong: {
  //   fontWeight: 1000,
  // },
};
function ProposalPrintNew({
  exclusionsArr,
  interiorArr,
  exteriorArr,
  alternatesArr,
  estimationData,
  opportunityData,
  extSum,
  altSum,
  intSum,
  contact,
}) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Page Header */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <View fixed style={styles.pageHeader}>
          <Image
            // style={{ width: "200px", height: "100px" }}
            src={BrandLogo}
            fixed
          />
          <View style={{ paddingTop: 8 }}>
            <Text style={{ marginTop: 2 }}>Mailing Address:</Text>
            <Text style={{ marginTop: 2 }}>5660 Strand Court, Suite A18,</Text>
            <Text style={{ marginTop: 2 }}>Naples, Florida 34110</Text>
            <Text style={{ marginTop: 2 }}>
              info@coastalpaintingcontractors.com
            </Text>
            <Text style={{ marginTop: 2 }}>+1 (844) 992-4272</Text>
          </View>
        </View>
        <View style={[styles.infoBox, { width: "50%", paddingBottom: "15px" }]}>
          <Text style={[styles.infoBoxText, { flex: 1 }]}>Date: </Text>
          <Text style={[styles.infoBoxText, { flex: 1 }]}>
            {moment().format("DD/MM/YYYY")}
          </Text>
        </View>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Job Information */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <View style={styles.titleBar}>
          <Text>Job Information</Text>
        </View>
        <View style={styles.centerText}>
          <Text>C24-ME-066</Text>
        </View>
        <View style={[styles.infoBox, { paddingBottom: "15px" }]}>
          <View style={[styles.infoBoxText, { flex: 1 }]}>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Estimator: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Blake Cathey
              </Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Email: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Blake Cathey
              </Text>
            </View>

            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Phone: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Blake Cathey
              </Text>
            </View>
          </View>

          <View style={[styles.infoBoxText, { flex: 1 }]}>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Business Development:
              </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Mohamed Elkholy
              </Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Email: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Blake Cathey
              </Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Phone: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Blake Cathey
              </Text>
            </View>
          </View>
        </View>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/*Client's Information         */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <View style={styles.titleBar}>
          <Text>Client&apos;s Information</Text>
        </View>
        <View style={styles.centerText}>
          <Text> {opportunityData?.company?.name}</Text>
        </View>
        <View style={[styles.infoBox, { paddingBottom: "15px", width: "50%" }]}>
          <View style={[styles.infoBoxText, { flex: 1 }]}>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>ATEENTION: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                {contact?.display_name}
              </Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Email: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                {contact?.email}
              </Text>
            </View>

            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Phone: </Text>
              <Text
                style={[
                  styles.infoBoxText,
                  { flex: 1, fontFamily: "Calibri", fontWeight: "extrabold" },
                ]}
              >
                {contact?.phone}
              </Text>
            </View>
          </View>
        </View>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/*Project's Information         */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <View style={styles.titleBar}>
          <Text>Project&apos;s Information</Text>
        </View>
        <View style={styles.centerText}>
          <Text>{estimationData?.opportunity?.title}</Text>
        </View>
        <View style={styles.centerText}>
          <Text>{estimationData?.opportunity?.address}</Text>
        </View>
        <View style={[styles.infoBox, { paddingBottom: "15px", width: "50%" }]}>
          <View style={[styles.infoBoxText, { flex: 1 }]}>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Revision: </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Blake Cathey
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: "35px" }}>
          <Text>
            With the most highly qualified and safety trained Journeyman
            Painters at your disposal, Coastal Painting Contractors proposes to
            furnish all labor, materials, and equipment to complete the work in
            our scope(s) and as described in the plans and specifications listed
            below.
          </Text>
        </View>
        <View
          style={[
            styles.infoBox,
            {
              paddingBottom: "15px",
              // marginBottom: "30px",
              width: "50%",
              color: "red",
            },
          ]}
        >
          <View style={[styles.infoBoxText, { flex: 1 }]}>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Bid Plans Dated:
              </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                01/08/24 ( up to addendum 02)
              </Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Bid Specifications Dated:
              </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>N/A</Text>
            </View>

            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Architect:</Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                RITTER MAHER ARCHITECTS
              </Text>
            </View>
          </View>
        </View>

        <View style={[styles.infoBox, { paddingBottom: "15px", width: "50%" }]}>
          <View style={[styles.infoBoxText, { flex: 1 }]}>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Section(s):</Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                01/08/24 ( up to addendum 02)
              </Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>Alternates</Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>N/A</Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Per Plans and Specs:
              </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>N/A</Text>
            </View>
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Bond Included:
              </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                N/A (Add 2.5%)
              </Text>
            </View>{" "}
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Prevailing (Union) Wages:
              </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>N/A</Text>
            </View>{" "}
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>
                Taxes & Insurance:
              </Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>YES</Text>
            </View>{" "}
            <View style={styles.infoBox}>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>SBE:</Text>
              <Text style={[styles.infoBoxText, { flex: 1 }]}>YES</Text>
            </View>
          </View>
        </View>
        <View style={styles.centerText}>
          <Text>Total base bid amount: $50,480.00</Text>
        </View>

        <Svg height="10" width="100%" style={{ margin: "30px 0 20px 0" }}>
          <Line
            x1="0"
            y1="5"
            x2="580"
            y2="5"
            strokeWidth={2}
            stroke="rgb(0,0,0)"
          />
        </Svg>
      </Page>
      <Page size="A4" style={styles.page2}>
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Page Header */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <View fixed style={styles.pageHeader}>
          <Image
            // style={{ width: "200px", height: "100px" }}
            src={BrandLogo}
            fixed
          />
          <View style={{ paddingTop: 8 }}>
            <Text style={{ marginTop: 2 }}>Mailing Address:</Text>
            <Text style={{ marginTop: 2 }}>5660 Strand Court, Suite A18,</Text>
            <Text style={{ marginTop: 2 }}>Naples, Florida 34110</Text>
            <Text style={{ marginTop: 2 }}>
              info@coastalpaintingcontractors.com
            </Text>
            <Text style={{ marginTop: 2 }}>+1 (844) 992-4272</Text>
          </View>
        </View>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/*Inclusions         */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <View style={styles.titleBar2}>
          <Text>Inclusion&apos;s</Text>
        </View>
        <View style={{ marginBottom: "20px" }}>
          <Text>
            painting of the interior and the exterior of a one story building ,
            total gross area is approximately 9,750
          </Text>
        </View>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Interior Painting  */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {interiorArr?.length > 0 && (
          <>
            <View style={tableStyles.tableRowHeader}>
              <Text
                style={[
                  tableStyles.tableCellHeader,
                  { flex: 3, textAlign: "left" },
                ]}
              >
                09 9123 - Interior Painting :
              </Text>
              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}></Text>

              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                {intSum > 0 && "$" + intSum?.toLocaleString()}
              </Text>
            </View>
            {interiorArr.map((row, id) => (
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.customKey}
                </Text>
                <Text
                  style={[
                    tableStyles.tableCell,
                    {
                      flex: 3,
                      borderRight: "2px solid black",
                      textAlign: "left",
                    },
                  ]}
                >
                  <Html stylesheet={stylesheet} style={{ fontSize: 8 }}>
                    {row?.description}
                  </Html>
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.price ? "$" + row?.price?.toLocaleString() : null}
                </Text>
              </View>
            ))}
          </>
        )}

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Exterior Painting */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {exteriorArr?.length > 0 && (
          <>
            <View style={tableStyles.tableRowHeader}>
              <Text
                style={[
                  tableStyles.tableCellHeader,
                  { flex: 3, textAlign: "left" },
                ]}
              >
                09 9113 - Exterior Painting :
              </Text>
              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}></Text>
              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                {extSum > 0 && "$" + extSum?.toLocaleString()}
              </Text>
            </View>
            {exteriorArr?.map((row, id) => (
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.customKey}
                </Text>
                <Text
                  style={[
                    tableStyles.tableCell,
                    {
                      flex: 3,
                      borderRight: "2px solid black",
                      textAlign: "left",
                    },
                  ]}
                >
                  {" "}
                  <Html style={{ fontSize: 8 }}>{row?.description}</Html>
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.price ? "$" + row?.price?.toLocaleString() : null}
                </Text>
              </View>
            ))}
          </>
        )}

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Alternates */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {alternatesArr?.length > 0 && (
          <>
            <View style={tableStyles.tableRowHeader}>
              <Text
                style={[
                  tableStyles.tableCellHeader,
                  { flex: 3, textAlign: "left" },
                ]}
              >
                Alternates :
              </Text>
              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}></Text>

              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                {altSum > 0 && "$" + altSum?.toLocaleString()}
              </Text>
            </View>
            {alternatesArr?.map((row, id) => (
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.customKey}
                </Text>
                <Text
                  style={[
                    tableStyles.tableCell,
                    {
                      flex: 3,
                      borderRight: "2px solid black",
                      textAlign: "left",
                    },
                  ]}
                >
                  <Html style={{ fontSize: 8 }}>{row?.description}</Html>
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.price ? "$" + row?.price?.toLocaleString() : null}
                </Text>
              </View>
            ))}
          </>
        )}

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/* Exclusions */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        {exclusionsArr?.length > 0 && (
          <>
            <View style={tableStyles.tableRowHeader}>
              <Text
                style={[
                  tableStyles.tableCellHeader,
                  { flex: 3, textAlign: "left" },
                ]}
              >
                Exclusions :
              </Text>
              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}></Text>

              <Text style={[tableStyles.tableCellHeader, { flex: 1 }]}>
                {/* {excSum > 0 && "$" + excSum?.toLocaleString()} */}
              </Text>
            </View>
            {exclusionsArr?.map((row, id) => (
              <View key={id} style={tableStyles.tableRow}>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.customKey}
                </Text>
                <Text
                  style={[
                    tableStyles.tableCell,
                    {
                      flex: 3,
                      borderRight: "2px solid black",
                      textAlign: "left",
                    },
                  ]}
                >
                  <Html style={{ fontSize: 8 }}>{row?.description}</Html>
                </Text>
                <Text style={[tableStyles.tableCell, { flex: 1 }]}>
                  {row?.price ? "$" + row?.price?.toLocaleString() : null}
                </Text>
              </View>
            ))}
          </>
        )}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/*  General Conditions */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <View>
          <Text style={[styles.leftTextUnderline, { margin: "5px 0 20px" }]}>
            General Conditions
          </Text>
          <Text style={styles.pointsMargin}>
            1. The Contractor will reduce any held retainage by 50%, at 90%
            completion, at which time said reduction shall be paid in full to
            Coastal Painting Contractors. At such time, if the progress of Work
            is satisfactory to the Owner and{" "}
          </Text>
          <Text style={styles.pointsMargin}>
            2. Contractor, remaining progress payments may be made in full:
            provided, however, that the retainage previously accumulated may be
            held until final completion
          </Text>
          <Text style={styles.pointsMargin}>
            3. At Contractor’s request of overtime work, the overtime premium
            will be charged and based on the Labor Rate below. Overtime hours
            will be tracked on daily tickets, and shall become a billable item
            upon signature of Contractor’s authorized representative.
          </Text>
          <Text style={styles.pointsMargin}>
            4. All change work will be priced out in either a Lump Sum amount or
            at a Time & Material rate;
          </Text>
          <Text style={[styles.leftTextUnderline, { margin: "15px 0 10px" }]}>
            4. All change work will be priced out in either a Lump Sum amount or
            at a Time & Material rate;
          </Text>
          <Text>
            Coastal Painting Contractors Inc., (CPC) when performing T&M work,
            will be billed at the following rates
          </Text>
          {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* Rates */}
          {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          <View style={tableStyles.tableRow}>
            <Text
              style={[
                tableStyles.rateTableCellHeader,
                { flex: 3, textAlign: "left" },
              ]}
            ></Text>
            <Text
              style={[
                tableStyles.rateTableCellHeader,
                { flex: 1, borderBottom: "1px solid black" },
              ]}
            >
              Straight Time:
            </Text>

            <Text
              style={[
                tableStyles.rateTableCellHeader,
                { flex: 1, borderBottom: "1px solid black" },
              ]}
            >
              Double Time:
            </Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text
              style={[
                tableStyles.rateTableCell,
                { flex: 3, textAlign: "left" },
              ]}
            >
              Journeyman Painter:
            </Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>$55</Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>$110</Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text
              style={[
                tableStyles.rateTableCell,
                { flex: 3, textAlign: "left" },
              ]}
            >
              Foreman Painter
            </Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>$65</Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>$130</Text>
          </View>{" "}
          <View style={tableStyles.tableRow}>
            <Text
              style={[
                tableStyles.rateTableCell,
                { flex: 3, textAlign: "left" },
              ]}
            >
              Wall-covering Journeyman
            </Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>$95</Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>$190</Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text
              style={[
                tableStyles.rateTableCell,
                { flex: 3, textAlign: "left" },
              ]}
            >
              Our minimum charge is four (4) hours of work.
            </Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}></Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}></Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text
              style={[
                tableStyles.rateTableCellHeader,
                { flex: 3, textAlign: "left" },
              ]}
            ></Text>
            <Text
              style={[
                tableStyles.rateTableCellHeader,
                { flex: 1, borderBottom: "1px solid black" },
              ]}
            >
              Small pump
            </Text>

            <Text
              style={[
                tableStyles.rateTableCellHeader,
                { flex: 1, borderBottom: "1px solid black" },
              ]}
            >
              Large pump
            </Text>
          </View>
          <View style={tableStyles.tableRow}>
            <Text
              style={[
                tableStyles.rateTableCell,
                { flex: 3, textAlign: "left" },
              ]}
            >
              Sprayers Rates
            </Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>
              $65 Day
            </Text>
            <Text style={[tableStyles.rateTableCell, { flex: 1 }]}>
              $110 Day
            </Text>
          </View>
          <Text>
            If at any time during the duration of the project that signed T&M
            tickets performed by CPC amount to 10% or greater of the base
            contract value or if the work performed by CPC on signed T&M tickets
            are 45 days or older, CPC reserves the right to refuse to perform
            additional work outside of the scope of their executed contract
            until the Contractor agrees to allow CPC to bill for all additional
            work performed to date by CPC.
          </Text>
          <Text style={styles.leftTextUnderline}>
            Materials = Cost plus 10%, OH&P = 10% and Equipment rental = Cost
            plus 10%.
          </Text>
          <Text style={styles.pointsMargin}>
            5. Signed T&M work tickets are to be an acknowledgement of work
            performed, acceptance of hours, and materials used. Signed T&M work
            tickets shall become a billable item upon signature of Contractor’s
            authorized representative.
          </Text>
          <Text style={styles.pointsMargin}>
            6. This Bid Proposal pricing will be held firm for 60 days from date
            of Proposal.
          </Text>
          <Text style={styles.pointsMargin}>
            7. Payment Terms are Net 30 days from date of Invoice.
          </Text>
          <Text style={styles.pointsMargin}>
            8. Specialty ordered materials over $5,000.00 will be billed at time
            of purchase, with proof of said purchase.
          </Text>
          <Text style={styles.pointsMargin}>
            9. The work performed will be in accordance with PDCA (painting &
            decorating contractors of America) standards.
          </Text>
          <Text style={styles.pointsMargin}>10. Standard Wages and Taxes</Text>
          <Text style={styles.pointsMargin}>
            11. This Bid Proposal shall become part of the Contract if Coastal
            Painting Contractors, Inc. is awarded this project. Failure to
            incorporate this proposal into the contract in its entirety, shall
            be grounds for refusal to execute a contract, unless negotiated
            otherwise by both parties.
          </Text>
        </View>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {/*  General Exclusions: */}
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        <View style={{ marginBottom: 15 }}>
          <Text style={styles.leftTextUnderline}>General Exclusions:</Text>
          <Text style={styles.pointsMargin}>1. Obtaining Permits</Text>
          <Text style={styles.pointsMargin}>2. City Sales Tax</Text>
          <Text style={styles.pointsMargin}>
            3. Touch-up and repair due to trade damage to our work that IS
            CAUSED by others.
          </Text>
          <Text style={styles.pointsMargin}>
            4. Jobsite Utilities (i.e., Sanitary Services, Power, Pressurized
            Water, Heat, Lights, Dumpster & Wash out area)
          </Text>
          <Text style={styles.pointsMargin}>
            5. More than one Owner, Engineer or Architect punch list.
          </Text>
          <Text style={styles.pointsMargin}>
            6. Multiple mobilizations, nights, weekends & overtime work
          </Text>
          <Text style={styles.pointsMargin}>
            7. Caulking of Painted to Non-Painted surfaces / Dissimilar surfaces
          </Text>
          <Text style={styles.pointsMargin}>
            8. Masking of surface appurtenances, including electrical plates,
            hardware, light fixture trim, escutcheons, and fittings, prior to
            preparing surfaces or finishing
          </Text>
          <Text style={styles.pointsMargin}>
            9. High Performance Caulking / Control Joints / Exterior Sealants
          </Text>
          <Text style={styles.pointsMargin}>
            10. Painting or Staining of Architectural Woodwork, Millwork,
            Cabinets, Trim
          </Text>
          <Text style={styles.pointsMargin}>
            11. MEP Equipment, Color coding and pipe identification
          </Text>
          <Text style={styles.pointsMargin}>
            12. Metal Paneling, RTUs, Roof Piping & ductwork
          </Text>
          <Text style={styles.pointsMargin}>
            13. Painting of Prefinished or Factory finished items
          </Text>
          <Text style={styles.pointsMargin}>
            14. Painting of any Aluminum surfaces
          </Text>
          <Text style={styles.pointsMargin}>
            15. Painting of any Wood Clad & Aluminum Windows
          </Text>
          <Text style={styles.pointsMargin}>
            16. Traffic coatings, Pavement Markings, floor striping
          </Text>
          <Text style={styles.pointsMargin}>
            17. ACT, wood floors, Traffic Control
          </Text>
          <Text style={styles.pointsMargin}>
            18. Liquidated Damages, Cost of Bond (2.5% if required)
          </Text>
        </View>

        <View>
          <Text style={styles.pointsMargin}>
            1. Drywall to be prepared by others and “Ready for Paint”
          </Text>
          <Text style={styles.pointsMargin}>
            2. Primer and first finish coat will be applied in sequence prior to
            the installation of any base, trim, outlet covers, ACT grid, tile
            and flooring
          </Text>
          <Text style={styles.pointsMargin}>
            3. Coastal Painting Contractors utilizes an outsource safety company
            (TBD.) for all its site-specific safety plans, and safety issues.
          </Text>
          <Text style={styles.pointsMargin}>
            4. State and County taxes are included within this proposal. (City
            tax is excluded – See General Exclusion #2.)
          </Text>
          <Text style={styles.pointsMargin}>
            Thank you for providing us with the opportunity to provide pricing
            on this project. Please feel free to contact me with any questions
            that you may have at (844) 922- 4272.
          </Text>
        </View>

        <View style={styles.signatureBox}>
          <View style={{ alignItems: "center", gap: 4 }}>
            <Text>Accepted by</Text>
            <Text style={{ fontFamily: "Calibri", fontWeight: "bold" }}>
              Coastal Painting Contractors , Inc
            </Text>
            <Text
              style={{
                minWidth: "100px",
                maxWidth: "100px",
                borderBottom: "1px solid black",
                marginTop: 8,
              }}
            ></Text>
            <Text>Signature</Text>
          </View>
          <View style={{ alignItems: "center", gap: 4 }}>
            <Text>Submitted to</Text>
            <Text style={{ fontFamily: "Calibri", fontWeight: "bold" }}>
              Curran Young Construction
            </Text>
            <Text
              style={{
                minWidth: "100px",
                maxWidth: "100px",
                borderBottom: "1px solid black",
                marginTop: 8,
              }}
            ></Text>
            <Text>Signature</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

ProposalPrintNew.propTypes = {
  exclusionsArr: PropTypes.any,
  interiorArr: PropTypes.any,
  exteriorArr: PropTypes.any,
  alternatesArr: PropTypes.any,
  estimationData: PropTypes.any,
  intSum: PropTypes.any,
  altSum: PropTypes.any,
  extSum: PropTypes.any,
  contact: PropTypes.any,

  opportunityData: PropTypes.any,
};

export default ProposalPrintNew;
