import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoopIcon from "@mui/icons-material/Loop";
import SearchIcon from "@mui/icons-material/Search";

const DateFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onReset,
  onClose,
  callApi,
}) => {
  const { dateModalDivs } = useStyles();

  const [dateChanged, setdateChanged] = useState(false);

  useEffect(() => {
    if (dateChanged) {
      callApi();
      setdateChanged(false);
    }
  }, [dateChanged]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: { xs: 370, sm: "fit-content", md: 400, xl: "fit-content" },
        position: "absolute",
        top: "9%",
        zIndex: "999",
        padding: "15px",
        display: "flex",
        overflow: "auto",
        boxShadow:
          " 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          marginRight: "5px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <IconButton size="small" color="error">
                  <LoopIcon fontSize="small" onClick={onReset} />
                </IconButton>

                <TextField
                  label="Start Date"
                  type="date"
                  size="small"
                  inputProps={{ max: endDate }}
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />

                <Box className="text-black">-</Box>

                <TextField
                  label="End Date"
                  type="date"
                  size="small"
                  inputProps={{ min: startDate }}
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />

                <IconButton size="small" onClick={callApi}>
                  <SearchIcon />
                </IconButton>
              </Box>

              <Box
                className={dateModalDivs}
                onClick={() => {
                  setStartDate(moment().format("YYYY-MM-DD"));
                  setEndDate(moment().format("YYYY-MM-DD"));
                  setdateChanged(true);
                }}
              >
                Today
              </Box>
              <Box
                className={dateModalDivs}
                onClick={() => {
                  setStartDate(
                    moment().subtract(6, "days").format("YYYY-MM-DD")
                  );
                  setEndDate(moment().format("YYYY-MM-DD"));
                  setdateChanged(true);
                }}
              >
                Last 7 days
              </Box>
              <Box
                className={dateModalDivs}
                onClick={() => {
                  setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
                  setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
                  setdateChanged(true);
                }}
              >
                This Month
              </Box>
              <Box
                className={dateModalDivs}
                onClick={() => {
                  setStartDate(
                    moment()
                      .subtract(1, "months")
                      .startOf("month")
                      .format("YYYY-MM-DD")
                  );
                  setEndDate(
                    moment()
                      .subtract(1, "months")
                      .endOf("month")
                      .format("YYYY-MM-DD")
                  );
                  setdateChanged(true);
                }}
              >
                Past Month
              </Box>
              <Box
                className={dateModalDivs}
                onClick={() => {
                  setStartDate(
                    moment().subtract(3, "months").format("YYYY-MM-DD")
                  );
                  setEndDate(moment().format("YYYY-MM-DD"));
                  setdateChanged(true);
                }}
              >
                Past 3 Months
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  onClick={onClose}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DateFilter;
DateFilter.propTypes = {
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
  callApi: PropTypes.func,
};

const useStyles = makeStyles({
  dateModalDivs: {
    cursor: "pointer",
    marginTop: "5px",
    width: "100%",
    height: "30px",
    borderRadius: "5px",
    border: "1px solid #707070",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "#f4f7fe",
    },
  },
});
