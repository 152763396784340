import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  gridClasses,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import {
  fetchAllOpportunityByStatus,
  fetchOpportunityDocumentsData,
} from "../../../redux/slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import { useNavigate, useParams } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import DocumentsDialog from "../../../components/DocumentsDialog/DocumentsDialog";
import { ChangeOpportunityStatusBulk } from "../../../Apis/Opportunity/opportunityApis";
import { SnackBarContext } from "../../../context/SnackBarContext";
import NoteViewDialog from "../../../components/AddNoteDialog/NoteViewDialog";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

function EstimationsByStatus() {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [apiCalling, setApiCalling] = useState(false);
  const [showDocumentDialog, setShowDocumentDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [loading, setloading] = useState(false);
  const [cachedRows, setCachedRows] = useState({});
  const [pageState, setPageState] = useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 100,
  });

  const { setsnackBarData } = useContext(SnackBarContext);

  const navigate = useNavigate();
  const { screen } = useParams();

  const dispatch = useDispatch();
  const { OpportunityDocumentsData, OpportunityQualified, status, TotalCount } =
    useSelector((state) => state?.OpportunitySettings);

  useEffect(() => {
    if (screen === "awaiting-estimate") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "Pending",
          page: pageState.page,
          pageSize: pageState.pageSize,
        })
      );
    } else if (screen === "estimate-in-progress") {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "In Progress",
          page: pageState.page,
          pageSize: pageState.pageSize,
        })
      );
    } else {
      dispatch(
        fetchAllOpportunityByStatus({
          status: "Under Review",
          page: pageState.page,
          pageSize: pageState.pageSize,
        })
      );
    }
  }, [screen]);

  useEffect(() => {
    if (OpportunityQualified) {
      setalldata(OpportunityQualified);
    } else {
      setalldata([]);
    }
  }, [OpportunityQualified]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
            width: "100%",
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          {checkedRows.length ? (
            <Box sx={{ display: "flex", gap: "5px" }}>
              <Button
                onClick={() =>
                  handleChangeStatus(
                    screen === "awaiting-estimate"
                      ? "In Progress"
                      : "Under Review"
                  )
                }
                variant="contained"
                size="small"
                color="success"
              >
                {screen === "awaiting-estimate"
                  ? "Mark In Progress"
                  : "Mark Complete"}
              </Button>
              {screen === "awaiting-estimate" && (
                <Button
                  onClick={() => handleChangeStatus("No Opportunity")}
                  variant="contained"
                  size="small"
                  color="secondary"
                >
                  No Opportunity
                </Button>
              )}
            </Box>
          ) : (
            <Box>
              <GridToolbarExport />
              <GridToolbarFilterButton />
            </Box>
          )}

          <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
            Total Amount: ${totalAmount?.toLocaleString()}
          </Typography>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "sr",
      headerName: "Sr",
      minWidth: 50,
      flex: "auto",
      renderCell: (params) => {
        return (pageState.page - 1) * pageState.pageSize + params.value;
      },
    },
    {
      field: "jobNo",
      headerName: "Job #",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      cellClassName: (params) => {
        switch (params.value) {
          case "On Hold":
            return "status-onHold";
          case "Pending":
            return "status-pending";
          case "In Progress":
            return "status-inProgress";
          case "Open":
            return "status-open";
          case "Lost":
            return "status-lost";
          case "Sold":
            return "status-sold";
          case "No Opportunity":
            return "status-noOpportunity";
          case "Under Review":
            return "status-underReview";
          default:
            return "";
        }
      },
    },
    {
      field: "opportunityValue",
      headerName: "Amount",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              navigate("/companies/form", {
                state: params?.row?.rowData?.company,
              });
            }}
          >
            {params?.row?.company}
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "clientDueDate",
      headerName: "Client Due Date",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        const dateValue = params.value;
        return dateValue ? moment(dateValue, "MM/DD/YYYY").toISOString() : null;
      },
      valueFormatter: (params) => {
        const dateValue = params.value;
        return dateValue
          ? moment(new Date(dateValue), "YYYY-MM-DDTHH:mm:ss").format(
              "MM/DD/YYYY"
            )
          : "N/A";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: "auto",
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Tooltip title="Documents">
              <IconButton
                sx={{ padding: "5px" }}
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  setApiCalling(true);
                  setSelectedRow(params?.row?.rowData);
                  dispatch(
                    fetchOpportunityDocumentsData({
                      opportunityId: params?.row?.rowData?.opportunityId,
                      refName: null,
                      refId: null,
                    })
                  );
                }}
              >
                <ArticleIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Notes">
              <IconButton
                sx={{ padding: "5px" }}
                color="info"
                onClick={() => {
                  setShowNotes(true);
                  setSelectedRow(params?.row?.rowData);
                }}
              >
                <DescriptionOutlinedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  React.useEffect(() => {
    let arr = [];
    let amount = 0;
    if (alldata) {
      alldata?.forEach((item, idx) => {
        amount += item?.opportunityValue;
        return arr.push({
          sr: idx + 1,
          id: item?.opportunityId,
          jobNo: item?.jobNo,
          opportunityValue: item?.opportunityValue
            ? `$${item?.opportunityValue?.toLocaleString()}`
            : "N/A",
          title: item?.title,
          source: item?.opportunitySource?.title,
          description: item?.description,
          contact: item?.contact?.display_name,
          company: item?.company?.name,
          status: item?.status,
          type: item?.type?.title,
          clientDueDate: moment(item?.clientDueDate).format("MM/DD/YYYY"),
          createdAt: moment(item?.createdAt).format("MM/DD/YYYY"),
          updatedAt: moment(item?.updatedAt).format("MM/DD/YYYY"),
          rowData: item,
        });
      });
    }
    setTotalAmount(amount);
    setrows(arr);
  }, [alldata]);

  useEffect(() => {
    if (apiCalling && OpportunityDocumentsData) {
      setApiCalling(false);
      setShowDocumentDialog(true);
    } else {
      setApiCalling(false);
    }
  }, [OpportunityDocumentsData]);

  const handleChangeStatus = async (status) => {
    setloading(true);
    const response = await ChangeOpportunityStatusBulk({
      opportunityIds: checkedRows,
      status: status,
    });
    if (response.snackBarData.type === "success") {
      if (screen === "awaiting-estimate") {
        dispatch(
          fetchAllOpportunityByStatus({
            status: "Pending",
            page: pageState.page,
            pageSize: pageState.pageSize,
          })
        );
      } else {
        dispatch(
          fetchAllOpportunityByStatus({
            status: "In Progress",
            page: pageState.page,
            pageSize: pageState.pageSize,
          })
        );
      }
      setCheckedRows([]);
    }
    setloading(false);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
  };

  useEffect(() => {
    const fetchData = () => {
      setPageState((old) => ({ ...old, isLoading: true }));
      if (cachedRows[pageState.page]) {
        setPageState((old) => ({ ...old, isLoading: false }));
        return;
      } else {
        try {
          if (screen === "awaiting-estimate") {
            dispatch(
              fetchAllOpportunityByStatus({
                status: "Pending",
                page: pageState.page,
                pageSize: pageState.pageSize,
              })
            );
          } else {
            dispatch(
              fetchAllOpportunityByStatus({
                status: "In Progress",
                page: pageState.page,
                pageSize: pageState.pageSize,
              })
            );
          }
        } catch (error) {
          console.error("Failed to fetch opportunities", error);
        } finally {
          setPageState((old) => ({ ...old, isLoading: false }));
        }
      }
    };
    fetchData();
  }, [pageState.page, pageState.pageSize]);

  useEffect(() => {
    setCachedRows((prev) => ({
      ...prev,
      [pageState.page]: rows,
    }));
  }, [rows]);

  const rows1 = cachedRows[pageState.page] || [];

  return (
    <>
      <Box className="data-grid-box">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows1}
          pagination
          paginationMode="server"
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState.pageSize,
                page: pageState.page - 1,
              },
            },
          }}
          checkboxSelection={screen !== "estimate-complete" ? true : false}
          rowSelectionModel={checkedRows}
          onRowSelectionModelChange={(ids) => {
            if (screen !== "estimate-complete") setCheckedRows(ids);
          }}
          onPaginationModelChange={(model) => {
            console.log("model", model);
            setPageState((old) => ({ ...old, page: model.page + 1 }));
          }}
          pageSizeOptions={false}
          rowCount={TotalCount}
          loading={pageState.isLoading}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
        {status === "loading" || loading ? <Loader /> : null}
      </Box>

      <DocumentsDialog
        open={showDocumentDialog}
        onClose={() => {
          setSelectedRow(null);
          setShowDocumentDialog(false);
        }}
        selectedRow={selectedRow}
      />
      {showNotes && (
        <NoteViewDialog
          open={showNotes}
          onClose={() => {
            setShowNotes(false);
            setSelectedRow(null);
          }}
          state={selectedRow}
        />
      )}
    </>
  );
}

export default EstimationsByStatus;
