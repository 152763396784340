import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Triangle } from "react-loader-spinner";

const useStyle = makeStyles(() => {
  return {
    childDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    parent: {
      position: "fixed",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      width: "100vw",
      height: "100vh",
      background: "rgba(0, 0, 0, 0.26)",
      transition: "opacity 500ms",
      padding: " 60px",
      zIndex: 9999,
    },
  };
});
function Loader() {
  const { parent, childDiv } = useStyle();
  return (
    <Box className={parent}>
      <Box className={childDiv}>
        <Triangle
          sx={{ color: (theme) => theme.palette.primary.main }}
          height="100"
          width="100"
          color="#f79c35"
          secondaryColor="#f79c35"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </Box>
    </Box>
  );
}

export default Loader;
