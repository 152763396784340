import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    mainContainer: {
        color: 'red',
    },
}));

function NotFound() {
    const classes = useStyles();

    return (
        <main
            className={classes.mainContainer}
        >
            <h1>Page Not Found!</h1>
        </main>
    );
}

export default NotFound;