import axiosInstance, { axiosInstanceForm } from "../../utils/AxiosInstance";

export const SendProposalApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstanceForm.post(
      "/FollowUps/sendProposal",
      data
    );

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    console.log(error);
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const MarkOpenApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put("Opportunity/BulkOpen", data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
