import axiosInstance from "../../utils/AxiosInstance";

export const SendFollowUpsApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(`/FollowUps/Send`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const CreateFollowUpsApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(`/FollowUps/Create`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
