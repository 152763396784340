import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  RequestQuoteData: null,
  ThirdPartyData: null,

  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
};

export const fetchRequestQuoteData = createAsyncThunk(
  "Quotation/GetByOppurtunityId",
  async (id) => {
    const response = await axiosInstance.get(
      `Quotation/GetByOppurtunityId/${id}`
    );
    return response?.data;
  }
);
export const fetchThirdPartyData = createAsyncThunk(
  "ThirdParty/GetByOppurtunityId",
  async (id) => {
    const response = await axiosInstance.get(
      `ThirdParty/GetByOppurtunityId/${id}`
    );
    return response?.data;
  }
);
export const fetchThirdPartyDataById = createAsyncThunk(
  "ThirdParty/GetAll",
  async (id) => {
    const response = await axiosInstance.get(`ThirdParty/GetById/${id}`);
    return response?.data;
  }
);

const EstimationSlice = createSlice({
  name: "EstimationSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchRequestQuoteData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRequestQuoteData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.RequestQuoteData = action.payload.data;
      })
      .addCase(fetchRequestQuoteData.rejected, (state, action) => {
        state.RequestQuoteData = null;
        state.error = action.error.message;
        state.status = "failed";
      })
      .addCase(fetchThirdPartyData.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchThirdPartyData.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.message;
        state.ThirdPartyData = action.payload.data;
      })
      .addCase(fetchThirdPartyData.rejected, (state, action) => {
        state.ThirdPartyData = null;
        state.error = action.error.message;
        state.status2 = "failed";
      });
  },
});

export default EstimationSlice.reducer;
