import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/AxiosInstance";
import { revertAll } from "../ResetStateAction";

const initialState = {
  TemplateData: null,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchTemplateData = createAsyncThunk(
  "EmailTemplate/GetAll",
  async () => {
    const response = await axiosInstance.get(`EmailTemplate/GetAll`);
    return response?.data;
  }
);

const TemplateSlice = createSlice({
  name: "TemplateSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(fetchTemplateData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTemplateData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.TemplateData = action.payload.data;
      })
      .addCase(fetchTemplateData.rejected, (state, action) => {
        state.TemplateData = null;
        state.error = action.error.message;
        state.status = "failed";
      });
  },
});

export default TemplateSlice.reducer;
