import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { makeStyles } from "@mui/styles";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function Roles({ handleBreadcrumbs }) {
  const { paperBox, paperContentBox, contentBox, textBox, setingText } =
    useStyle();
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ fontWeight: "bold" }}>Roles</Typography>
      <Typography>Manage all your roles in the system.</Typography>
      <Paper className={paperBox} elevation={3}>
        <Box className={paperContentBox}>
          <Box
            onClick={() => {
              navigate("/settings/roles");
              handleBreadcrumbs("Roles", "/settings/roles");
            }}
            className="settingBoxA"
            sx={{ padding: "10px", cursor: "pointer" }}
            id="settings-groups"
          >
            <Box className={contentBox}>
              <WebhookOutlinedIcon fontSize="large" />
              <Box className={textBox}>
                <Typography variant="h6">Roles</Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  Add,edit or delete Users from{" "}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  System
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            onClick={() => {
              navigate("/settings/users");
              handleBreadcrumbs("Users", "/settings/users");
            }}
            sx={{ padding: "10px", cursor: "pointer" }}
            id="settings-groups"
            className="settingBoxA"
          >
            <Box className={contentBox}>
              <GroupOutlinedIcon fontSize="large" />
              <Box className={textBox}>
                <Typography variant="h6">Users</Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  Add,edit or delete Users from{" "}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${setingText} settingBoxAhoverEffect`}
                >
                  System
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
export default Roles;
Roles.propTypes = {
  handleBreadcrumbs: PropTypes.func,
};

const useStyle = makeStyles(() => {
  return {
    paperBox: {
      width: "100%",
      maxWidth: "1280px",
      padding: "20px",
      marginTop: "20px",
    },
    paperContentBox: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      gap: "10px",
    },
    contentBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    textBox: {
      height: "90px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    setingText: {
      transform: "scale(0)",
      height: "0px",
    },
  };
});
