import React, { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { BreadcrumbsContext } from "../../../context/BcrumbsContextProvider";
import LockIcon from "@mui/icons-material/Lock";
import BrandLogo from "../../../Assets/Images/brandLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import PropTypes from "prop-types";
import { useAuth } from "../../../context/useAuth";
import { adminSideBarData } from "../../../Resources/SidebarData";
import theme from "../../../theme/theme";
import { useDispatch } from "react-redux";
import { revertAll } from "../../../redux/slice/ResetStateAction";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const cookies = new Cookies();

function SideDrawer({ drawerOpen, toggleDrawer }) {
  const classes = useStyles();
  const { userRoles } = useAuth() ?? { userRoles: null };
  const [drawerVariant, setDrawerVariant] = useState("temporary");
  const { width } = useWindowDimensions();
  const [openMenu, setOpenMenu] = useState({});

  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signup } = useAuth();

  const handleClick = (name) => {
    setOpenMenu({ ...openMenu, [name]: !openMenu[name] });
  };

  useEffect(() => {
    const openObj = {};
    adminSideBarData?.forEach((item) => {
      if (item?.open !== undefined) {
        openObj[item?.name] = item?.open;
      }
    });
    setOpenMenu(openObj);
  }, [adminSideBarData?.length]);

  useEffect(() => {
    if (width < 1200) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [width]);

  const navLinkStyles = ({ isActive }) => {
    if (isActive) {
      return {
        backgroundImage: "linear-gradient(to right top, #f69220, #fdcb91)",
      };
    }
  };

  const handleBreadcrumbs = (name, url) => {
    if (name === "Dashboard" || url === "/") {
      setBreadcrumbsItems([
        {
          id: breadcrumbsItems.length,
          url: "/",
          name: "Dashboard",
        },
        {
          id: breadcrumbsItems.length,
          url: "/",
          name: "Home",
        },
      ]);
    } else {
      setBreadcrumbsItems([
        {
          id: breadcrumbsItems.length,
          url: "/",
          name: "Dashboard",
        },
        {
          id: breadcrumbsItems.length,
          url: url,
          name: name,
        },
      ]);
    }
  };

  const handleLogout = () => {
    cookies.remove("access_token");
    cookies.remove("LoginId");
    localStorage.clear();
    signup(null);
    setBreadcrumbsItems([]);
    dispatch(revertAll());
    navigate("/sigin", { replace: true });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 288 }}
      role="presentation"
      className={classes.drawerMainContainer}
    >
      <Box className={classes.drawerHeadContainer}>
        <Link
          className={classes.drawerHeadLink}
          to="/"
          onClick={() => handleBreadcrumbs("Dashboard", "/")}
        >
          <img
            src={BrandLogo}
            alt="logo"
            className={classes.brandLogo}
          />

          {/* <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              lineHeight: 1.625,
              fontWeight: 600,
              letterSpacing: 0,
            }}
          >
            CPC
          </Typography> */}
        </Link>

        <IconButton
          className={classes.closeBtn}
          onClick={() => toggleDrawer(false)}
          sx={{
            display: { lg: "none" },
          }}
        >
          <CloseIcon sx={{ fontSize: "inherit" }} />
        </IconButton>
      </Box>

      <Divider
        sx={{
          backgroundColor: "rgb(96 125 139 /1)",
        }}
      />

      <Box className={classes.drawerList}>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          {adminSideBarData.map((item, index) => {
            if (item?.subItems?.length === 0) {
              if (item?.roleName) {
                return (
                  userRoles?.find(
                    (screen) => screen?.key === item?.roleName
                  ) && (
                    <ListItem
                      key={index}
                      disablePadding
                    >
                      <NavLink
                        to={item?.url}
                        className={classes.listLink}
                        style={navLinkStyles}
                        onClick={() => handleBreadcrumbs(item.name, item.url)}
                      >
                        <ListItemButton className={classes.listItemBtn}>
                          <ListItemIcon
                            sx={{
                              width: "1.5rem",
                              height: "1.5rem",
                              color: "inherit",
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            sx={{
                              fontSize: "1rem !important",
                              fontWeight: "500 !important",
                              lineHeight: "1.625 !important",
                            }}
                          >
                            {item.name}
                          </ListItemText>
                        </ListItemButton>
                      </NavLink>
                    </ListItem>
                  )
                );
              }
            } else {
              if (item?.roleName) {
                return (
                  userRoles?.find(
                    (screen) => screen?.key === item?.roleName
                  ) && (
                    <Box key={index}>
                      <ListItem disablePadding>
                        <NavLink
                          // to={item?.url}
                          // style={navLinkStyles}
                          className={classes.listLink}
                          style={{
                            backgroundColor: location.pathname?.includes(
                              item?.url
                            )
                              ? "#546c78"
                              : "inherit",
                          }}
                          onClick={() => {
                            handleClick(item?.name);
                            // handleBreadcrumbs(item.name, item.url);
                          }}
                        >
                          <ListItemButton className={classes.listItemBtn}>
                            <ListItemIcon
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                color: "inherit",
                              }}
                            >
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText
                              sx={{
                                fontSize: "1rem !important",
                                fontWeight: "500 !important",
                                lineHeight: "1.625 !important",
                              }}
                            >
                              {item.name}
                            </ListItemText>
                            {openMenu[item?.name] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItemButton>
                        </NavLink>
                      </ListItem>

                      <Collapse
                        in={openMenu[item?.name]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List
                          component="div"
                          disablePadding
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            padding: "5px 5px 5px 35px",
                          }}
                        >
                          {item?.subItems?.map(
                            (subitem, idx) =>
                              subitem?.roleName &&
                              userRoles?.find(
                                (screen) => screen?.key === subitem?.roleName
                              ) && (
                                <NavLink
                                  key={idx}
                                  to={subitem?.url}
                                  style={navLinkStyles}
                                  className={classes.listLink}
                                  onClick={() => {
                                    handleBreadcrumbs(
                                      subitem?.name,
                                      subitem?.url
                                    );
                                  }}
                                >
                                  <ListItemButton
                                    className={classes.subListItemBtn}
                                  >
                                    {/* <ListItemIcon
                                      sx={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        color: "inherit",
                                      }}
                                    >
                                      {subitem?.icon}
                                    </ListItemIcon> */}
                                    <ListItemText sx={{ padding: 0 }}>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          padding: 0,
                                        }}
                                      >
                                        {subitem?.name}
                                      </Typography>
                                    </ListItemText>
                                  </ListItemButton>
                                </NavLink>
                              )
                          )}
                        </List>
                      </Collapse>
                    </Box>
                  )
                );
              }
            }
          })}

          <ListItem
            sx={{
              margin: "1rem 0 0.5rem",
            }}
          >
            <Typography
              sx={{
                color: "rgb(255 255 255 /.75)",
                lineHeight: 1.5,
                fontWeight: 900,
                fontSize: ".875rem",
                textTransform: "uppercase",
              }}
            >
              Auth Pages
            </Typography>
          </ListItem>

          <ListItem disablePadding>
            <NavLink
              to={"/signin"}
              className={classes.listLink}
              style={navLinkStyles}
              onClick={handleLogout}
            >
              <ListItemButton className={classes.listItemBtn}>
                <ListItemIcon
                  sx={{
                    width: "1.5rem",
                    height: "1.5rem",
                    color: "inherit",
                  }}
                >
                  <LockIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    fontSize: "1rem !important",
                    fontWeight: "500 !important",
                    lineHeight: "1.625 !important",
                  }}
                >
                  Log Out
                </ListItemText>
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Drawer
      variant={drawerVariant}
      anchor={"left"}
      open={drawerOpen}
      onClose={() => toggleDrawer(false)}
      elevation={0}
      PaperProps={{
        sx: {
          margin: "15px 0 30px 15px",
          borderRadius: "12px",
          height: "calc(100vh - 30px)",
        },
      }}
    >
      {list("left")}
    </Drawer>
  );
}

export default SideDrawer;
SideDrawer.propTypes = {
  toggleDrawer: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

const useStyles = makeStyles(() => ({
  listLink: {
    textDecoration: "none",
    color: "inherit",
    display: "block",
    width: "100%",
    borderRadius: "0.5rem",
    "&:hover": {
      backgroundColor: "#ffffff1a",
    },
  },

  link: {
    textDecoration: "none",
  },

  drawerMainContainer: {
    height: "100vh",
    backgroundImage: "linear-gradient(to bottom right, #37474f , #263238)",
    color: "rgb(255 255 255/1)",
    overflow: "hidden",
  },

  drawerHeadContainer: {
    maxHeight: "100px",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },

  drawerHeadLink: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    color: "rgb(255 255 255/1)",
  },

  brandLogo: {
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "cover",
  },

  closeBtn: {
    color: "#fff !important",
    fontWeight: "500 !important",
    fontSize: "2rem !important",
    lineHeight: "1rem !important",
    borderRadius: "0.5rem !important",
    maxWidth: "40px",
    height: "2.5rem",
    "&:hover": {
      color: "rgb(96 125 139 /1) !important",
    },
  },

  drawerList: {
    maxHeight: "calc(100% - 110px)",
    padding: "16px",
    overflowY: "auto",
  },

  breadcrumbsLink: {
    fontSize: ".875rem",
    lineHeight: "1.5",
    color: "rgb(38 50 56 /.5)",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },

  listItemBtn: {
    paddingBlock: "5px !important",
  },

  subListItemBtn: {
    paddingBlock: "3px !important",
  },
}));
