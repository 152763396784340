import { configureStore } from "@reduxjs/toolkit";
import DashboardSlice from "./slice/DashboardSlice/DashboardSlice";
import RolesSlice from "./slice/RolesSlice/RolesSlice";
import UsersSlice from "./slice/UsersSlice/UsersSlice";
import OpportunitySettingsSlice from "./slice/OpportunitySettingsSlice/OpportunitySettingsSlice";
import ContactSlice from "./slice/ContactsSlice/ContactSlice";
import CompanySlice from "./slice/CompanySlice/CompanySlice";
import EmailTemplateSlice from "./slice/EmailTemplateSlice/EmailTemplateSlice";
import EstimationSlice from "./slice/EstimationSlice/EstimationSlice";
import ChangeReqSlice from "./slice/ChangeReqSlice/ChangeReqSlice";
import FollowUpSlice from "./slice/FollowUpSlice/FollowUpSlice";

const store = configureStore({
  reducer: {
    Dashboard: DashboardSlice,
    Roles: RolesSlice,
    Users: UsersSlice,
    OpportunitySettings: OpportunitySettingsSlice,
    Contacts: ContactSlice,
    Companies: CompanySlice,
    EmailTemplate: EmailTemplateSlice,
    Estimations: EstimationSlice,
    ChangeReq: ChangeReqSlice,
    FollowUp: FollowUpSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
