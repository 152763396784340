import { Box, gridClasses } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import ArticleIcon from "@mui/icons-material/Article";
import PropTypes from "prop-types";

function DocumentsGrid({ files }) {
  const [alldata, setalldata] = useState([]);
  const [rows, setrows] = useState([]);

  useEffect(() => {
    if (files) {
      setalldata(files);
    } else {
      setalldata([]);
    }
  }, [files]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
            padding: "10px",
            width: "100%",
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Box>
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Box>
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "Sr", minWidth: 50, flex: "auto" },
    {
      field: "fileType",
      headerName: "File Type",
      minWidth: 150,
      flex: 1,
    },

    {
      field: "documentsUrl",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              cursor: "pointer",
              color: "#551a8b",
              textDecoration: "underline",
            }}
            onClick={() => {
              window.open(params?.row?.documentsUrl, "_blank");
            }}
          >
            {params?.row?.documentsUrl ? "Open" : ""}
          </Box>
        );
      },
    },
    {
      field: "createdByUser",
      headerName: "Created By",
      minWidth: 155,
      flex: 1,
      renderCell: (params) => params?.row?.createdByUser?.name,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 155,
      flex: 1,
      valueGetter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY"
        );
      },
      valueFormatter: (params) => {
        return moment(new Date(params.value), "YYYY-MM-DDTHH:mm:ss a")?.format(
          "MM/DD/YYYY"
        );
      },
    },

    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   minWidth: 70,
    //   flex: "auto",
    //   renderCell: () => {
    //     return (
    //       <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
    //         <Tooltip title="Documents">
    //           <IconButton sx={{ padding: "5px" }} color="secondary">
    //             <ArticleIcon sx={{ fontSize: "1rem" }} />
    //           </IconButton>
    //         </Tooltip>
    //       </Box>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    let arr = [];
    if (alldata) {
      alldata?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          ...item,
        });
      });
    }
    setrows(arr);
  }, [alldata]);

  return (
    <>
      <Box className="data-grid-box-doc">
        <DataGrid
          className="data_grid_parent_div"
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          pagination
          components={{
            toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
    </>
  );
}

export default DocumentsGrid;
DocumentsGrid.propTypes = {
  files: PropTypes.any,
};
