import { Alert, Snackbar } from "@mui/material";
import React, { useContext } from "react";
import { SnackBarContext } from "../../context/SnackBarContext";

const SnackBar = () => {
  const { snackBarData, setsnackBarData } = useContext(SnackBarContext);
  const handleClose = (e) => {
    e && e.stopPropagation();
    setsnackBarData({
      openToast: false,
      type: "",
      message: "",
    });
  };
  return (
    <Snackbar
      open={snackBarData?.openToast}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={snackBarData?.type || "success"}
        sx={{ width: "100%" }}
      >
        {snackBarData?.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
