import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import AppDialog from "../../common/AppDialog/AppDialog";

function ProductInfoDialog({ open, onClose, selectedRow }) {
  return (
    <>
      <AppDialog
        open={open}
        onClose={onClose}
        title="Products"
        minHeight="auto"
      >
        <Box my={2}>
          <TableContainer
            component={Box}
            sx={{ marginTop: "10px", maxHeight: "300px" }}
          >
            <Table
              sx={{ minWidth: "100%" }}
              aria-label="simple table"
            >
              <TableHead className="table-head">
                <TableRow>
                  <TableCell>Sr. </TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRow?.quotationLineItems?.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item?.productName}</TableCell>
                      <TableCell>{item?.quantity}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AppDialog>
    </>
  );
}

export default ProductInfoDialog;
ProductInfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  selectedRow: PropTypes.any,
};
