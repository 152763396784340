import axiosInstance from "../../utils/AxiosInstance";

export const AddNotesApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post(`/Notes/Create`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const UpdateNotesApi = async (id, data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.put(`/Notes/Update/${id}`, data);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const DeleteNotesApi = async (id) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/Notes/Delete/${id}`);

    snackBarData = {
      type: response?.data?.success ? "success" : "error",
      message: response?.data?.msg,
      openToast: true,
    };

    return { ...response?.data, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.msg,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
