import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import AppDialog from "../common/AppDialog/AppDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddNoteDialog from "./AddNoteDialog";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes } from "../../redux/slice/ContactsSlice/ContactSlice";
import { SnackBarContext } from "../../context/SnackBarContext";
import { DeleteNotesApi } from "../../Apis/Notes/NotesApi";
import AppConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import Loader from "../Loader/Loader";
import moment from "moment";

function NoteViewDialog({ open, onClose, state }) {
  const { setsnackBarData } = useContext(SnackBarContext);

  const [showAddNotesDialog, setShowAddNotesDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { Notes, status } = useSelector((state) => state?.Contacts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotes({ opportunityId: state?.opportunityId }));
  }, [dispatch, state]);
  const deleteNote = async () => {
    setShowConfirmation(false);

    const response = await DeleteNotesApi(selectedRow?.notesId);

    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);
    if (response?.success) {
      dispatch(
        fetchNotes({
          opportunityId: state?.opportunityId,
        })
      );
      setSelectedRow(null);
    }
  };
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title={"View Notes"}
      minHeight="auto"
      maxWidth="md"
    >
      <Box sx={{ mt: 2 }}>
        <TableContainer
          component={Box}
          sx={{ marginTop: "5px", maxHeight: "300px" }}
        >
          <Table sx={{ minWidth: "100%" }} aria-label="simple table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell width={"5%"}>Sr.</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell width={"20%"}>Created By</TableCell>
                <TableCell width={"20%"}>Created At</TableCell>
                <TableCell>
                  {" "}
                  <Button
                    variant="contained"
                    sx={{ float: "right" }}
                    size="small"
                    onClick={() => {
                      setShowAddNotesDialog(true);
                    }}
                  >
                    Add New
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Notes?.data?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>{index + 1}</TableCell>

                  <TableCell
                    dangerouslySetInnerHTML={{ __html: item?.note }}
                  ></TableCell>
                  <TableCell>{item?.createdByUser?.name || "N/A"}</TableCell>
                  <TableCell>
                    {moment(item?.createdAt, "YYYY-MM-DDTHH:mm:ss a")?.format(
                      "MM/DD/YYYY"
                    ) || "N/A"}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        float: "right",
                        mr: 2,
                      }}
                    >
                      <Tooltip title="Edit">
                        <IconButton
                          hidden
                          sx={{ padding: "5px", display: "none" }}
                          color="info"
                          onClick={() => {
                            setSelectedRow(item);
                            setShowAddNotesDialog(true);
                          }}
                        >
                          <EditIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ padding: "5px", display: "none" }}
                          color="error"
                          onClick={() => {
                            setSelectedRow(item);
                            setShowConfirmation(true);
                          }}
                        >
                          <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {showAddNotesDialog && (
        <AddNoteDialog
          open={showAddNotesDialog}
          onClose={(success) => {
            if (success)
              dispatch(
                fetchNotes({
                  opportunityId: state?.opportunityId,
                })
              );
            setShowAddNotesDialog(false);
            setSelectedRow(null);
          }}
          opportunityId={state?.opportunityId}
          updateData={selectedRow}
        />
      )}
      {showConfirmation && (
        <AppConfirmationDialog
          open={showConfirmation}
          onDeny={() => {
            setSelectedRow(null);
            setShowConfirmation(false);
          }}
          onConfirm={() => {
            deleteNote();
          }}
          iconContainerColor="#fbeaea"
          icon={
            <DeleteOutlineIcon
              fontSize="small"
              sx={{ color: (theme) => theme?.palette?.error?.main }}
            />
          }
          dialogTitle="Delete Note"
          title={`Are you sure, you want to delete selected note?`}
          successText="Yes"
          successBtnColor="error"
          cancelText="No"
        />
      )}
      {status === "loading" ? <Loader /> : null}
    </AppDialog>
  );
}

export default NoteViewDialog;
NoteViewDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.any,
};
