import { responsiveFontSizes, createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#f79c35 !important",
      dark: "#dc7a09 !important",
      light: "#fcdbb6 !important",
      contrastText: "#fff",
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "custom"],
    values: {
      xs: 300,
      sm: 650,
      md: 800,
      lg: 1200,
      xl: 1300,
      custom: 1400,
    },
    unit: "px",
  },

  padding: {
    standard: "8px 10px",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    fontSizeRegular: "12px !important",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    cardTitle: {
      fontFamily: "Roboto !important",
      fontWeight: 700,
      fontSize: "1.5rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    h1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "75px",
    },
    h2: {
      fontFamily: "Roboto !important",
      fontWeight: 900,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: "Roboto !important",
      fontWeight: 500,
      fontSize: "2.3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: "Roboto !important",
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: "Roboto !important",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: "Roboto !important",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
    },
    subtitle1: {
      fontFamily: "Roboto !important",
      fontWeight: 500,
      fontSize: "1rem",

      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      fontFamily: "Roboto !important",
      fontWeight: 500,
      fontSize: "0.875rem",

      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      fontFamily: "Roboto !important",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1.7,
      letterSpacing: "0.00938em",
    },
    body2: {
      fontFamily: "Roboto !important",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          // borderRadius: theme.shape.borderRadius,
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
