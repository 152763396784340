import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import CompaniesForm from "../CompaniesForm";
import AppDialog from "../../common/AppDialog/AppDialog";

function CompaniesFormDialog({ open, onClose, screen, setFieldValue }) {
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Add Company"
      minHeight="auto"
      maxWidth="lg"
    >
      <Box>
        <CompaniesForm
          parent="dialog"
          onClose={onClose}
          screen={screen}
          setFieldValue={setFieldValue}
        />
      </Box>
    </AppDialog>
  );
}

export default CompaniesFormDialog;
CompaniesFormDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  screen: PropTypes.string,
  setFieldValue: PropTypes.func,
};
