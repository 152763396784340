import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { SnackBarContext } from "../../../context/SnackBarContext";
import LoadingButton from "@mui/lab/LoadingButton";
import bgImg from "../../../Assets/Images/Authentication/bgImg.jpg";
import { useNavigate } from "react-router-dom";
import GetOtp from "./GetOtp/GetOtp";
import {
  ForgotPasswordAPi,
  ResetPasswordAPi,
  VerifyOTPAPi,
} from "../../../Apis/Auth/AuthApis";
import VerifyOTP from "./VerifyOTP/VerifyOTP";
import ResetPassword from "./ResetPassword/ResetPassword";
import { useAuth } from "../../../context/useAuth";

const validationSchema = yup.object().shape({
  email: yup.string().when("step", {
    is: (step) => step == "email",
    then: () =>
      yup
        .string()
        .email("Please enter valid email")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
  otp: yup.string().when("step", {
    is: (step) => step == "verify",
    then: () =>
      yup
        .string()
        .min(4, "Please add 4-digits OTP!")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
  password: yup.string().when("step", {
    is: (step) => step == "reset",
    then: () =>
      yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
  confirmPassword: yup.string().when("step", {
    is: (step) => step == "reset",
    then: () =>
      yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Field Required!"),
    otherwise: () => yup.string(),
  }),
});

function ForgotPassword() {
  const classes = useStyles();

  const [otpSending, setOtpSending] = useState(false);

  const { user } = useAuth();

  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const resendOTP = async (values, setFieldValue) => {
    setOtpSending(true);
    const formData = {
      email: values.email,
    };
    const response = await ForgotPasswordAPi(formData);

    setOtpSending(false);
    response?.snackBarData?.message && setsnackBarData(response?.snackBarData);

    if (response?.success) {
      setFieldValue("duration", 600);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setSubmitting, setFieldValue, setTouched }
  ) => {
    if (values.step === "email") {
      const formData = {
        email: values.email,
      };
      const response = await ForgotPasswordAPi(formData);

      setSubmitting(false);
      setsnackBarData(response?.snackBarData);

      if (response?.success) {
        setFieldValue("duration", 600);
        setFieldValue("step", "verify");
        setTouched({
          otp: false,
          password: false,
          confirmPassword: false,
        });
      }
    } else if (values.step === "verify") {
      const formData = {
        email: values.email,
        otp: values.otp,
      };
      const response = await VerifyOTPAPi(formData);
      console.log("VerifyOTPAPi", response);

      setSubmitting(false);
      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);

      if (response?.success) {
        setFieldValue("step", "reset");
        setTouched({
          password: false,
          confirmPassword: false,
        });
      }
    } else {
      const formData = {
        email: values.email,
        newPassword: values.password,
      };
      const response = await ResetPasswordAPi(formData);

      setSubmitting(false);
      response?.snackBarData?.message &&
        setsnackBarData(response?.snackBarData);

      if (response?.success) {
        resetForm(values);
        navigate("/signin");
      }
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.overlay}></Box>
      <Box
        className={classes.cardContainer}
        sx={{
          minWidth: { xs: "22rem", sm: "24rem" },
        }}
      >
        <Box className={classes.whiteContainer}>
          <Box
            sx={{
              height: "30px",
            }}
          ></Box>

          <Formik
            initialValues={{
              step: "email",
              email: "",
              otp: "",
              duration: 0,
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <>
                  <Box className={classes.headingContainer}>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#f79c35",
                        letterSpacing: 0,
                        lineHeight: 1.375,
                        fontWeight: 600,
                        fontSize: "1rem !important",
                      }}
                    >
                      {values.step === "email"
                        ? "Get OTP"
                        : values.step === "verify"
                        ? "Verify OTP"
                        : "Reset Password"}
                    </Typography>
                  </Box>

                  <Form className={classes.formContainer}>
                    {values.step === "email" ? (
                      <GetOtp
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    ) : values.step === "verify" ? (
                      <VerifyOTP
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        otpSending={otpSending}
                        resendOTP={() => {
                          resendOTP(values, setFieldValue);
                        }}
                      />
                    ) : (
                      <ResetPassword
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    )}

                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disableElevation
                      color="primary"
                      sx={{
                        fontSize: "0.75rem",
                        fontWeight: 700,
                        lineHeight: "1rem",
                        padding: "0.75rem 1.5rem",
                        backgroundImage: !isSubmitting
                          ? "linear-gradient(to right top, #f69220, #fdcb91)"
                          : "",
                      }}
                      loading={isSubmitting}
                      loadingIndicator="Loading…"
                      disabled={values.step === "verify" && !values.duration}
                    >
                      {values.step === "email"
                        ? "Get OTP"
                        : values.step === "verify"
                        ? "Verify OTP"
                        : "Reset Password"}
                    </LoadingButton>

                    <Typography
                      sx={{
                        cursor: "pointer",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        lineHeight: 1.5,
                        color: "#f79c35",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => {
                        navigate("/signin");
                      }}
                    >
                      Sign In
                    </Typography>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}
export default ForgotPassword;

const useStyles = makeStyles(() => ({
  mainContainer: {
    minWidth: "100vw",
    minHeight: "100vh",
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    zIndex: 0,
  },

  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    backgroundColor: "#00000080",
  },

  brandLogo: {
    display: "block",
    width: "250px",
    height: "90px",
    borderRadius: "20px",
    objectFit: "contain",
  },

  cardContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },

  whiteContainer: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    borderRadius: "0.75rem",
  },

  headingContainer: {
    backgroundColor: "#fff",
    boxShadow: "#fcdbb6 0px 2px 8px 0px",
    borderRadius: "0.75rem",
    height: "50px",
    display: "grid",
    placeItems: "center",
    margin: "-3.5rem 1rem 1rem",
    color: "#fff",
  },

  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1.5rem",
  },
}));
