import React from "react";
import AppDialog from "../../common/AppDialog/AppDialog";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";

function ContactsDetailDialog({ open, onClose, state }) {
  console.log(state);
  return (
    <AppDialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
      title="Contact Details"
      minHeight="auto"
      maxWidth="md"
    >
      <Box>
        <Typography color="primary">General Details</Typography>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>First Name</Typography>
            <Typography>{state?.first_name || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Last Name</Typography>
            <Typography>{state?.last_name || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Display Name</Typography>
            <Typography>{state?.display_name || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Company</Typography>
            <Typography>{state?.company || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Type</Typography>
            <Typography>{state?.type || "N/A"}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Status</Typography>
            <Typography>
              {state?.active ? "Active" : "Inactive" || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Phone</Typography>
            <Typography>{state?.phone || "N/A"}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
            <Typography>{state?.email || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Created At</Typography>
            <Typography>
              {state?.createdAt
                ? moment(state?.createdAt).format("Do MMM-YYYY")
                : "N/A"}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography sx={{ fontWeight: "bold" }}>Updated At</Typography>
            <Typography>
              {state?.updatedAt
                ? moment(state?.updatedAt).format("Do MMM-YYYY")
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography color="primary">Address Details</Typography>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }}>Street Address</Typography>
            <Typography>{state?.address || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={{ fontWeight: "bold" }}>State</Typography>
            <Typography>{state?.state || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={{ fontWeight: "bold" }}>City</Typography>
            <Typography>{state?.city || "N/A"}</Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography sx={{ fontWeight: "bold" }}>Zip Code</Typography>
            <Typography>{state?.zipcode || "N/A"}</Typography>
          </Grid>
        </Grid>
      </Box>
      {state?.notes?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography color="primary">Notes</Typography>
          <Divider sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer
                component={Box}
                sx={{ marginTop: "5px", maxHeight: "300px" }}
              >
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell width={"5%"}>Sr.</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell width={"20%"}>Created By</TableCell>
                      <TableCell width={"20%"}>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state?.notes?.map((item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>

                        <TableCell
                          dangerouslySetInnerHTML={{ __html: item?.note }}
                        ></TableCell>
                        <TableCell>
                          {item?.createdByUser?.name || "N/A"}
                        </TableCell>
                        <TableCell>
                          {moment(
                            item?.createdAt,
                            "YYYY-MM-DDTHH:mm:ss a"
                          )?.format("MM/DD/YYYY") || "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      )}
    </AppDialog>
  );
}

export default ContactsDetailDialog;
ContactsDetailDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  state: PropTypes.any,
};
