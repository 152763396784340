import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { BlobProvider } from "@react-pdf/renderer";
import ProposalPrintNew from "../../components/ProposalReview/ProposalPrintNew";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ThirdPartyView from "../../components/ProposalReview/ThirdPartyView";
import RequstQuotataionView from "../../components/ProposalReview/RequstQuotataionView";
import ChangeReqView from "../../components/ProposalReview/ChangeReqView";
import NoData from "../../Assets/Images/no_data.png";
import SendIcon from "@mui/icons-material/Send";
import SendProposalDialog from "../../components/ProposalReview/SendProposalDialog";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  TableHead: {
    background: "#e0f7fa",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  fieldsHeading: {
    fontSize: "1.125rem !important",
    color: "rgb(38 50 56 /1) !important",
    lineHeight: "1.625 !important",
    letterSpacing: "0 !important",
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
}));
function ProposalReviewDetail() {
  const classes = useStyles();
  const { ProposalReviewByIdData, status } = useSelector(
    (state) => state?.OpportunitySettings
  );
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const [expandedArray, setExpandedArray] = useState([]);
  const [interiorArr, setInteriorArr] = useState([]);
  const [exteriorArr, setExteriorArr] = useState([]);
  const [alternatesArr, setAlternatesArr] = useState([]);
  const [exclusionsArr, setExclusionsArr] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const [thirdPartyData, setThirdPartyData] = useState([]);
  const [estimationData, setEstimationData] = useState([]);
  const [opportunityData, setOpportunityData] = useState([]);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  console.log("ProposalReviewByIdData", ProposalReviewByIdData);

  useEffect(() => {
    if (ProposalReviewByIdData) {
      console.log("ProposalReviewByIdData", ProposalReviewByIdData);
      setInteriorArr(ProposalReviewByIdData?.estimation?.interiorPainting);
      setExteriorArr(ProposalReviewByIdData?.estimation?.exteriorPainting);
      setAlternatesArr(ProposalReviewByIdData?.estimation?.alternates);
      setExclusionsArr(ProposalReviewByIdData?.estimation?.exclusions);
      setQuotationData(ProposalReviewByIdData?.quotation);
      setThirdPartyData(ProposalReviewByIdData?.thirdparty);
      setEstimationData(ProposalReviewByIdData?.estimation);
      setOpportunityData(ProposalReviewByIdData?.opportunityData);
    } else {
      navigate(-1);
    }
  }, [ProposalReviewByIdData]);
  const handleAccordionChange = (panel) => {
    setExpandedArray((prevExpanded) =>
      prevExpanded.includes(panel)
        ? prevExpanded.filter((p) => p !== panel)
        : [...prevExpanded, panel]
    );
  };
  const [intSum, setIntSum] = useState(0);
  const [extSum, setExtSum] = useState(0);
  const [altSum, setAltSum] = useState(0);

  useEffect(() => {
    setExtSum(
      exteriorArr
        ?.reduce((total, item) => Number(total) + Number(item?.price), 0)
        ?.toLocaleString() || 0
    );
    setIntSum(
      interiorArr
        ?.reduce((total, item) => Number(total) + Number(item?.price), 0)
        ?.toLocaleString() || 0
    );
    setAltSum(
      alternatesArr
        ?.reduce((total, item) => Number(total) + Number(item?.price), 0)
        ?.toLocaleString() || 0
    );
  }, [exclusionsArr, interiorArr, alternatesArr, exteriorArr]);

  return (
    <Grid
      container
      spacing={2}
    >
      {/* Estimations */}
      <Grid
        item
        xs={12}
        lg={7}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            padding: "1.5rem",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Typography
              className={classes?.fieldsHeading}
              color="primary"
            >
              Estimations
            </Typography>
            <Box
              display={"flex"}
              gap={1}
              sx={{ mb: "10px" }}
            >
              <BlobProvider
                document={
                  <ProposalPrintNew
                    exclusionsArr={exclusionsArr}
                    alternatesArr={alternatesArr}
                    exteriorArr={exteriorArr}
                    interiorArr={interiorArr}
                    estimationData={estimationData}
                    opportunityData={opportunityData}
                    intSum={intSum || 0}
                    extSum={extSum || 0}
                    altSum={altSum || 0}
                  />
                }
              >
                {({ url, loading, error }) => {
                  if (loading) return <div>Loading document...</div>;
                  if (error)
                    return (
                      <div>Error while loading document: {error.message}</div>
                    );

                  if (!url) return <div>URL not generated</div>;

                  const openPDF = () => {
                    window.open(url, "_blank", "noopener,noreferrer");
                  };

                  return (
                    <Button
                      size="small"
                      variant="outlined"
                      color="info"
                      onClick={openPDF}
                      startIcon={<PictureAsPdfIcon />}
                    >
                      View PDF
                    </Button>
                  );
                }}
              </BlobProvider>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(true)}
                startIcon={<SendIcon />}
              >
                Send Proposal
              </Button>
            </Box>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Box
            sx={{
              marginTop: "20px",
              maxHeight: "calc(100vh - 220px)",
              overflowY: "auto",
            }}
          >
            <Accordion
              expanded={expandedArray.includes("INT")}
              onChange={() => handleAccordionChange("INT")}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography className={classes?.fieldsHeading}>
                  Interior Painting (${intSum || 0})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {interiorArr?.length > 0 ? (
                        interiorArr?.map((row, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                            >
                              {row?.customKey}
                            </TableCell>

                            <TableCell>
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: row?.description,
                                }}
                              >
                                {}
                              </Box>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                            >
                              {row?.price?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            colSpan={5}
                            align="center"
                          >
                            <img
                              width={100}
                              height={100}
                              src={NoData}
                              alt="No data available"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedArray.includes("EXT")}
              onChange={() => handleAccordionChange("EXT")}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography className={classes?.fieldsHeading}>
                  Exterior Painting (${extSum || 0})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {exteriorArr?.length > 0 ? (
                        exteriorArr?.map((row, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{row?.customKey}</TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                            >
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: row?.description,
                                }}
                              >
                                {}
                              </Box>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                            >
                              {row?.price?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            colSpan={5}
                            align="center"
                          >
                            <img
                              width={100}
                              height={100}
                              src={NoData}
                              alt="No data available"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedArray.includes("ALT")}
              onChange={() => handleAccordionChange("ALT")}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography className={classes?.fieldsHeading}>
                  Alternates (${altSum || 0})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {alternatesArr?.length > 0 ? (
                        alternatesArr?.map((row, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{row?.customKey}</TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                            >
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: row?.description,
                                }}
                              >
                                {}
                              </Box>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                            >
                              {row?.price?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            colSpan={5}
                            align="center"
                          >
                            <img
                              width={100}
                              height={100}
                              src={NoData}
                              alt="No data available"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedArray.includes("EXC")}
              onChange={() => handleAccordionChange("EXC")}
            >
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography className={classes?.fieldsHeading}>
                  Exclusions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {exclusionsArr?.length > 0 ? (
                        exclusionsArr?.map((row, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{row?.customKey}</TableCell>

                            <TableCell
                              component="th"
                              scope="row"
                            >
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: row?.description,
                                }}
                              ></Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            colSpan={5}
                            align="center"
                          >
                            <img
                              width={100}
                              height={100}
                              src={NoData}
                              alt="No data available"
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Grid>

      {/* Request for Quotation */}
      <Grid
        item
        xs={12}
        lg={5}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            padding: "10px",
          }}
        >
          <Typography
            className={classes?.fieldsHeading}
            color="primary"
          >
            Change Request
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <ChangeReqView opportunityId={estimationData?.opportunityId} />
        </Box>

        {/* Tabs */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            padding: "10px",
            mt: 2,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Request For Quotation"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ textTransform: "capitalize" }}
              label="Third Party"
              {...a11yProps(1)}
            />
          </Tabs>
          <Divider sx={{ mb: 2 }} />
          <CustomTabPanel
            value={tabValue}
            index={0}
          >
            <RequstQuotataionView quotationData={quotationData} />
          </CustomTabPanel>
          <CustomTabPanel
            value={tabValue}
            index={1}
          >
            <ThirdPartyView thirdPartyData={thirdPartyData} />
          </CustomTabPanel>
        </Box>
      </Grid>
      {status === "loading" ? <Loader /> : null}

      {open && (
        <SendProposalDialog
          open={open}
          onClose={onClose}
          opportunityId={estimationData?.opportunityId}
          contacts={opportunityData?.contacts}
          exclusionsArr={exclusionsArr}
          alternatesArr={alternatesArr}
          exteriorArr={exteriorArr}
          interiorArr={interiorArr}
          estimationData={estimationData}
          opportunityData={opportunityData}
          intSum={intSum || 0}
          extSum={extSum || 0}
          altSum={altSum || 0}
          status={status}
        />
      )}
    </Grid>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default ProposalReviewDetail;
