import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { BreadcrumbsContext } from "../../../context/BcrumbsContextProvider";
import PropTypes from "prop-types";
import theme from "../../../theme/theme";

const useStyles = makeStyles(() => ({
  navbarContainer: {
    padding: "4px 0",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
  },

  navbarLinksContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
  },

  navBtnContainer: {
    display: "flex",
    gap: "10px",
  },

  navbarInfoContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  navIconsBtn: {
    color: "rgb(96 125 139 /1) !important",
    fontWeight: "500 !important",
    fontSize: ".75rem !important",
    lineHeight: "1rem !important",
    borderRadius: "0.5rem !important",
    maxWidth: "40px",
    height: "2.5rem",
  },

  breadcrumbsLink: {
    fontSize: ".875rem",
    lineHeight: "1.5",
    color: "rgb(38 50 56 /.5)",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function Navbar({ toggleDrawer }) {
  const classes = useStyles();
  const location = useLocation();
  const { breadcrumbsItems, setBreadcrumbsItems } =
    useContext(BreadcrumbsContext);

  const getPageName = () => {
    let pathName =
      location.pathname.match(/(?<=\/)\w+$|\w+-\w+$|\w+_\w+$/)?.[0] || "home";
    return pathName?.split(/[-,_,\s]/g)?.join(" ");
  };

  const handleBreadcrumbs = (name, idx) => {
    if (name === "Dashboard") {
      setBreadcrumbsItems([
        {
          id: breadcrumbsItems.length,
          url: "/",
          name: "Dashboard",
        },
        {
          id: breadcrumbsItems.length,
          url: "/",
          name: "Home",
        },
      ]);
    } else {
      let newBreadCrumbs = JSON.parse(JSON.stringify(breadcrumbsItems));
      newBreadCrumbs = newBreadCrumbs?.filter((item, index) => index <= idx);
      setBreadcrumbsItems(newBreadCrumbs);
    }
  };

  return (
    <header className={classes.headerContainer}>
      <nav className={classes.navbarContainer}>
        <Box className={classes.navbarLinksContainer}>
          <Box className={classes.navBtnContainer}>
            <IconButton
              className={classes.navIconsBtn}
              onClick={() => toggleDrawer(true)}
              sx={{
                display: { lg: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbsItems.map((item, index) => {
              if (index !== breadcrumbsItems.length - 1) {
                return (
                  <Link
                    key={item.id}
                    className={classes.breadcrumbsLink}
                    to={item.url}
                    onClick={() => handleBreadcrumbs(item?.name, index)}
                  >
                    {item.name}
                  </Link>
                );
              } else {
                return (
                  <Typography
                    key={item.id}
                    sx={{
                      fontSize: ".875rem",
                      lineHeight: "1.5",
                      color: "rgb(38 50 56 /1)",
                    }}
                  >
                    {item.name}
                  </Typography>
                );
              }
            })}
          </Breadcrumbs>

          <Typography
            variant="h6"
            sx={{
              fontSize: "1rem",
              lineHeight: "1.625",
              color: "rgb(38 50 56 /1)",
              letterSpacing: 0,
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {getPageName()}
          </Typography>
        </Box>
      </nav>
    </header>
  );
}

export default Navbar;
Navbar.propTypes = {
  toggleDrawer: PropTypes.func,
};
